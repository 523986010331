import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { Alert } from "../../model/alerts/Alert";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { executeWorkflowOnAlert, getPaginatedAlert, getTotalAmountOfAlert } from "../../store/alerts/action";
import { AlertFieldsConstant } from "../../common/constant/AlertFieldsConstant";
import { AlertsContextMenu } from "./AlertContextMenu";
import { AlertDetailsPopup } from "./AlertDetailsPopup";
import { AlertSearch } from "./AlertSearch";
import { Pagination } from "../../components/pagination/Pagination";
import { Customer } from "../customer/Customer";
import { WorkflowStatus } from "../../model/alerts/WorkflowStatus";
import { Request } from "../../model/common/Request";
import { Comment } from "../../model/alerts/Comment";
import { AlertsWorkflowCommentPopup } from "./AlertsWorkflowCommentPopup";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { Group } from "../../model/user/Group";
import { User } from "../../model/user/User";
import { UserDashBoardPreferences } from "../../model/user/UserDashBoardPreferences";
import { Name } from "../../model/persons/Name";
import { formatMessage } from "../../common/translate/Translate";

interface IntProps {
    isLoading?: Boolean,
    alerts?: Alert[],
    totalAmountOfAlerts?: number,
    addPersonTab?: (customerId: number) => void,
    userDashBoardPreferences?: UserDashBoardPreferences,
    fullListOfWorkflowStatus?: WorkflowStatus[]
}

interface IntState {
    paginationRequest: PaginationRequest,
    selectedRow: number,
    contextMenuX: number,
    contextMenuY: number,
    contextMenuAlertId: string,
    contextMenuVisible: boolean,

    alertDetailsPopupVisible: boolean,
    customerDetailPopupVisible: boolean,

    workflowNextStepId: string,
    workflowComment: string,
    workflowAssignedToUsers: number,
    workflowAssignedToGroups: number,

    workflowCommentDisplay: boolean,
    workflowShowAssignedToUser: boolean,
    workflowShowAssignedToGroup: boolean,

    tabSelected: number
}

class AlertsClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        let paginationRequest: PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;

        this.state = {
            paginationRequest: paginationRequest,
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false,
            contextMenuAlertId: '',
            alertDetailsPopupVisible: false,
            customerDetailPopupVisible: false,
            workflowNextStepId: '',
            workflowComment: '',
            workflowAssignedToUsers: -1,
            workflowAssignedToGroups: -1,
            workflowCommentDisplay: false,
            workflowShowAssignedToUser: false,
            workflowShowAssignedToGroup: false,
            tabSelected: -1
        }

        // this.refreshId = setInterval(this.onRefresh, 5000);

    }

    private maxPage: number = 0;

    public render() {

        if (this.props.totalAmountOfAlerts) {
            this.maxPage = Math.floor(this.props.totalAmountOfAlerts / this.state.paginationRequest.maxPerPage);

            if (this.props.totalAmountOfAlerts / this.state.paginationRequest.maxPerPage > this.maxPage) {
                this.maxPage += 1;
            }
        } else {
            this.maxPage = 0;
        }

        let header: Array<{ id: number, name: string, visibleTab: boolean }> = this.getHeader();

        return (
            <React.Fragment>
                <AlertsContextMenu x={this.state.contextMenuX} y={this.state.contextMenuY} visible={this.state.contextMenuVisible}
                    showAlertDetail={this.showAlertDetails}
                    showCustomerDetails={this.showCustomerDetails}
                    nextSteps={this.state.contextMenuAlertId !== '' ? this.getNextStepsWorkflows(Number(this.state.contextMenuAlertId)) : []}
                    executeWorkflow={this.addCommentWorkflowOnAlert}
                />
                <AlertDetailsPopup visible={this.state.alertDetailsPopupVisible} alertId={this.state.contextMenuAlertId !== '' ? Number(this.state.contextMenuAlertId) : -1}
                    closePopup={this.closeAllPopup} refreshData={this.refreshAlertData} y={400} />
                <Customer visible={this.state.customerDetailPopupVisible}
                    customerId={this.state.contextMenuAlertId !== '' ? this.getCurrentCustomer(Number(this.state.contextMenuAlertId)) : -1}
                    closePopup={this.closeAllPopup} />
                <AlertsWorkflowCommentPopup visible={this.state.workflowCommentDisplay}
                    savePopup={this.executeWorkflowOnAlert}
                    setComment={this.setCommentWorkflowFromPopup}
                    cancelPopup={this.closeAllPopup}
                    showGroup={this.state.workflowShowAssignedToGroup}
                    showUser={this.state.workflowShowAssignedToUser}
                    setAssignToUsers={this.setAssignToUserWorkflowFromPopup}
                    setAssignToGroups={this.setAssignToGroupWorkflowFromPopup}
                />
                <div className="main-search" >
                    <AlertSearch
                        savePaginationRequest={this.savePaginationRequest}
                        selectedWorkflowId={this.state.tabSelected}
                    />
                </div>
                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu} style={{ paddingRight: '10px' }}>
                    <label className="o-label title"> {formatMessage('keyword_alerts')}</label>

                    <div style={{ marginLeft: '20px', marginRight: '10px', width: '98%', display: 'flex' }}>
                        <nav className="c-tabs__nav" >
                            <ul className="c-tabs__list">
                                {header.map((head, index) => {
                                    return (
                                        <li className="c-tabs__title" key={index}>
                                            <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.id ? 'is-active' : ''}`}
                                                id={String(head.id)}
                                                name={String(head.id)} onClick={this.changeVisibility}>
                                                <span id={String(head.id)} className="nav">{head.name}</span>
                                            </button>
                                        </li>
                                    )
                                })}
                            </ul>
                        </nav>

                        <div className="navworkflow">
                            <nav className="c-tabs__nav" style={{ width: 'fit-content' }}>
                                <ul className="c-tabs__list">
                                    <li className="c-tabs__title" key={'wkf'} style={{display:'flex'}}>
                                        {this.state.contextMenuAlertId !== '' &&
                                            this.getNextStepsWorkflows(Number(this.state.contextMenuAlertId)).map((item, j) => {
                                                return (
                                                    <button key={item.code} id={String(item.id)} className="m-button m-button--small-margin-bottom" type="button"
                                                        style={{ textAlign: 'left', width: 'fit-content', marginLeft:'5px'}} onClick={this.addCommentWorkflowOnAlert}>
                                                        {item.description}
                                                    </button>

                                                );
                                            }
                                            )}
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>

                    <div className="grids-display">
                        <table className="c-table js-table" style={{ paddingTop: '2rem' }}>
                            <thead>
                                <tr>
                                    {this.isRowMustBeDisplayed("alertId") && <HeaderColumn sortHeader={this.sortHeader} id="alertId" display="alert_alertId" />}
                                    {this.isRowMustBeDisplayed("alertName") && <HeaderColumn sortHeader={this.sortHeader} id="alertName" display="alert_alertName" />}
                                    {this.isRowMustBeDisplayed("alertDescription") && <HeaderColumn sortHeader={this.sortHeader} id="alertDescription" display="alert_alertDescription" />}
                                    {this.isRowMustBeDisplayed("alertScore") && <HeaderColumn sortHeader={this.sortHeader} id="alertScore" display="alert_alertScore" />}
                                    {this.isRowMustBeDisplayed("alertPriority") && <HeaderColumn sortHeader={this.sortHeader} id="alertPriority" display="alert_alertPriority" />}
                                    {this.isRowMustBeDisplayed("alertMessageType") && <HeaderColumn sortHeader={this.sortHeader} id="alertMessageType" display="alert_alertMessageType" />}
                                    {this.isRowMustBeDisplayed("alertTrxnReference") && <HeaderColumn sortHeader={this.sortHeader} id="alertTrxnReference" display="alert_alertTrxnReference" />}
                                    {this.isRowMustBeDisplayed("alertTrxnAmount") && <HeaderColumn sortHeader={this.sortHeader} id="alertTrxnAmount" display="alert_alertTrxnAmount" />}
                                    {this.isRowMustBeDisplayed("alertDateTime") && <HeaderColumn sortHeader={this.sortHeader} id="alertDateTime" display="alert_alertDateTime" />}
                                    {this.isRowMustBeDisplayed("alertSourceName") && <HeaderColumn sortHeader={this.sortHeader} id="alertSourceName" display="alert_alertSourceName" />}
                                    {this.isRowMustBeDisplayed("alertType") && <HeaderColumn sortHeader={this.sortHeader} id="alertType" display="alert_alertType" />}
                                    {this.isRowMustBeDisplayed("alertStatus") && <HeaderColumn sortHeader={this.sortHeader} id="alertStatus" display="alert_alertStatus" />}
                                    {this.isRowMustBeDisplayed("alertAssignTo") && <HeaderColumn sortHeader={this.sortHeader} id="alertAssignTo" display="alert_alertAssignedTo" />}
                                    {this.isRowMustBeDisplayed("alertMatchScore") && <HeaderColumn sortHeader={this.sortHeader} id="alertMatchScore" display="alert_alertMatchScore" />}
                                    {this.isRowMustBeDisplayed("alertTargetName") && <HeaderColumn sortHeader={this.sortHeader} id="alertTargetName" display="alert_alertTargetName" />}
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.alerts && this.props.alerts.map((rowElem, j) => {
                                    return (
                                        <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow === rowElem.id ? "is-selected" : ""}`} onClick={this.selectRow}
                                            onDoubleClick={this.displayAlertDetails}>
                                            {this.isRowMustBeDisplayed("alertId") && <td className="c-table__data c-table__smallfont">{rowElem.id}</td>}
                                            {this.isRowMustBeDisplayed("alertName") && <td className="c-table__data c-table__smallfont">{rowElem.name}</td>}
                                            {this.isRowMustBeDisplayed("alertDescription") && <td className="c-table__data c-table__smallfont">{rowElem.description}</td>}
                                            {this.isRowMustBeDisplayed("alertScore") && <td className="c-table__data c-table__smallfont">{rowElem.score}</td>}
                                            {this.isRowMustBeDisplayed("alertPriority") && <td className="c-table__data c-table__smallfont">{rowElem.priority}</td>}
                                            {this.isRowMustBeDisplayed("alertMessageType") && <td className="c-table__data c-table__smallfont">{rowElem.messageType}</td>}
                                            {this.isRowMustBeDisplayed("alertTrxnReference") && <td className="c-table__data c-table__smallfont">{rowElem.transactionReference}</td>}
                                            {this.isRowMustBeDisplayed("alertTrxnAmount") && <td className="c-table__data c-table__smallfont">{rowElem.transactionAmount}</td>}
                                            {this.isRowMustBeDisplayed("alertDateTime") && <td className="c-table__data c-table__smallfont">{rowElem.alertDate}</td>}
                                            {this.isRowMustBeDisplayed("alertSourceName")
                                                && <td className="c-table__data c-table__smallfont">
                                                    {rowElem.source && rowElem.source.names && rowElem.source.names.length > 0 && this.getFullNameForDisplay(rowElem.source.names[0])}
                                                </td>
                                            }
                                            {this.isRowMustBeDisplayed("alertType") && <td className="c-table__data c-table__smallfont">{rowElem.type}</td>}
                                            {this.isRowMustBeDisplayed("alertStatus") && <td className="c-table__data c-table__smallfont">{rowElem.status ? rowElem.status.description : ''}</td>}
                                            {this.isRowMustBeDisplayed("alertAssignTo") && <td className="c-table__data c-table__smallfont">
                                                {rowElem.assignedToUser ? rowElem.assignedToUser.userName : ''}
                                                &nbsp;
                                                {rowElem.assignedToGroup ? rowElem.assignedToGroup.name : ''}
                                            </td>}
                                            {this.isRowMustBeDisplayed("alertMatchScore") && <td className="c-table__data c-table__smallfont">{rowElem.matchScore}</td>}
                                            {this.isRowMustBeDisplayed("alertTargetName") && <td className="c-table__data c-table__smallfont">{rowElem.target && rowElem.target.length > 0 && rowElem.target[0].watchListPersons && rowElem.target[0].watchListPersons.names && this.getFullNameForDisplay(rowElem.target[0].watchListPersons.names[0])}</td>}
                                        </tr>
                                    );
                                }
                                )}
                            </tbody>
                        </table>
                    </div>
                    <div className="pagination-display">
                        {this.maxPage !== Number.NaN && this.maxPage > 1 &&
                            <Pagination
                                changeInput={this.changeInput}
                                onChangeNext={this.onChangeNext}
                                onChangePrevious={this.onChangePrevious}
                                maxPage={this.maxPage}
                                pageNumber={this.state.paginationRequest.pageNumber} />
                        }
                    </div>
                </div>
            </React.Fragment>
        )
    }

    private isRowMustBeDisplayed = (rowName: string): boolean => {
        if (this.props.userDashBoardPreferences && this.props.userDashBoardPreferences.listOfFieldsToDisplay) {
            for (let i = 0; i < this.props.userDashBoardPreferences.listOfFieldsToDisplay.length; i++) {
                if (this.props.userDashBoardPreferences.listOfFieldsToDisplay[i] === rowName) {
                    return true;
                }
            }
            return false;
        }
        return true;
    }

    private setCommentWorkflowFromPopup = (comment: string) => {
        this.setState({
            ...this.state,
            workflowComment: comment
        })
    }

    private setAssignToUserWorkflowFromPopup = (user: number) => {
        this.setState({
            ...this.state,
            workflowAssignedToUsers: user
        })
    }

    private setAssignToGroupWorkflowFromPopup = (group: number) => {
        this.setState({
            ...this.state,
            workflowAssignedToGroups: group
        })
    }

    private addCommentWorkflowOnAlert = (event: any) => {

        let wkfStatus: WorkflowStatus = this.getWorkflowsStatus(Number(event.target.id));

        this.setState({
            ...this.state,
            workflowNextStepId: event.target.id,
            workflowCommentDisplay: true,
            workflowShowAssignedToGroup: wkfStatus.assignToGroup,
            workflowShowAssignedToUser: wkfStatus.assignToUser,
            contextMenuVisible: false,
        })
    }

    private executeWorkflowOnAlert = () => {

        let request: Request = new Request();
        request.alert = this.getAlert(Number(this.state.contextMenuAlertId));
        request.alertNextStatus = new WorkflowStatus();
        request.alertNextStatus.id = Number(this.state.workflowNextStepId);

        if (!Number.isNaN(this.state.workflowAssignedToGroups)
            && this.state.workflowAssignedToGroups !== -1) {
            let group: Group = new Group();
            group.id = this.state.workflowAssignedToGroups;
            request.alert.assignedToGroup = group;
        }

        if (!Number.isNaN(this.state.workflowAssignedToUsers)
            && this.state.workflowAssignedToUsers !== -1) {
            let user: User = new User();
            user.id = this.state.workflowAssignedToUsers;
            request.alert.assignedToUser = user;
        }

        request.comment = new Comment();
        request.comment.comment = this.state.workflowComment;

        executeWorkflowOnAlert(request, this.state.paginationRequest);

        this.setState({
            ...this.state,
            workflowNextStepId: '',
            workflowComment: '',
            workflowCommentDisplay: false,
            workflowShowAssignedToGroup: false,
            workflowShowAssignedToUser: false,
        })
    }

    private onGridDisplayContextMenu = (event: any) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX,
            contextMenuY: event.pageY,
            contextMenuAlertId: event.target && event.target.parentElement ? event.target.parentElement.id : ''
        })
    }

    private onChangeNext = (): void => {
        if (this.state.paginationRequest.pageNumber < this.maxPage) {
            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber + 1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest: paginationRequest
            })
            getPaginatedAlert(paginationRequest);
        }
    }

    private onChangePrevious = (): void => {
        if (this.state.paginationRequest.pageNumber > 0) {
            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber - 1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest: paginationRequest
            })
            getPaginatedAlert(paginationRequest);
        }
    }

    private changeInput = (e: any) => {
        const value = e;
        if (value !== '') {
            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest: paginationRequest
            })
            getPaginatedAlert(paginationRequest);
            return;
        }
        if (value < 1) {
            return;
        }
    }

    private selectRow = (e: any) => {
        const value = e.target.parentNode ? e.target.parentNode.id : e.id;
        console.log(e.target && e.target.parentElement ? e.target.parentElement.id : '');
        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow: Number(value),
            contextMenuAlertId: e.target && e.target.parentElement ? e.target.parentElement.id : ''
        })
    }

    private displayAlertDetails = (e: any) => {
        const value = e.target.parentNode ? e.target.parentNode.id : e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow: Number(value),
            contextMenuAlertId: value,
            alertDetailsPopupVisible: true
        })
    }

    private showAlertDetails = () => {
        this.setState({
            ...this.state,
            contextMenuVisible: false,
            alertDetailsPopupVisible: true,
            customerDetailPopupVisible: false
        })
    }

    private showCustomerDetails = (event: any) => {

        event.stopPropagation();

        const value = event.target.parentNode ? event.target.parentNode.id : event.id;

        if (this.state.contextMenuAlertId !== '' && this.props.addPersonTab) {
            this.props.addPersonTab(this.getCurrentCustomer(Number(this.state.contextMenuAlertId)));
        } else if (value && this.props.addPersonTab) {
            this.props.addPersonTab(this.getCurrentCustomer(Number(value)));
        }

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            alertDetailsPopupVisible: false,
            customerDetailPopupVisible: false,
            selectedRow: Number(value)
        })
    }

    private closeAllPopup = () => {
        this.setState({
            ...this.state,
            contextMenuVisible: false,
            alertDetailsPopupVisible: false,
            customerDetailPopupVisible: false,
            workflowCommentDisplay: false
        })
    }

    private getAlert = (alertId: number): Alert => {
        if (this.props.alerts) {
            for (var alert of this.props.alerts) {
                if (alert.id === alertId) {
                    return alert;
                }
            }
        }
        return new Alert();
    }

    private getNextStepsWorkflows = (alertId: number): WorkflowStatus[] => {
        if (this.props.alerts) {
            for (var alert of this.props.alerts) {
                if (alert.id === alertId) {
                    if (alert.status && alert.status.nextSteps) {
                        return alert.status.nextSteps;
                    }
                }
            }
        }
        return [];
    }

    private getWorkflowsStatus = (statusId: number): WorkflowStatus => {
        let workflowStatusList: WorkflowStatus[] = this.getNextStepsWorkflows(Number(this.state.contextMenuAlertId));
        for (var wkfStatus of workflowStatusList) {
            if (wkfStatus.id === statusId) {
                return wkfStatus;
            }
        }
        return new WorkflowStatus();
    }

    private getCurrentCustomer = (alertId: number): number => {
        if (this.props.alerts) {
            for (var alert of this.props.alerts) {
                if (alert.id === alertId && alert.source) {
                    return alert.source.id;
                }
            }
        }
        return -1;
    }

    private sortHeader = (e: any) => {
        const value = e.target ? e.target.id : e.id;

        let paginationRequest: PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder: string = paginationRequest.sortField;
        if (value === 'alertName') {
            paginationRequest.sortField = AlertFieldsConstant.NAMEFIELD;
        }

        if (value === 'alertId') {
            paginationRequest.sortField = AlertFieldsConstant.IDFIELD;
        }

        if (value === 'alertScore') {
            paginationRequest.sortField = AlertFieldsConstant.SCOREFIELD;
        }

        if (value === 'alertPriority') {
            paginationRequest.sortField = AlertFieldsConstant.PRIORITYFIELD;
        }

        if (value === 'alertMessageType') {
            paginationRequest.sortField = AlertFieldsConstant.MESSAGETYPEFIELD;
        }

        if (value === 'alertTrxnReference') {
            paginationRequest.sortField = AlertFieldsConstant.TRANSACTIONREFERENCEFIELD;
        }

        if (value === 'alertTrxnAmount') {
            paginationRequest.sortField = AlertFieldsConstant.TRANSACTIONAMOUNTFIELD;
        }

        if (value === 'alertDateTime') {
            paginationRequest.sortField = AlertFieldsConstant.ALERTDATEFIELD;
        }

        if (value === 'alertSourceName') {
            paginationRequest.sortField = AlertFieldsConstant.NAMEFIELD;
        }

        if (value === 'alertType') {
            paginationRequest.sortField = AlertFieldsConstant.TYPEFIELD;
        }

        if (value === 'alertStatus') {
            paginationRequest.sortField = AlertFieldsConstant.STATUSFIELD;
        }

        if (value === 'alertMatchScore') {
            paginationRequest.sortField = AlertFieldsConstant.MATCHSCOREFIELD;
        }


        if (paginationRequest.sortField === previousFieldOrder) {
            if (paginationRequest.sortOrder === 'DESC') {
                paginationRequest.sortOrder = 'ASC';
            } else {
                paginationRequest.sortOrder = 'DESC';
            }
        } else {
            paginationRequest.sortOrder = 'DESC';
        }

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            paginationRequest: paginationRequest
        })
        getPaginatedAlert(paginationRequest);
    }

    private refreshAlertData = () => {
        getPaginatedAlert(this.state.paginationRequest);
    }

    private getFullNameForDisplay(name: Name): string {
        if (name.fullName && name.fullName.length > 0) {
            return name.fullName;
        }

        let fullNameConcat: string = '';

        if (name.surname) {
            fullNameConcat += name.surname;
            fullNameConcat += ' ';
        }

        if (name.fatherName) {
            fullNameConcat += name.fatherName;
            fullNameConcat += ' ';
        }

        if (name.givenName) {
            fullNameConcat += name.givenName;
        }

        return fullNameConcat.trim();
    }

    private getHeader = () => {
        const header: Array<{ id: number, name: string, visibleTab: boolean }> = [];

        header.push({ id: -1, name: 'All', visibleTab: false });

        if (this.props.fullListOfWorkflowStatus) {
            for (let cpt: number = 0; cpt < this.props.fullListOfWorkflowStatus.length; cpt++) {
                header.push({ id: this.props.fullListOfWorkflowStatus[cpt].id, name: this.props.fullListOfWorkflowStatus[cpt].description, visibleTab: false });
            }
        }

        return header;
    }

    private changeVisibility = (event: any) => {
        this.onSubmit(Number.parseInt(event.target.id))
        this.setState({
            ...this.state,
            tabSelected: Number.parseInt(event.target.id)
        })
    }

    private onSubmit = (workflowId: number): void => {
        let paginationRequest: PaginationRequest = this.state.paginationRequest;
        let alert: Alert = new Alert();
        let setAlert: boolean = false;

        if (workflowId !== -1) {
            let wkfStatus: WorkflowStatus = new WorkflowStatus();
            wkfStatus.id = workflowId;
            alert.status = wkfStatus;
            setAlert = true;
        }

        if (setAlert) {
            paginationRequest.alert = alert;
        } else {
            paginationRequest.alert = new Alert();
        }

        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;

        getTotalAmountOfAlert(paginationRequest);
        getPaginatedAlert(paginationRequest);

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            paginationRequest: paginationRequest
        })
    }

    public savePaginationRequest = (paginationRequest: PaginationRequest) => {
        this.setState({
            ...this.state,
            paginationRequest: paginationRequest
        })
    }

    private onRefresh = (): void => {
        getTotalAmountOfAlert(this.state.paginationRequest);
        // getPaginatedAlert(this.state.paginationRequest);
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    alerts: state.alertStore.listOfAlerts,
    totalAmountOfAlerts: state.alertStore.amountOfAlerts,
    userDashBoardPreferences: state.userStore.userDashBoardPreferences,
    fullListOfWorkflowStatus: state.workflowStore.listOfWorkflowStatus
})

export const Alerts = connect(mapStateToProps, null)(AlertsClass);