import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { IRootState } from "../../store";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { Person } from "../../model/persons/Person";
import { Name } from "../../model/persons/Name";
import { getAmountOfRiskPerson, getPaginatedRiskPerson, getRiskPersonAsCSV } from "../../store/risk/action";
import { PersonFieldsConstant } from "../../common/constant/PersonFieldsConstant";
import { DataSets } from "../../model/dataset/DataSets";

interface IntProps {
    customerListOfDatasets?: DataSets[],
}

interface IntState {
    keywordSearch: string,
    scoreMin:string,
    scoreMax:string,
    datasetSelected:{ value: string; label: string; }
}

class RiskSearchClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = ({
            keywordSearch:'',
            scoreMin:'',
            scoreMax:'',
            datasetSelected: {
                value: '',
                label: '',             
            }
        });
    }

    public render() {

        let optionsDatasets: { value: string; label: string; }[] = [];
        if (this.props.customerListOfDatasets) {
            for (var dataset of this.props.customerListOfDatasets) {
                optionsDatasets.push(
                    {
                        value: String(dataset.id),
                        label: dataset.label                       
                    }
                )
            }
        }

        return (
            <React.Fragment>   
                <main className="c-main overflow-hidden" style={{height: "100%", marginTop: '5px'}}>
                                               
                   <div className="c-form__field" style={{height: "80%", margin: '15px'}}>

                        <label className="o-label">{formatMessage('datasets_search')}</label>
                        <Select options={optionsDatasets}
                            name="datasetSelect"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={this.changeDatasetSearch}
                        />

                       <label className="o-label">{formatMessage('keyword_search')}</label>
                       <input
                           className="o-input"
                           name="keyword"
                           placeholder={formatMessage('keyword_search')}
                           value= {this.state.keywordSearch}
                           onChange = {this.changeKeywordSearch} 
                           onKeyPress = {this.inputKeyPress} 
                           style={{marginTop: '2px'}}              
                        /> 

                       <label className="o-label">{formatMessage('common_score')}</label>
                       <div style={{ display: 'flex' }} >
                        <input
                            className="o-input"
                            name="riskScoreMin"
                            placeholder={formatMessage('common_score')}
                            value= {this.state.scoreMin}
                            onChange = {this.changeScoreMin}
                            onKeyPress = {this.inputKeyPress} 
                            style={{marginTop: '2px'}}              
                            /> 

                        <input
                            className="o-input"
                            name="riskScoreMax"
                            placeholder={formatMessage('common_score')}
                            value= {this.state.scoreMax}
                            onChange = {this.changeScoreMax} 
                            onKeyPress = {this.inputKeyPress}
                            style={{marginTop: '2px'}}              
                            /> 
                        </div>
                    </div>
                    <button className="m-button m-button--primary" onClick={this.onSubmit} style={{marginTop: '5px'}}>
                         {formatMessage('button_search')}
                    </button> 
                    <button className="m-button m-button--primary" onClick={this.onExport} style={{marginTop: '5px'}}>
                         {formatMessage('button_export')}
                    </button> 
                    
                </main>
            </React.Fragment>
        );
    }

    private changeDatasetSearch = (value: { value: string; label: string;} | null): void => {

       if (value) {        
            this.setState({
                ...this.state,
                datasetSelected : value
            });
        } else {
            this.setState({
                ...this.state,
                datasetSelected: {
                    value: '',
                    label: '',             
                }
            });
           
        }
    }

    private changeKeywordSearch = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            keywordSearch: event.target.value                  
        });
    }

    private changeScoreMin = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            scoreMin: event.target.value                  
        });
    }

    private changeScoreMax = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            scoreMax: event.target.value                  
        });
    }

    private onSubmit = () : void => {
        this.onSubmitWithExport(false);
    }

    private onExport = () : void => {
        this.onSubmitWithExport(true);
    }

    private onSubmitWithExport = (exportAsCsv:boolean) : void => {
        let paginationRequest:PaginationRequest = new PaginationRequest();
       
        paginationRequest.maxPerPage = 10;

        let person:Person = new Person();
        if (this.state.keywordSearch.length>0) {
            let name:Name = new Name();
            name.fullName = this.state.keywordSearch;

            person.names = [];
            person.names.push(name);
        }

        if (this.state.scoreMin!=='' || this.state.scoreMax!=='') {
            paginationRequest.numberRangeField = PersonFieldsConstant.RISKSCOREFIELD;
            if (this.state.scoreMin!=='') {
                paginationRequest.number1 = Number(this.state.scoreMin);
            }

            if (this.state.scoreMax!=='') {
                paginationRequest.number2 = Number(this.state.scoreMax);
            }
        }

        if (this.state.datasetSelected && this.state.datasetSelected.value!=='') {
            let dset:DataSets = new DataSets();
            dset.id = Number(this.state.datasetSelected.value);
            person.dataset = dset;
        }

        paginationRequest.person = person;
        getPaginatedRiskPerson(paginationRequest);
        getAmountOfRiskPerson(paginationRequest);

        if (exportAsCsv) {
            getRiskPersonAsCSV(paginationRequest);
        }
    }

    private inputKeyPress = (e:React.KeyboardEvent<HTMLInputElement>) : void => {        
        if (e.key === 'Enter') {
            this.onSubmit();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,    
    customerListOfDatasets: state.datasetStore.customerListOfDatasets,
})

export const RiskSearch = connect(mapStateToProps, null)(RiskSearchClass);