import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Request } from "../../model/common/Request";
import { MatchingRule } from "../../model/screening/MatchingRule";
import { DataSets } from "../../model/dataset/DataSets";
import { MatchingRuleDefinition } from "../../model/screening/MatchingRuleDefinition";
import { MatchingRuleScoring } from "../../model/screening/MatchingRuleScoring";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { getAllDatasets } from "../../store/dataset/action";
import { updateMatchingRules } from "../../store/screening/action";
import { AdminContextMenu } from "./AdminContextMenu";
import { MatchingRuleDefinitionDetailsPopup } from "./MatchingRuleDefinitionDetailsPopup";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { DatasetConstant } from "../../common/constant/DatasetConstant";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    matchingRule?:MatchingRule,
    create?:boolean,
    closePopup?:() => void,
    fullListOfDatasets?:DataSets[]
}

interface IntState {
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,  

    matchingRuleDefinitionDetailPopupVisible:boolean,
    matchingRuleDefinitionCreate:boolean,

    id:number,
    dataId:string,  
    apikey:string,
    organizationId:number,
    sourceDataSet:DataSets,
    alertDataset:DataSets,
    matchingRules:MatchingRuleDefinition[],
    matchingRuleScoring:MatchingRuleScoring,
    errors:{        
        dataId:string
    }
}

class MatchingConfigurationDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {     
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            id:-1,
            contextMenuVisible: false,     
            dataId:'',
            apikey:'',
            organizationId:-1,
            sourceDataSet:new DataSets(),
            alertDataset:new DataSets(),
            matchingRules:[],
            matchingRuleScoring: new MatchingRuleScoring(),

            matchingRuleDefinitionDetailPopupVisible: false,
            matchingRuleDefinitionCreate : false,

            errors:{
                dataId:''
            }     
        }

        getAllDatasets();
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                dataId:'',
                apikey:'',
                organizationId:-1,
                sourceDataSet:new DataSets(),
                alertDataset: new DataSets(),
                matchingRules:[],
                matchingRuleScoring: new MatchingRuleScoring(),
                errors:{
                    dataId:''
                }   
            })
        } else if (nextProps.matchingRule 
                    && nextProps.matchingRule.id!==this.state.id)  {    
                        
            if (nextProps.matchingRule.matchingRuleScoring) {
                this.setState({
                    id : nextProps.matchingRule.id,
                    dataId : nextProps.matchingRule.dataId,
                    apikey : nextProps.matchingRule.apiKey,
                    organizationId : nextProps.matchingRule.organizationUnitId,
                    sourceDataSet : nextProps.matchingRule.sourceDataSet,
                    matchingRules : nextProps.matchingRule.matchingRules,
                    matchingRuleScoring : nextProps.matchingRule.matchingRuleScoring,
                    alertDataset : nextProps.matchingRule.matchingRuleScoring.dataset 
                })
            } else {
                this.setState({
                    id : nextProps.matchingRule.id,
                    dataId : nextProps.matchingRule.dataId,
                    apikey : nextProps.matchingRule.apiKey,
                    organizationId : nextProps.matchingRule.organizationUnitId,
                    sourceDataSet : nextProps.matchingRule.sourceDataSet,
                    matchingRules : nextProps.matchingRule.matchingRules,
                    matchingRuleScoring: new MatchingRuleScoring(),
                })
            }
          
        }
           
      }

    public render() {    

        let fieldValue:{value: string;label: string} = {
            value:'',
            label:''
        };

        let alertFieldValue:{value: string;label: string} = {
            value:'',
            label:''
        };

        let optionsDatasets:{value: string;label: string;}[] = [];
        let alertsDatasets:{value: string;label: string;}[] = [];
        if (this.props.fullListOfDatasets) {
            for (var dataset of this.props.fullListOfDatasets) {
                optionsDatasets.push(
                    {
                        value : String(dataset.id),
                        label : dataset.label
                    }
                )

                if (this.state.sourceDataSet && this.state.sourceDataSet.id === dataset.id) {
                    fieldValue =  {
                                    value : String(dataset.id),
                                    label : dataset.label
                                };
                }

                if (dataset.sourceOfData === DatasetConstant.ALERTS) {
                    alertsDatasets.push(
                        {
                            value : String(dataset.id),
                            label : dataset.label
                        }
                    )
                }

                if (this.state.alertDataset && this.state.alertDataset.id === dataset.id) {
                    alertFieldValue =  {
                                    value : String(dataset.id),
                                    label : dataset.label
                                };
                }
            }
        }

        let heightAdd:number = 0;

        if (this.state.matchingRules) {
            heightAdd += this.state.matchingRules.length * 3.5;
        }

        return (
            <React.Fragment>
                <AdminContextMenu visible={this.state.contextMenuVisible} 
                                    x={this.state.contextMenuX} y={this.state.contextMenuY}
                                    deleteAction={this.deleteMatchingRule}
                                    editAction={this.displayMatchingRuleDefinitionDetails}
                />

                <MatchingRuleDefinitionDetailsPopup visible={this.state.matchingRuleDefinitionDetailPopupVisible}
                                                    closePopup={this.closeMatchingRuleDetails}
                                                    saveMatchingRuleDefinition={this.saveMatchingRuleDef}
                                                    matchingRuleDefinition={this.getMatchingRuleDef()}
                                                    create={this.state.matchingRuleDefinitionCreate}
                />

                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: 48 + heightAdd + "rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                               
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_dataId')}
                                </label>
                                <input
                                    className="o-input"
                                    name="scenario"
                                    placeholder={formatMessage('risk_conf_dataId')}
                                    value={this.state.dataId}
                                    onChange={this.changeDataId}
                                    style={{ marginTop: '2px' }}
                                />
                                 {this.state.errors && this.state.errors["dataId"] &&
                                    <span className="form-error">{this.state.errors["dataId"]}</span>
                                 }
                            </div>   

                            <label className="o-label">{formatMessage('matchingRule_datasets')}</label>
                            <Select options={optionsDatasets}
                                    name="datasets"                                                                              
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    value={fieldValue}
                                    onChange={this.changeDataSet}
                            />    

                            <label className="o-label">{formatMessage('matchingRule_apikey')}</label>
                            <input
                                    className="o-input"
                                    name="apikey"
                                    placeholder={formatMessage('common_apikey')}
                                    value={this.state.apikey}
                                    onChange={this.changeApiKey}
                                    style={{ marginTop: '2px' }}
                             />                                 
                        </div>

                        {this.state.matchingRuleScoring && 
                            <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                                <label className="o-label">
                                        {formatMessage('matchingRule_alertDefinition')}
                                </label>

                                <div style={{ display: 'flex' }} >
                                    <label className="o-label" style={{ width: '100px' }}>
                                        {formatMessage('common_description')}
                                    </label>
                                    <input
                                        className="o-input"
                                        name="description"
                                        placeholder={formatMessage('common_description')}
                                        value={this.state.matchingRuleScoring.description}
                                        onChange={this.changeMatchingRuleScoringDescription}
                                        style={{ marginTop: '2px' }}
                                    />                                
                                </div>

                                <div style={{ display: 'flex' }} >
                                    <label className="o-label" style={{ width: '100px' }}>
                                        {formatMessage('common_name')}
                                    </label>
                                    <input
                                        className="o-input"
                                        name="name"
                                        placeholder={formatMessage('common_name')}
                                        value={this.state.matchingRuleScoring.name}
                                        onChange={this.changeMatchingRuleScoringName}
                                        style={{ marginTop: '2px' }}
                                    />                                
                                </div>

                                <div style={{ display: 'flex' }} >
                                    <label className="o-label" style={{ width: '100px' }}>
                                        {formatMessage('matchingRule_priority')}
                                    </label>
                                    <input
                                        className="o-input"
                                        name="priority"
                                        placeholder={formatMessage('matchingRule_priority')}
                                        value={this.state.matchingRuleScoring.priority}
                                        onChange={this.changeMatchingRuleScoringPriority}
                                        style={{ marginTop: '2px' }}
                                    />                                
                                </div>

                                <div style={{ display: 'flex' }} >
                                    <label className="o-label" style={{ width: '100px' }}>
                                        {formatMessage('matchingRule_alertStatus')}
                                    </label>
                                    <input
                                        className="o-input"
                                        name="description"
                                        placeholder={formatMessage('matchingRule_alertStatus')}
                                        value={this.state.matchingRuleScoring.status}
                                        onChange={this.changeMatchingRuleScoringAlertStatus}
                                        style={{ marginTop: '2px' }}
                                    />                                
                                </div>

                                <label className="o-label">{formatMessage('matchingRule_alert_datasets')}</label>
                                <Select options={alertsDatasets}
                                        name="alertDatasets"                                                                              
                                        value={alertFieldValue}
                                        className="basic-multi-select"
                                        classNamePrefix="select"
                                        onChange={this.changeAlertDataSet}
                                />
                            </div>
                        }

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }} onContextMenu={this.onGridDisplayContextMenu}>
                            <div>
                                <div style={{ display: 'flex' }} >
                                    <label className="o-label" >
                                        {formatMessage('matchingRule_list')}
                                        
                                    </label>
                                    <button className="m-button m-button--small-margin-bottom" onClick={this.createMatchingRuleDefinitionDetails} style={{marginLeft: '15px', width:'3rem', height:'2rem'}}>
                                        {formatMessage('button_plus')}
                                    </button>
                                </div>
                                <table className="c-table js-table">
                                    <thead className={`c-table__head`}>
                                        <tr>
                                            <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="name" display="common_name"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="permutation" display="matchingRule_permutation"/>
                                            <HeaderColumn sortHeader={this.sortHeader} id="truncation" display="matchingRule_truncation"/>  
                                            <HeaderColumn sortHeader={this.sortHeader} id="datasets" display="matchingRule_targetDataset"/>                                                                                                      
                                        </tr>
                                    </thead>
                                    <tbody className="c-table__body">
                                        {this.state.matchingRules && this.state.matchingRules.map((rowElem, j) => {                                   
                                                return (
                                                        <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} 
                                                                    onClick={this.selectRow}
                                                                    onDoubleClick={this.displayMatchingRuleDefinitionDetails}>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.name}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.permutation===true?'true':'false'}</td>
                                                            <td className="c-table__data c-table__smallfont">{rowElem.truncation===true?'true':'false'}</td>
                                                            <td className="c-table__data c-table__smallfont">{this.getListOfDataSetLabel(rowElem.targetDataSets)}</td>
                                                        </tr>
                                                    );
                                            }
                                        )}
                                    </tbody>                            
                                </table>                                
                            </div>                   
                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveMatchingRule} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private getListOfDataSetLabel(datasets:DataSets[]) : string {
        let strDs : string ='';
        if (datasets) {
            for (var ds of datasets) {
                strDs+=ds.label + ' ';
            }
        }

        return strDs;
    }

    private saveMatchingRuleDef = (matchRuleDef:MatchingRuleDefinition) : void => {
        if (matchRuleDef.id) {
            let matchingRulesDef = [];

            for (var matchRefDef of this.state.matchingRules) {
                if (this.state.selectedRow===matchRefDef.id) {
                    matchingRulesDef.push(matchRuleDef);
                } else {
                    matchingRulesDef.push(matchRefDef);
                }
            }

            this.setState({
                ...this.state,
                matchingRules: matchingRulesDef,
                matchingRuleDefinitionDetailPopupVisible : false,
                matchingRuleDefinitionCreate:false,
                contextMenuVisible: false
            })
        } else {
            let matchingRulesDef:MatchingRuleDefinition[] = [];

            if (this.state.matchingRules) {
                matchingRulesDef = this.state.matchingRules;
            }

            matchingRulesDef.push(matchRuleDef);
            console.log(matchingRulesDef.length);
            this.setState({
                ...this.state,
                matchingRules: matchingRulesDef,
                matchingRuleDefinitionDetailPopupVisible : false,
                matchingRuleDefinitionCreate:false,
                contextMenuVisible: false
            })
        }
        
    }

    private getMatchingRuleDef = () : MatchingRuleDefinition => {
        if (this.state.matchingRules) {
            for (var matchRefDef of this.state.matchingRules) {
                if (this.state.selectedRow===matchRefDef.id) {
                    return matchRefDef;
                } 
            }
        }
        return new MatchingRuleDefinition();
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private changeDataSet = (invalue: { value: string; label: string; } | null): void => {

        let value:string='';

        if (invalue) {
            value = invalue.value;
        
            this.setState({                        
                ...this.state,  
                sourceDataSet : this.getDatasetFromId(Number(value))
            });

        }
    }

    private changeAlertDataSet = (invalue: { value: string; label: string; } | null): void => {

        let value:string='';

        if (invalue) {
            value = invalue.value;
        
            let matchingRuleScoring : MatchingRuleScoring = this.state.matchingRuleScoring;

            if (!matchingRuleScoring) {
                matchingRuleScoring = new MatchingRuleScoring();
            }

            matchingRuleScoring.dataset = this.getDatasetFromId(Number(value));
            
            this.setState({                        
                ...this.state,
                matchingRuleScoring : matchingRuleScoring,  
                alertDataset : matchingRuleScoring.dataset
            });

        }
    }

    private deleteMatchingRule = () => {
        // deleteMatchingRules(String(this.state.selectedRow));
        this.setState({
            ...this.state,
            contextMenuVisible: false
        })
    }

    private createMatchingRuleDefinitionDetails = () => {
        this.setState({
            ...this.state,
            matchingRuleDefinitionDetailPopupVisible : true,
            matchingRuleDefinitionCreate:true,
            contextMenuVisible: false
        })
    }

    private displayMatchingRuleDefinitionDetails = () => {
        this.setState({
            ...this.state,
            matchingRuleDefinitionDetailPopupVisible : true,
            matchingRuleDefinitionCreate:false,
            contextMenuVisible: false
        })
    }

    private closeMatchingRuleDetails = () => {
        this.setState({
            ...this.state,
            matchingRuleDefinitionDetailPopupVisible : false,
            matchingRuleDefinitionCreate:false,
            contextMenuVisible: false

        })
    }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private changeDataId = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            dataId: event.target.value
        });
    }

    private changeApiKey = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            apikey: event.target.value
        });
    }

    private changeMatchingRuleScoringDescription = (event: { target: { value: any; }; }):void => {
        let matchingRuleScoring : MatchingRuleScoring = this.state.matchingRuleScoring;

        if (!matchingRuleScoring) {
            matchingRuleScoring = new MatchingRuleScoring();
        }

        matchingRuleScoring.description = event.target.value;

        this.setState({                        
            ...this.state,      
            matchingRuleScoring: matchingRuleScoring
        });
    }

    private changeMatchingRuleScoringAlertStatus = (event: { target: { value: any; }; }):void => {
        let matchingRuleScoring : MatchingRuleScoring = this.state.matchingRuleScoring;

        if (!matchingRuleScoring) {
            matchingRuleScoring = new MatchingRuleScoring();
        }

        matchingRuleScoring.status = event.target.value;

        this.setState({                        
            ...this.state,      
            matchingRuleScoring: matchingRuleScoring
        });
    }

    private changeMatchingRuleScoringName = (event: { target: { value: any; }; }):void => {
        let matchingRuleScoring : MatchingRuleScoring = this.state.matchingRuleScoring;

        if (!matchingRuleScoring) {
            matchingRuleScoring = new MatchingRuleScoring();
        }

        matchingRuleScoring.name = event.target.value;

        this.setState({                        
            ...this.state,      
            matchingRuleScoring: matchingRuleScoring
        });
    }

    private changeMatchingRuleScoringPriority = (event: { target: { value: any; }; }):void => {
        let matchingRuleScoring : MatchingRuleScoring = this.state.matchingRuleScoring;

        if (!matchingRuleScoring) {
            matchingRuleScoring = new MatchingRuleScoring();
        }

        matchingRuleScoring.priority = Number(event.target.value);

        this.setState({                        
            ...this.state,      
            matchingRuleScoring: matchingRuleScoring
        });
    }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;
    }

    private getDatasetFromId = (datasetId:number) : DataSets => {
        if (this.props.fullListOfDatasets) {          
            for (var dataset of this.props.fullListOfDatasets) {
                if (dataset.id === datasetId) {
                    return dataset;
                }
            }
        }

        return new DataSets();
    }

    private saveMatchingRule = () : void => {
        let request:Request = new Request();
        request.matchingRules = [];
       
        if (this.props.create) {
           let matchingRule:MatchingRule = new MatchingRule();
           
           matchingRule.dataId = this.state.dataId;
           matchingRule.organizationUnitId = this.state.organizationId;
           matchingRule.matchingRules = this.state.matchingRules;
           matchingRule.matchingRuleScoring = this.state.matchingRuleScoring;
           matchingRule.sourceDataSet = this.state.sourceDataSet;
           matchingRule.apiKey = this.state.apikey;
           request.matchingRules.push(matchingRule);
        } else if (this.props.matchingRule) {
            let matchingRule:MatchingRule = this.props.matchingRule;
            matchingRule.dataId = this.state.dataId;
            matchingRule.organizationUnitId = this.state.organizationId;
            matchingRule.matchingRules = this.state.matchingRules;
            matchingRule.matchingRuleScoring = this.state.matchingRuleScoring;
            matchingRule.sourceDataSet = this.state.sourceDataSet;
            matchingRule.apiKey = this.state.apikey;
            request.matchingRules.push(matchingRule);
        }
   
        updateMatchingRules(request);

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    fullListOfDatasets: state.datasetStore.fullListOfDatasets,
})

export const MatchingConfigurationDetailsPopup = connect(mapStateToProps, null)(MatchingConfigurationDetailsPopupClass);