import { hashCode } from "../../common/helper/StringHelper"
import { Person } from "../../model/persons/Person"
import { Constants } from "./constant"
import { SearchDatasetStore } from "./type"


const init: SearchDatasetStore = {
    listOfPersons:[],
    amountOfPersons:-1,

    listOfAlerts:[],
    amountOfAlerts:-1,

    listOfAccounts:[],
    amountOfAccounts:-1
} 
    
export const searchDatasetReducer = (state: SearchDatasetStore = init, action: any): SearchDatasetStore => {    
    switch (action.type) {      
        case Constants.LISTOFPERSONS:  
            if (action.payload.numberOfObjects) {
                return {
                    ...state,
                    amountOfPersons: action.payload.numberOfObjects,
                    listOfPersons: action.payload.persons,
                    listOfAccounts : [],
                    listOfAlerts: []
                } 
            } else {
                return {
                    ...state,
                    listOfPersons: action.payload.persons,
                    listOfAccounts : [],
                    listOfAlerts: []

                } 
            }     
            
        case Constants.LISTOFPERSONSCOUNT:          
            return {
				...state,
                amountOfPersons: action.payload.numberOfObjects,
            } 

        case Constants.LISTOFALERTS:            
            return {
				...state,
				listOfAlerts: action.payload.alerts,
                listOfPersons: [],
                listOfAccounts : []
            }  

        case Constants.LISTOFALERTSCOUNT:          
            return {
				...state,
                amountOfAlerts: action.payload.numberOfObjects,
            }

        case Constants.LISTOFACCOUNTS:
            return {
				...state,
				listOfAccounts: action.payload.accounts,
                listOfPersons : [],
                listOfAlerts : []
            }  
        
        case Constants.LISTOFACCOUNTSCOUNT:          
            return {
				...state,
                amountOfAccounts: action.payload.numberOfObjects,
            }

        case Constants.CLEANSEARCHES:
            return {
				...state,
                amountOfAlerts: -1,
				listOfAlerts: [],
                listOfPersons: [],
                listOfAccounts:[],
                amountOfPersons: -1
            } 
            
        case Constants.SCREENA_DATASETS_RECORD:            
            if (action.payload.datasets) {
                let persons:Person[] = [];
                for (var person of action.payload.datasets[0].records) {
                    console.log(person);
                    person.id = hashCode(person.dataID);
                    persons.push(person);
                }
                return {
                    ...state,
                    listOfPersons: persons,
                    listOfAccounts : [],
                    listOfAlerts: []
                }
            } else {
                return {
                    ...state,
                    listOfPersons: []
                }
            }

        case Constants.SCREENA_DATASETS_RECORD_COUNT:
            if (action.payload.datasets) {
                console.log(action.payload.datasets);
                return {
                    ...state,
                    amountOfPersons: action.payload.datasets[0].totalCount
                }
            } else {
                return {
                    ...state,
                    amountOfPersons: 0
                }
            }

        default:
            return {
				...state,
			}
    }
}