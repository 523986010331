import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { formatMessage } from "../../common/translate/Translate";
import { AdminContextMenu } from "./AdminContextMenu";
import { WorkflowStatus } from "../../model/alerts/WorkflowStatus";
import { deleteWorkflowStatus, getFullListOfWkfStatus } from "../../store/workflow/action";
import { WorkflowStatusDetailPopup } from "./WkfStatusDetail";

interface IntProps {
    isLoading?:Boolean,  
    workflowStatus?:WorkflowStatus[]  
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,

    wkfStatusDetailsVisible: boolean,
    createWkfStatus: boolean
}

class WorkflowStatusClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            paginationRequest : new PaginationRequest(),
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false ,
            wkfStatusDetailsVisible: false ,
            createWkfStatus : false   
        }

        getFullListOfWkfStatus();
    }

    private maxPage:number = 0;

    public render() {
        
        return (
            <React.Fragment>                      
                <AdminContextMenu visible={this.state.contextMenuVisible} x={this.state.contextMenuX} y={this.state.contextMenuY}
                                    editAction={this.displayWkfStatusDetails}
                                    deleteAction={this.deleteWkfStatus}                                   
                />      
                <WorkflowStatusDetailPopup visible={this.state.wkfStatusDetailsVisible}
                                             create={this.state.createWkfStatus}
                                             closePopup={this.closeWkfStatusDetails}
                                             wkfStatus={this.getCurrentWkfStatus(this.state.selectedRow)}
                                             paginationRequest={this.state.paginationRequest}
                />
                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu}>  
                    <div style={{ marginLeft: '20px', marginRight: '10px', width: '98%', display: 'flex' }}>
                        <label className="o-label title" style={{ width: '98%'}}> {formatMessage('admin_main_workflowstatus')}</label>
                        <button className="m-button m-button--small-margin-bottom"  onClick={this.createWkfStatus} style={{marginTop: '5px', width: '10rem'}}>
                            {formatMessage('button_create')}
                        </button>
                    </div>
                    <div className="grids-display">
                        <table className="c-table js-table">
                            <thead className={`c-table__head`}>
                                <tr>
                                    <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="name" display="common_name"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="description" display="common_description"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="active" display="jobs_active"/>
                            
                                    <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedBy" display="common_updatedBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedOn" display="common_updatedOn"/>                                                                  
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.workflowStatus && this.props.workflowStatus.map((rowElem, j) => {                                   
                                        return (
                                                <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} 
                                                    onClick={this.selectRow}
                                                    onDoubleClick={this.displayWkfStatusDetails}>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.code}</td>                                                 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.description}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.closeStatus}</td>                                                     

                                                    <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updateDate}</td> 
                                                </tr>
                                            );
                                    }                          
                                )}
                            </tbody>                            
                        </table> 
                    </div>                   
                </div>  
            </React.Fragment>
        );
    }

    private getCurrentWkfStatus = (wkfStatusId: number) : WorkflowStatus => {
        if (this.props.workflowStatus) {
            for (var wkfstat of this.props.workflowStatus) {
                if (wkfstat.id===wkfStatusId) {
                    return wkfstat;
                }
            }
        }        
        return new WorkflowStatus();
    }

    private deleteWkfStatus = () => {
        deleteWorkflowStatus(String(this.state.selectedRow));

        this.setState({
            ...this.state,
            contextMenuVisible: false
        })
    }

    private displayWkfStatusDetails = () => {
        this.setState({
            ...this.state,
            wkfStatusDetailsVisible:true,
            createWkfStatus:false,
            contextMenuVisible: false
        })
    }

    private createWkfStatus = () => {
        this.setState({
            ...this.state,
            wkfStatusDetailsVisible:true,
            createWkfStatus:true,
            selectedRow:-1
        })
    }

    private closeWkfStatusDetails = () => {
        this.setState({
            ...this.state,
            wkfStatusDetailsVisible:false,
            createWkfStatus:false,
            contextMenuVisible: false

        })
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    workflowStatus : state.workflowStore.listOfWorkflowStatus   
})

export const WorkflowStatusAdmin = connect(mapStateToProps, null)(WorkflowStatusClass);