
export function getMonthText(text:string) {

    switch (Number.parseInt(text)) {
        case 1 : return 'January';
        case 2 : return 'February';
        case 3 : return 'March';
        case 4 : return 'April';
        case 5 : return 'May';
        case 6 : return 'June';
        case 7 : return 'July';
        case 8 : return 'August';
        case 9 : return 'September';
        case 10 : return 'October';
        case 11 : return 'November';
        case 12 : return 'December';        
    }

}

export function formatDate(text:string|undefined){

    if (!text) {
        return '';
    }

    if (text.length===4) {
        return text;
    }

    if (text.length===7) {
        return getMonthText(text.substring(5,7)) + ', ' + text.substring(0,4);
    }

    if (text.length===10) {
        return getMonthText(text.substring(5,7)) + ' ' + Number.parseInt(text.substring(8,10)) + ', '  + text.substring(0,4);
    }

    return text;

}


const regExpScreenaYear:RegExp = new RegExp( /^([0-9]{4})$/);
const regExpScreenaYearMonth:RegExp = new RegExp( /^([0-9]{4}-[0-9]{2})$/);
const regExpScreenaYearMonthDay:RegExp = new RegExp( /^([0-9]{4}-[0-9]{2}-[0-9]{2})$/);

export function validateScreenaDateFormat(text:string|undefined)  {

    if (text && regExpScreenaYear.test(text)) {
        return true;
    }

    if (text && regExpScreenaYearMonth.test(text)) {
        return true;
    }

    if (text && regExpScreenaYearMonthDay.test(text)) {
        return true;
    }

    return false;

}

const regExpRadarFullDate:RegExp = new RegExp( /^([0-9]{1,2}\/[0-9]{1,2}\/[0-9]{4})$/);

export function validateRadarDateFormat(text:string|undefined)  {

    if (text && regExpRadarFullDate.test(text)) {
        return true;
    }

    return false;

}