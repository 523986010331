import React from "react";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { formatMessage } from "../../common/translate/Translate";
import { WorkflowStatus } from "../../model/alerts/WorkflowStatus";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    editAction?: (e: any) => void,
    deleteAction?: (e: any) => void,
    startAction?: (e: any) => void,
}

interface IntState {
    
}

export class AdminContextMenu extends React.Component<IntProps, IntState> {

     public render() {    
        return (
                <React.Fragment>   
                        <div className={this.props.visible===true?"c-popover-right-click c-lc-popover.is-visible js-lc-context" : "c-popover c-lc-popover js-lc-context"}
                                        style={{position: 'absolute' , left:this.props.x + 'px', top:this.props.y+'px'}}>
                            <div className="c-popover__body">
                                <ul className="d-list d-list--borders">
                                    <li className="d-list__item">
                                        <ul className="d-list d-list--borders">
                                            <li className="d-list__item">
                                                {this.props.editAction && 
                                                    <button className="m-button m-button--inline" type="button" onClick={this.props.editAction}>
                                                        {formatMessage('admin_context_edit')}
                                                    </button>
                                                }
                                                {this.props.deleteAction && 
                                                    <button className="m-button m-button--inline" type="button" onClick={this.props.deleteAction}>
                                                        {formatMessage('admin_context_delete')}
                                                    </button>                   
                                                }
                                                {this.props.startAction && 
                                                    <button className="m-button m-button--inline" type="button" onClick={this.props.startAction}>
                                                        {formatMessage('admin_context_start')}
                                                    </button>                   
                                                }                                                                 
                                            </li>
                                        </ul>
                                    </li>
                                </ul>                               
                            </div>
                        </div>
                </React.Fragment>
        );
    }
}