import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { User } from "../../model/user/User";
import { Request } from "../../model/common/Request";
import { changePasswordUser } from "../../store/user/action";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    closePopup?:() => void,
    user?:User
}

interface IntState {
    password:string,
    passwordVerify:string,
    errors: {
        password:string
    }
}

class ChangePasswordPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            password:'',
            passwordVerify:'',
            errors: {
                password:''
            }
        }
    }

    public render() {    
        
        let heightError:number = 0;

        if (this.state.errors && this.state.errors["password"]) {
            heightError+=4;
        }

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "30rem", height: 16 + heightError  + "rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('title_change_password')}
                            </legend>
                            <div>                                
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{display : 'flex'}}>
                                <label className="o-label" style={{width:'80px'}}>
                                      {formatMessage('user_password')}
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["password"]? "o-input error" : "o-input"}
                                    name="userpassword"
                                    type="password"
                                    placeholder={formatMessage('user_password')}
                                    value= {this.state.password}
                                    onChange = {this.changePassword}  
                                    style={{marginTop: '2px'}}              
                                />                                             
                            </div>
                            <div style={{display : 'flex'}}>
                                <label className="o-label" style={{width:'80px'}}>
                                    {formatMessage('user_password')}
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["password"]? "o-input error" : "o-input"}
                                    name="userpasswordVerify"
                                    type="password"
                                    placeholder={formatMessage('user_password_verify')}
                                    value= {this.state.passwordVerify}
                                    onChange = {this.changePasswordVerify}  
                                    style={{marginTop: '2px'}}              
                                />                                
                            </div>
                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveUser} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>

                        {this.state.errors && this.state.errors["password"] &&
                                <div className="form errors">
                                    <span className="form-errors p">{this.state.errors["password"]}</span>
                                </div>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changePassword = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            password: event.target.value
        });
    }

    private changePasswordVerify = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            passwordVerify: event.target.value
        });
    }

    private verifyField = () : boolean => {
        let noError:boolean = true;
        let errors = {           
            password : ''
        };

        if (this.state.password.length===0) {
            errors.password = formatMessage('password_mandatory');
            noError = false;
        }

        if (this.state.password!==this.state.passwordVerify) {
            errors.password = formatMessage('password_no_match');
            noError = false;
        }

        if (!noError) {
            this.setState({
                ...this.state, 
                errors:errors       
            })
        }

        return noError;
    }

    private saveUser = () => {
        if (!this.verifyField()) {
            return;
        }

        let request:Request = new Request();
        let user:User;

        if (this.props.user) {
            user = this.props.user;
            user.password = this.state.password;
            request.user = user;

            changePasswordUser(request);
        } else {
            return;
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }

    
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,  
    user : state.userStore.currentUser
})

export const ChangePasswordPopup = connect(mapStateToProps, null)(ChangePasswordPopupClass);