import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { Pagination } from "../../components/pagination/Pagination";
import { Role } from "../../model/user/Role";
import { deleteRole, getPaginatedRoles, getTotalAmountOfRoles } from "../../store/role/action";
import { RoleDetailsPopup } from "./RoleDetailPopup";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { formatMessage } from "../../common/translate/Translate";
import { AdminContextMenu } from "./AdminContextMenu";

interface IntProps {
    isLoading?:Boolean,
    roles?:Role[],
    totalAmountOfRoles?:number
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,

    roleDetailsVisible: boolean,
    createRole: boolean
}

class RoleClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            paginationRequest : new PaginationRequest(),
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false ,
            roleDetailsVisible: false ,
            createRole : false   
        }

        getPaginatedRoles(this.state.paginationRequest);
        getTotalAmountOfRoles(this.state.paginationRequest);
    }

    private maxPage:number = 0;

    public render() {
        
        if (this.props.totalAmountOfRoles) {
            this.maxPage = Math.ceil(this.props.totalAmountOfRoles / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        }
        
        return (
            <React.Fragment> 
                <RoleDetailsPopup visible={this.state.roleDetailsVisible} 
                                    closePopup={this.closeRoleDetails}
                                    role={this.getCurrentRole(this.state.selectedRow)}
                                    create={this.state.createRole}
                                    paginationRequest={this.state.paginationRequest}/>
                <AdminContextMenu visible={this.state.contextMenuVisible} x={this.state.contextMenuX} y={this.state.contextMenuY}
                                    deleteAction={this.deleteRole}
                                    editAction={this.displayRoleDetails}
                />
                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu}>  
                    <div style={{ marginLeft: '20px', marginRight: '10px', width: '98%', display: 'flex' }}>
                        <label className="o-label title" style={{ width: '98%'}}> {formatMessage('admin_main_role')}</label>
                        <button className="m-button m-button--small-margin-bottom"  onClick={this.createRole} style={{marginTop: '5px', width: '10rem'}}>
                            {formatMessage('button_create')}
                        </button>
                    </div>
                    <div className="grids-display">
                        <table className="c-table js-table">
                            <thead className={`c-table__head`}>
                                <tr>
                                    <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="name" display="common_name"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="description" display="common_description"/>

                                    <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedBy" display="common_updatedBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedOn" display="common_updatedOn"/>                                                                  
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.roles &&  this.props.roles.map((rowElem, j) => {                                   
                                        return (
                                                <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                                    onDoubleClick={this.displayRoleDetails}>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.name}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.description}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedDate}</td>
                                                </tr>
                                            );
                                    }                          
                                )}
                            </tbody>                            
                        </table> 
                    </div>
                    <div className="pagination-display">
                        {this.maxPage!==Number.NaN && this.maxPage>0 && 
                           <Pagination 
                                changeInput={this.changeInput} 
                                onChangeNext={this.onChangeNext} 
                                onChangePrevious={this.onChangePrevious} 
                                maxPage={this.maxPage} 
                                pageNumber={this.state.paginationRequest.pageNumber}/>      
                        }
                    </div>  
                </div>  
            </React.Fragment>
        );
    }

    private deleteRole = () => {
        deleteRole(String(this.state.selectedRow), this.state.paginationRequest);
        this.setState({
            ...this.state,
            contextMenuVisible: false            
        })
    }

    private displayRoleDetails = () => {
        this.setState({
            ...this.state,
            roleDetailsVisible:true,
            createRole:false,
            contextMenuVisible: false
        })
    }

    private createRole = () => {
        this.setState({
            ...this.state,
            roleDetailsVisible:true,
            createRole:true,
            selectedRow:-1
        })
    }

    private closeRoleDetails = () => {
        this.setState({
            ...this.state,
            roleDetailsVisible:false,
            contextMenuVisible: false
        })
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                contextMenuVisible: false,
                paginationRequest:paginationRequest                
            })
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
        }        
    }

    private changeInput = (e: any) => {
        const value = e.target? e.target.value: e.value;
        if (value === '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
          return;
        }
        if (value < 1) {
          return;
        }       
    }

    private getCurrentRole = (roleId: number) : Role => {
        if (this.props.roles) {
            for (var role of this.props.roles) {
                if (role.id===roleId) {
                    return role;
                }
            }
        }        
        return new Role();
    }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    roles : state.roleStore.listOfRoles,
    totalAmountOfRoles : state.roleStore.amountOfRoles
})

export const RoleAdmin = connect(mapStateToProps, null)(RoleClass);