import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { showNotification } from "../notification/action";
import { Constants } from "./constant";

export const getFullListOfGroups = () => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.fullGroupList, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.FULLLISTOFGROUPS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getPaginatedGroups = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.groupList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.LISTOFGROUPS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getTotalAmountOfGroups = (request:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.groupCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.COUNTOFGROUPS
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}

export const createGroup = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.groupCreate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('group_action_created'), null, null) ; 
        if (paginationRequest) {
            getTotalAmountOfGroups(paginationRequest);
            getPaginatedGroups(paginationRequest);
        }
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEGROUP
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}

export const updateGroup = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.groupUpdate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('group_action_updated'), null, null) ; 
        if (paginationRequest) {
            getTotalAmountOfGroups(paginationRequest);
            getPaginatedGroups(paginationRequest);
        }
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEGROUP
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}

export const deleteGroup = (groupId:string, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.delete(URLConstants.groupDelete + groupId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('group_action_deleted'), null, null) ;   

        if (paginationRequest) {
            getTotalAmountOfGroups(paginationRequest);
            getPaginatedGroups(paginationRequest);
        }

        return store.dispatch({
            payload: response.data,
            type: Constants.DELETEGROUP
        });        
    })
    .catch((error: AxiosError<String>) => {        
        stopLoadingStatus();
        manageAxiosError(error);
    })
}