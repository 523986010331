import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/login.css'
import { formatMessage } from "../../common/translate/Translate";
import { IRootState } from "../../store";
import { authenticateUser, getScreenApiKey } from "../../store/login/action";
import { JwTRequest } from "../../model/user/JwTRequest";

import { Redirect } from "react-router-dom";
import { getSingleUser } from "../../store/user/action";
import { IconsEnum } from "../../common/constant/IconsEnum";

interface IntProps {
    isLoading?: Boolean,
    token?: string,
    errorMessage?: string
}

interface IntState {
    user: string,
    password: string
}

class Login extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)
        this.state = {
            user: '',
            password: ''
        }
    }

    public render() {

        if (this.props.token && localStorage.getItem('jwt') && localStorage.getItem('jwt') === 'Bearer ' + this.props.token) {
            getSingleUser(this.state.user);
            getScreenApiKey();
            return (
                <Redirect to='/main' />
            )
        }

        return (
            <React.Fragment>
                <div className={`d-loader js-loader ${this.props.isLoading ? 'is-loading' : ''}`} />
                <main className="c-main overflow-hidden">
                    <div className="p-login">

                        <div className="c-form__field">
                            <span >
                                <svg id="logo" className="image_logo" style={{ width: "137px", height: "19px" }}>
                                    <title></title>
                                    <use href={IconsEnum.LOGO.url} />
                                </svg>
                            </span>
                            <label className="o-label" style={{ paddingTop: '10px' }}>{formatMessage('login_username')}</label>
                            <input
                                className={this.props.errorMessage ? "o-input error" : "o-input"}
                                name="user"
                                style={{ width: '300px' }}
                                placeholder={formatMessage('login_username')}
                                value={this.state.user}
                                onChange={this.changeUserName}

                            />
                            <label className="o-label">{formatMessage('login_password')}</label>
                            <input
                                className={this.props.errorMessage ? "o-input error" : "o-input"}
                                style={{ width: '300px' }}
                                type="password"
                                name="password"
                                placeholder={formatMessage('login_password')}
                                value={this.state.password}
                                onChange={this.changePassword}
                                onKeyPress={this.passwordKeyPress}
                            />
                            <br />
                            <button className="m-button m-button--primary" onClick={this.onSubmit} style={{ marginTop: '10px', width: '300px' }}>
                                {formatMessage('login_button')}
                            </button>

                            {this.props.errorMessage &&
                                <div className="form errors" style={{ minWidth: '25rem' }}>
                                    <span className="form-errors p" style={{ width: '25rem' }}>{this.props.errorMessage}</span>
                                </div>
                            }

                        </div>

                    </div>


                </main>
            </React.Fragment>
        )
    }

    private changeUserName = (event: { target: { value: any; }; }): void => {
        this.setState({
            ...this.state,
            user: event.target.value
        });
    }

    private changePassword = (event: { target: { value: any; }; }): void => {
        this.setState({
            ...this.state,
            password: event.target.value
        });
    }

    private onSubmit = (): void => {
        var request: JwTRequest = new JwTRequest();
        request.userName = this.state.user;
        request.password = this.state.password;
        authenticateUser(request);
    }

    private passwordKeyPress = (e: React.KeyboardEvent<HTMLInputElement>): void => {
        if (e.key === 'Enter') {
            var request: JwTRequest = new JwTRequest();
            request.userName = this.state.user;
            request.password = this.state.password;
            authenticateUser(request);
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    token: state.loginStore.jwtToken,
    errorMessage: state.loginStore.errorMessage
})

export const LoginHOC = connect(mapStateToProps, null)(Login);