import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/tabs.css'
import '../../common/css/card.css'
import { IRootState } from "../../store";
import { HeaderConstant } from "../../common/constant/HeaderConstant";
import { formatMessage } from "../../common/translate/Translate";
import { WatchlistKeywords } from "./WatchlistKeywords";
import { WatchlistSources } from "./WatchlistSources";
import { WatchlistPersons } from "./WatchlistPersons";
import { Person } from "../../model/persons/Person";
import { WatchlistComment } from "./WatchlistComment";
import { AttributeDisplay } from "../../components/attribute/AttributeDisplay";
import { MultipleAttributeDisplay } from "../../components/attribute/MultipleAttributeDisplay";
import { MultipleAttributeDisplayName } from "../../components/attribute/MultipleAttributeDisplayName";
import { Name } from "../../model/persons/Name";
import { capitalizeWords } from "../../common/helper/StringHelper";

interface IntProps {
    visible?: boolean,
    watchlistPerson?: Person,
    closePopup?: () => void
}

interface IntState {
    tabSelected: string
}

class WatchlistTabClass extends React.Component<IntProps, IntState> {

    private componentVisible: boolean;

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);
        this.componentVisible = false;

        this.state = {
            tabSelected: HeaderConstant.RELATED_KEYWORDS
        }
    }

    public render() {
        if (this.props.visible && this.componentVisible !== this.props.visible) {
            this.componentVisible = this.props.visible;
        }

        let header: Array<{ id: string, name: string, visibleTab: string, closeAble: boolean }> = this.getHeader();

        console.log(this.props.watchlistPerson);

        return (
            <React.Fragment>
                <div className="main-customer" style={{ overflow: 'auto' }}>
                    <div className={`entity-card js-horizontal-card`} style={{ width: '99%%' }}>
                        <div className="header" style={{ width: '100%' }}>
                            <p className="header main-name">
                                {this.props.watchlistPerson && this.props.watchlistPerson.names ? this.getFullNameForDisplay(this.props.watchlistPerson.names[0]) : ''}
                            </p>
                            <p>
                                <span className="header label-entity-type">
                                    {this.props.watchlistPerson ? this.props.watchlistPerson.entityType : ''}
                                </span>
                                <span className="header label-dataset">
                                    {this.props.watchlistPerson ? this.props.watchlistPerson.label ? this.props.watchlistPerson.label : '' : ''}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className={`entity-card js-horizontal-card`} style={{ width: '99%' }}>
                        <div className="header" style={{ width: '100%' }}>
                            <AttributeDisplay field={formatMessage('person_details_dataid')}
                                value={this.props.watchlistPerson ? this.props.watchlistPerson.dataID : ''} />
                            <MultipleAttributeDisplayName field={formatMessage('person_details_fullName')}
                                value={this.props.watchlistPerson ? this.props.watchlistPerson.names : []} />
                            <AttributeDisplay field={formatMessage('person_details_sex')}
                                value={this.props.watchlistPerson ? this.props.watchlistPerson.sex : ''}
                                sex={true} />

                            {this.props.watchlistPerson?.titles && this.props.watchlistPerson?.titles.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_titles')} value={this.props.watchlistPerson?.titles} />
                            }

                            {this.props.watchlistPerson?.bic &&
                                <AttributeDisplay field={formatMessage('person_details_bic')} value={this.props.watchlistPerson?.bic} />
                            }

                            {this.props.watchlistPerson?.lei &&
                                <AttributeDisplay field={formatMessage('person_details_lei')} value={this.props.watchlistPerson?.lei} />
                            }

                            {this.props.watchlistPerson?.datesOfBuild && this.props.watchlistPerson?.datesOfBuild.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_dobuild')} value={this.props.watchlistPerson?.datesOfBuild} />
                            }

                            {this.props.watchlistPerson?.datesOfRegistry && this.props.watchlistPerson?.datesOfRegistry.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_doregistry')} value={this.props.watchlistPerson?.datesOfRegistry} />
                            }

                            {this.props.watchlistPerson?.flags && this.props.watchlistPerson?.flags.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_flags')} value={this.props.watchlistPerson?.flags} />
                            }

                            {this.props.watchlistPerson?.datesOfBirth && this.props.watchlistPerson?.datesOfBirth.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_dob')} value={this.props.watchlistPerson.datesOfBirth} />
                            }

                            {this.props.watchlistPerson?.nationalities && this.props.watchlistPerson?.nationalities.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_nationality')} value={this.props.watchlistPerson.nationalities} />
                            }

                            {this.props.watchlistPerson?.placesOfBirth && this.props.watchlistPerson?.placesOfBirth.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_pobs')} value={this.props.watchlistPerson.placesOfBirth} location={true} />
                            }

                            {this.props.watchlistPerson?.placesOfRegistry && this.props.watchlistPerson?.placesOfRegistry.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_pobs')} value={this.props.watchlistPerson.placesOfRegistry} location={true} />
                            }

                            {this.props.watchlistPerson?.digitalCurrencies && this.props.watchlistPerson?.digitalCurrencies.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_digitalcurrency')} value={this.props.watchlistPerson?.digitalCurrencies} />
                            }

                            {this.props.watchlistPerson?.addresses && this.props.watchlistPerson?.addresses.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_location')} value={this.props.watchlistPerson.addresses} location={true} />
                            }

                            {this.props.watchlistPerson?.identityDocuments && this.props.watchlistPerson?.identityDocuments.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_idDoc')} value={this.props.watchlistPerson.identityDocuments} identificationDocument={true} />
                            }

                            {this.props.watchlistPerson?.additionalInformations && this.props.watchlistPerson?.additionalInformations.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_addinfo')} value={this.props.watchlistPerson?.additionalInformations} />
                            }

                            {this.props.watchlistPerson?.contactInformation && this.props.watchlistPerson?.contactInformation.length > 0 &&
                                <MultipleAttributeDisplay field={formatMessage('person_details_contactinfo')} value={this.props.watchlistPerson?.contactInformation} />
                            }
                        </div>
                    </div>
                    <div className="customer-tabs-largebox" >
                        <div className="customer-tab-largebox-head" >
                            <nav className="c-tabs__nav">
                                <ul className="c-tabs__list">
                                    {header.map((head, index) => {
                                        return (
                                            <li className="c-tabs__title" key={index}>
                                                <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.visibleTab ? 'is-active' : ''}`}
                                                    id={head.id}
                                                    name={head.id} onClick={this.changeVisibility}>
                                                    <span id={head.id}>{head.name}</span>
                                                </button>
                                            </li>
                                        )
                                    })}
                                </ul>
                            </nav>
                        </div>
                        <div className="customer-tab-largebox" style={{ display: 'block', textAlign: 'left' }}>
                            {this.state.tabSelected === HeaderConstant.RELATED_SOURCES &&
                                <WatchlistSources watchListPerson={this.props.watchlistPerson} />
                            }

                            {this.state.tabSelected === HeaderConstant.RELATED_KEYWORDS &&
                                <WatchlistKeywords watchListPerson={this.props.watchlistPerson} />
                            }
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private getHeader = () => {
        const header: Array<{ id: string, name: string, visibleTab: string, closeAble: boolean }> = [];

        header.push({ id: HeaderConstant.RELATED_KEYWORDS, name: formatMessage('watchlist_tab_header_keywords'), visibleTab: HeaderConstant.RELATED_KEYWORDS, closeAble: false });
        header.push({ id: HeaderConstant.RELATED_SOURCES, name: formatMessage('watchlist_tab_header_sources'), visibleTab: HeaderConstant.RELATED_SOURCES, closeAble: false });
        header.push({ id: HeaderConstant.RELATED_PERSONS, name: formatMessage('watchlist_tab_header_persons'), visibleTab: HeaderConstant.RELATED_PERSONS, closeAble: false });
        header.push({ id: HeaderConstant.RELATED_COMMENTS, name: formatMessage('watchlist_tab_header_comments'), visibleTab: HeaderConstant.RELATED_COMMENTS, closeAble: false });
        return header;
    }

    private changeVisibility = (event: any) => {
        this.setState({
            ...this.state,
            tabSelected: event.target.id
        })
    }

    private getFullNameForDisplay(name: Name): string {
        if (name.fullName && name.fullName.length > 0) {
            return name.fullName;
        }

        let fullNameConcat: string = '';

        if (name.surname) {
            fullNameConcat += name.surname;
            fullNameConcat += ' ';
        }

        if (name.fatherName) {
            fullNameConcat += name.fatherName;
            fullNameConcat += ' ';
        }

        if (name.givenName) {
            fullNameConcat += name.givenName;
        }

        return capitalizeWords(fullNameConcat.trim());
    }

}


const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
})

export const WatchlistTab = connect(mapStateToProps, null)(WatchlistTabClass);
