import React from "react";
import { connect } from "react-redux";

import { IRootState } from "../../store";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { formatMessage } from "../../common/translate/Translate";
import { Audit } from "../../model/audit/Audit";
import { IconsEnum } from "../../common/constant/IconsEnum";

interface IntProps {
    visible?: boolean,
    audits?: Audit[],
    importing?: boolean,
    close?: () => void
}

interface IntState {

}

export class DataSetImportStatusClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)
    }

    public render() {
        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ overflow: 'visible', width: '400px' }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <span className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </span>
                            <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.close}>
                                <svg className="o-icon o-icon--close o-icon--prepended">
                                    <title>{IconsEnum.CLOSE.title}</title>
                                    <use href={IconsEnum.CLOSE.url} />
                                </svg>
                            </button>
                        </div>
                        <div className="d-modal__view js-modal-view" style={{padding:'10px'}}>
                            {this.props.importing &&
                                <span className="attribute value">
                                    {formatMessage('import_processing')}
                                </span>
                            }

                            {!this.props.importing && this.props.audits && this.props.audits.map((rowElem, j) => {
                                return (
                                    <span className="attribute value">
                                        {rowElem.action}
                                        {rowElem.error}
                                    </span>
                                );
                            })}
                            {!this.props.importing && (!this.props.audits || this.props.audits.length === 0) &&
                                <span className="attribute value">
                                    {formatMessage('import_successfull')}
                                </span>
                            }

                            <div style={{ display: 'flex', paddingTop:'10px'}}>
                                <button className="m-button m-button--small-margin-bottom" style={{ margin: '5px' }} onClick={this.props.close}>
                                    {formatMessage('button_ok')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state: IRootState) => ({
    audits: state.datasetStore.importErrors,
    importing: state.datasetStore.importing
})

export const DataSetImportStatus = connect(mapStateToProps, null)(DataSetImportStatusClass);