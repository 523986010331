import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { IRootState } from "../../store";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { Person } from "../../model/persons/Person";

interface IntProps {
    watchListPerson?: Person
}

interface IntState {
    paginationRequest: PaginationRequest,
}

export class WatchlistSourcesClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        let paginationRequest: PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;

        this.state = {
            paginationRequest: paginationRequest
        }
    }

    public render() {
        return (
            <React.Fragment>
                {this.props.watchListPerson &&
                    <div className="d-modal__view js-modal-view">
                        <div className="grids-display">
                            <table className="c-table js-table">
                                <tbody className="c-table__body">
                                    {this.props.watchListPerson && this.props.watchListPerson.sources && this.props.watchListPerson.sources.map((rowElem, j) => {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem)} className={`c-table__row js-table-row`}>
                                                <td className="c-table__data c-table__smallfont">{rowElem}</td>
                                            </tr>
                                        );
                                    }
                                    )}
                                </tbody>
                            </table>
                        </div>
                    </div>
                }
            </React.Fragment>
        );
    }

    private sortHeader = (e: any) => {
        const value = e.target ? e.target.id : e.id;

        let paginationRequest: PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder: string = paginationRequest.sortField;

        if (paginationRequest.sortField === previousFieldOrder) {
            if (paginationRequest.sortOrder === 'DESC') {
                paginationRequest.sortOrder = 'ASC';
            } else {
                paginationRequest.sortOrder = 'DESC';
            }
        } else {
            paginationRequest.sortOrder = 'DESC';
        }

        this.setState({
            ...this.state,
            paginationRequest: paginationRequest
        })
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
})

export const WatchlistSources = connect(mapStateToProps, null)(WatchlistSourcesClass);