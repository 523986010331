export class AlertFieldsConstant {

    public static IDFIELD:string='id';

    public static SCOREFIELD:string='score';
    public static MATCHSCOREFIELD:string='matchScore';
    public static STATUSFIELD:string='status';
	
    
    public static ACCOUNTNUMBERFIELD:string='accountNumber';
    public static AGGREGATEFIELD:string='aggregate';
    public static PRIORITYFIELD:string='priority';
	public static NAMEFIELD:string='name';
    public static TYPEFIELD:string='type';

    public static OPENALERTSFIELD:string='openAlerts';

    public static DESCRIPTIONFIELD:string='description';
	
    public static MESSAGETYPEFIELD:string='messageType';
    public static TRANSACTIONREFERENCEFIELD:string='transactionReference';
    public static TRANSACTIONAMOUNTFIELD:string='transactionAmount';

    public static INVESTIGATEDBYFIELD:string='investigatedBy';

    public static ALERTAGEFIELD:string='alertAge';
    public static CREATIONDATEFIELD:string='creationDtg';
    public static ALERTDATEFIELD:string='alertDTG';
    public static LASTUPDATEFIELD:string='lastUpdateDTG';


    public static SEARCH_ALERTID:string = 'alertIdSearch';
    public static SEARCH_KEYWORD:string = 'keywordSearch';
    public static SEARCH_DATE1:string = 'date1Search';
    public static SEARCH_DATE2:string = 'date2Search';
    public static SEARCH_STATUS:string = 'status';


}