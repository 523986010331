import { DataSets } from "../dataset/DataSets";
import { MatchingRuleDefinition } from "./MatchingRuleDefinition";
import { MatchingRuleScoring } from "./MatchingRuleScoring";

export class MatchingRule {

    public id!:number;	
	public dataId!:string;
	public apiKey!:string;
	public organizationUnitId!:number;	
	public sourceDataSet!:DataSets;	
	
	public matchingRules!:MatchingRuleDefinition[];
	public matchingRuleScoring!:MatchingRuleScoring;

    public creationDate!:string;
	public createdBy!:string;
	public updateDate!:string;
	public updatedBy!:string;

}