
import { SearchStatistics } from "../../model/screena/SearchStatistics"
import { Constants } from "./constant"
import {  SearchScreenaDatasetStore } from "./type"


const init: SearchScreenaDatasetStore = {
    listOfPersons:[],
    searchStats:new SearchStatistics(),
    datasets:[]
} 

export const searchScreenaDatasetReducer = (state: SearchScreenaDatasetStore = init, action: any): SearchScreenaDatasetStore => {    
    switch (action.type) {      
        case Constants.SCREENASEARCH:  
            if (action.payload.results && action.payload.results.length>0 && action.payload.results[0].targetData) {
                return {
                    ...state,
                    searchStats: action.payload.searchStats,
                    listOfPersons: action.payload.results,
                  } 
            } else {
                return {
                    ...state,
                    searchStats: action.payload.searchStats,
                    listOfPersons: []
                } 
            }     
            
        case Constants.SCREENADATASETS:
            return {
                ...state,
                datasets: action.payload.datasets
            }

        case Constants.CLEANSEARCH:
            return {
                ...state,
                listOfPersons:[],
                searchStats:new SearchStatistics(),
            }

        case Constants.CLEANSEARCHSTATS:
            return {
                ...state,
                searchStats:undefined,
            }
            

        default:
            return {
				...state,
			}
    }
}