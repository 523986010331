import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { Person } from "../../model/persons/Person";
import { IRootState } from "../../store";
import { Pagination } from "../../components/pagination/Pagination";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { HeaderColumn } from "../../components/grid/HeaderColumn";

interface IntProps {
    person?:Person,
    persons?:Person[],
    totalAmountOfPersons?:number
}

interface IntState {
    paginationRequest : PaginationRequest,
}

export class CustomerLinksClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);
        
        let paginationRequest:PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 5;

        this.state = {
            paginationRequest : paginationRequest          
        }
    }

    private maxPage:number = 0;

    public render() {   
        if (this.props.totalAmountOfPersons) {
            this.maxPage = Math.ceil(this.props.totalAmountOfPersons / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        } 
        
        return ( 
                <React.Fragment>   
                    {this.props.person &&
                        <div className="grids-display" style={{display:'block', textAlign:'left'}}>
                            <table className="c-table js-table">
                                <thead className={`c-table__head`}>
                                    <tr>
                                        <HeaderColumn sortHeader={this.sortHeader} id="personId" display="common_id"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="personDataId" display="common_dataid"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="personName" display="common_name"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="personType" display="common_type"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="relationType" display="common_type"/>                                                                                                                   
                                    </tr>
                                </thead>
                                <tbody className="c-table__body">
                                    {this.props.persons &&  this.props.persons.map((rowElem, j) => {
                                            return (
                                                    <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row`}>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.dataID}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.names?rowElem.names.length>0?rowElem.names[0].fullName:'':''}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.entityType}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.type}</td>
                                                    </tr>
                                                );
                                        }
                                    )}
                                </tbody>                            
                        </table>
                        <div className="pagination-display">
                            {this.maxPage!==Number.NaN && this.maxPage>0 && 
                                    <Pagination 
                                                changeInput={this.changeInput} 
                                                onChangeNext={this.onChangeNext} 
                                                onChangePrevious={this.onChangePrevious} 
                                                maxPage={this.maxPage} 
                                                pageNumber={this.state.paginationRequest.pageNumber}/>     
                            } 
                        </div> 
                        </div>
                    }                  
                </React.Fragment>
        );
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                paginationRequest:paginationRequest                
            })            
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                paginationRequest:paginationRequest
            })            
        }        
    }

    private changeInput = (e: any) => {
        const value = e.target? e.target.value: e.value;
        if (value === '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value-1;
            this.setState({
                ...this.state,
                paginationRequest:paginationRequest
            })
          return;
        }
        if (value < 1) {
          return;
        }       
      }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;
       
        if (paginationRequest.sortField===previousFieldOrder) {
            if (paginationRequest.sortOrder==='DESC') {
                paginationRequest.sortOrder = 'ASC';    
            } else {
                paginationRequest.sortOrder = 'DESC';
            }
        } else {
            paginationRequest.sortOrder = 'DESC';
        }
        
        this.setState({
                ...this.state,
                paginationRequest:paginationRequest
        })
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,    
    person : state.personStore.currentPerson,
    persons : state.personStore.listOfRelatedPersons
})

export const CustomerLinks = connect(mapStateToProps, null)(CustomerLinksClass);