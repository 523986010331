import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { Person } from "../../model/persons/Person";
import { IRootState } from "../../store";
import { Alert } from "../../model/alerts/Alert";
import { Pagination } from "../../components/pagination/Pagination";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { getPaginatedAlert } from "../../store/alerts/action";
import { Constants } from "../../store/alerts/constant";
import { HeaderColumn } from "../../components/grid/HeaderColumn";

interface IntProps {
    person?:Person,
    alerts?:Alert[],
    totalAmountOfAlerts?:number
}

interface IntState {
    paginationRequest : PaginationRequest,
}

export class CustomerAlertsClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);
        
        let paginationRequest:PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 5;

        this.state = {
            paginationRequest : paginationRequest          
        }
    }

    private maxPage:number = 0;

    public render() {   
        if (this.props.totalAmountOfAlerts) {
            this.maxPage = Math.ceil(this.props.totalAmountOfAlerts / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        } 
        
        return ( 
                <React.Fragment>   
                    {this.props.person &&
                         <div className="d-modal__view js-modal-view">
                           <div className="grids-display">
                                <table className="c-table js-table">
                                <thead className={`c-table__head`}>
                                    <tr>
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertName" display="alert_alertName"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertId" display="alert_alertId"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertScore" display="alert_alertScore"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertPriority" display="alert_alertPriority"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertTrxnReference" display="alert_alertTrxnReference"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertDateTime" display="alert_alertDateTime"/>
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertType" display="alert_alertType"/>                   
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertStatus" display="alert_alertStatus"/> 
                                                                           
                                    </tr>
                                </thead>
                                <tbody className="c-table__body">
                                    {this.props.alerts &&  this.props.alerts.map((rowElem, j) => {                                   
                                            return (
                                                    <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row`}>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.name}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.score}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.priority}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.transactionReference}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.alertDate}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.type}</td>
                                                        <td className="c-table__data c-table__smallfont">{rowElem.status?rowElem.status.description:''}</td>
                                                    </tr>
                                                );
                                        }                          
                                    )}
                                </tbody>                            
                        </table> 
                    </div>
                    <div className="pagination-display">
                        {!Number.isNaN(this.maxPage) && this.maxPage>1 && 
                                <Pagination 
                                            changeInput={this.changeInput} 
                                            onChangeNext={this.onChangeNext} 
                                            onChangePrevious={this.onChangePrevious} 
                                            maxPage={this.maxPage} 
                                            pageNumber={this.state.paginationRequest.pageNumber}/>     
                        } 
                    </div> 
                        </div>  
                    }                  
                </React.Fragment>
        );
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                paginationRequest:paginationRequest                
            })
            getPaginatedAlert(paginationRequest, Constants.PERSONPAGEDLISTS);
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                paginationRequest:paginationRequest
            })
            getPaginatedAlert(paginationRequest, Constants.PERSONPAGEDLISTS);
        }        
    }

    private changeInput = (e: any) => {
        const value = e;
        if (value !== '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value;
            this.setState({
                ...this.state,
                paginationRequest:paginationRequest
            })
            getPaginatedAlert(paginationRequest, Constants.PERSONPAGEDLISTS);
          return;
        }
        if (value < 1) {
          return;
        }       
      }

      private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;
       
        if (paginationRequest.sortField===previousFieldOrder) {
            if (paginationRequest.sortOrder==='DESC') {
                paginationRequest.sortOrder = 'ASC';    
            } else {
                paginationRequest.sortOrder = 'DESC';
            }
        } else {
            paginationRequest.sortOrder = 'DESC';
        }
        
        this.setState({
                ...this.state,
                paginationRequest:paginationRequest
        })
        getPaginatedAlert(paginationRequest, Constants.PERSONPAGEDLISTS);
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    alerts    : state.alertStore.personListOfAlerts,
    totalAmountOfAlerts : state.alertStore.personAmountOfAlerts,
    person : state.personStore.currentPerson
})

export const CustomerAlerts = connect(mapStateToProps, null)(CustomerAlertsClass);