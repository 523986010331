import store from "..";
import { Constants } from "./constant";


export const showNotification: any = (variants: string, message: string, loadingCurrent: string, loadingMax: string) => {
    console.log('Call show notification');
	store.dispatch({
		payload: { variants, message, loadingCurrent, loadingMax, time: new Date().toLocaleTimeString(), hide:false },
		type: Constants.SHOW
	});
}