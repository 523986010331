import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Request } from "../../model/common/Request";
import { RiskMapping } from "../../model/risk/RiskMapping";
import { saveRiskConfiguration } from "../../store/risk/action";
import { RequestDataObject } from "../../model/common/RequestDataObject";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { OperationConstant } from "../../common/constant/OperationConstant";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    riskMapping?:RiskMapping,
    create?:boolean,
    closePopup?:() => void,
    screenakey?:string
}

interface IntState {
    scenario:string,  
    fieldName:string,
    fieldNames:string[],
    fieldType:string,
    riskCategory:string,
    riskSubCategory:string,
    entityType:string,
    dataId:string,
    noValue:boolean,
    scenarioName:string,
    score:number,
    operation:string,
    executionOrder:number,
    profileName:string,
    riskValueMappingDataset:string,
    errors:{
        scenario:string,
        dataId:string
    }
}

class RiskMappingDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            scenario:'',  
            fieldName:'',
            fieldNames:[],
            fieldType:'',
            riskCategory:'',
            riskSubCategory:'',
            entityType:'',
            dataId:'',
            noValue:false,
            scenarioName:'',
            score:0,
            operation:'',
            executionOrder:0,
            profileName:'',
            riskValueMappingDataset:'',
            errors:{
                scenario:'',
                dataId:''
            }     
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                scenario:'',  
                fieldName:'',
                fieldNames:[],
                fieldType:'',
                riskCategory:'',
                riskSubCategory:'',
                entityType:'',
                dataId:'',
                noValue:false,
                scenarioName:'',
                score:0,
                operation:'',
                executionOrder:0,
                profileName:'',
                riskValueMappingDataset:'',
                errors:{
                    scenario:'',
                    dataId:''
                }
            })
        } else if (nextProps.riskMapping) {             
          this.setState({
                scenario : nextProps.riskMapping.scenario,
                fieldName : nextProps.riskMapping.fieldName,
                fieldNames : nextProps.riskMapping.filterFieldNames,
                riskCategory : nextProps.riskMapping.riskCategory,
                riskSubCategory : nextProps.riskMapping.riskSubCategory,
                entityType : nextProps.riskMapping.entityType,
                dataId : nextProps.riskMapping.dataId,
                noValue : nextProps.riskMapping.noValue,
                scenarioName : nextProps.riskMapping.scenarioName,
                score : nextProps.riskMapping.score,
                operation : nextProps.riskMapping.operation,
                executionOrder : nextProps.riskMapping.executionOrder,
                profileName : nextProps.riskMapping.profileName,
                riskValueMappingDataset : nextProps.riskMapping.riskValueMappingDataset
          })
        }
           
      }

    public render() {    

        let fieldValues:{value: string;label: string; type:string}[] = [];


       if (this.state.fieldName && !(this.state.fieldName.length===0)) {
            for (var fieldNme of OperationConstant.LISTOFFIELDS_TYPE) {
               if (fieldNme.value===this.state.fieldName) {
                    fieldValues.push(fieldNme);
               }
            }
        }

        if (this.state.fieldNames && !(this.state.fieldNames.length===0)) {
            for (var fieldNameStr of this.state.fieldNames) {
                for (var fieldNme of OperationConstant.LISTOFFIELDS_TYPE) {
                    if (fieldNme.value===fieldNameStr) {
                        fieldValues.push(fieldNme);
                    }
                }
            }
        }

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "41rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                               
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_dataId')}
                                </label>
                                <input
                                    className="o-input"
                                    name="scenario"
                                    placeholder={formatMessage('risk_conf_dataId')}
                                    value={this.state.dataId}
                                    onChange={this.changeDataId}
                                    style={{ marginTop: '2px' }}
                                />
                                 {this.state.errors && this.state.errors["dataId"] &&
                                    <span className="form-error">{this.state.errors["dataId"]}</span>
                                 }
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_scenario')}
                                </label>
                                <input
                                    className="o-input"
                                    name="scenario"
                                    placeholder={formatMessage('risk_conf_scenario')}
                                    value={this.state.scenario}
                                    onChange={this.changeScenario}
                                    style={{ marginTop: '2px' }}
                                />
                                 {this.state.errors && this.state.errors["scenario"] &&
                                    <span className="form-error">{this.state.errors["scenario"]}</span>
                                 }
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_executionorder')}
                                </label>
                                <input
                                    className="o-input"
                                    name="scenario"
                                    placeholder={formatMessage('risk_conf_executionorder')}
                                    value={this.state.executionOrder}
                                    onChange={this.changeExecutionOrder}
                                    style={{ marginTop: '2px' }}
                                />                               
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_fieldname')}
                                </label>
                                 <div style={{ width: '100%' }}>
                                    <Select name="risk_conf_fieldname"                                                                                                                     
                                            className="basic-multi-select"
                                            value={fieldValues}
                                            isMulti
                                            options={OperationConstant.LISTOFFIELDS_TYPE}
                                            classNamePrefix="select"
                                            onChange={this.changeFieldName}
                                    />    
                                </div>                        
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_riskCategory')}
                                </label>
                                <input
                                    className="o-input"
                                    name="riskCategory"
                                    placeholder={formatMessage('risk_conf_riskCategory')}
                                    value={this.state.riskCategory}
                                    onChange={this.changeRiskCategory}
                                    style={{ marginTop: '2px' }}
                                />                                 
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_riskSubCategory')}
                                </label>
                                <input
                                    className="o-input"
                                    name="riskSubCategory"
                                    placeholder={formatMessage('risk_conf_riskSubCategory')}
                                    value={this.state.riskSubCategory}
                                    onChange={this.changeRiskSubCategory}
                                    style={{ marginTop: '2px' }}
                                />                                 
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_score')}
                                </label>
                                <input
                                    className="o-input"
                                    name="riskScore"
                                    placeholder={formatMessage('risk_conf_score')}
                                    value={this.state.score}
                                    onChange={this.changeRiskScore}
                                    style={{ marginTop: '2px' }}
                                />                                 
                            </div> 

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_profileName')}
                                </label>
                                <input
                                    className="o-input"
                                    name="riskProfileName"
                                    placeholder={formatMessage('risk_conf_profileName')}
                                    value={this.state.profileName}
                                    onChange={this.changeProfileName}
                                    style={{ marginTop: '2px' }}
                                />                                 
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_operation')}
                                </label>
                                <input
                                    className="o-input"
                                    name="riskOperation"
                                    placeholder={formatMessage('risk_conf_operation')}
                                    value={this.state.operation}
                                    onChange={this.changeOperation}
                                    style={{ marginTop: '2px' }}
                                />                                 
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_riskValueMappingDataset')}
                                </label>
                                <input
                                    className="o-input"
                                    name="riskValueMappingDataset"
                                    placeholder={formatMessage('risk_conf_riskValueMappingDataset')}
                                    value={this.state.riskValueMappingDataset}
                                    onChange={this.changeRiskValueMappingDataset}
                                    style={{ marginTop: '2px' }}
                                />                                 
                            </div>  

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '100px' }}>
                                    {formatMessage('risk_conf_droolsscenario')}
                                </label>
                                <input
                                    className="o-input"
                                    name="scenario"
                                    placeholder={formatMessage('risk_conf_droolsscenario')}
                                    value={this.state.scenarioName}
                                    onChange={this.changeScenarioName}
                                    style={{ marginTop: '2px' }}
                                />                               
                            </div>                          
                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveRiskMapping} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changeDataId = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            dataId: event.target.value
        });
    }

    private changeScenarioName = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            scenarioName: event.target.value
        });
    }
    
    private changeScenario = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            scenario: event.target.value
        });
    }

    private changeExecutionOrder = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            executionOrder: event.target.value
        });
    }

    private changeFieldName =  (values: OptionsType<{value: string;label: string;type:string;}>,
                                 actionMeta: ActionMeta<{value: string;label: string;type:string;}>):void => {

        if (values) {
            if (values.length===1) {
                this.setState({                        
                    ...this.state,      
                    fieldName: values[0].value,
                    fieldType: values[0].type
                });
            } else {
                let fieldNames:string[] = [];
                for (var value of values) {
                    fieldNames.push(value.value);
                }

                this.setState({                        
                    ...this.state,      
                    fieldName: '',
                    fieldType: '',
                    fieldNames: fieldNames
                });
            }
        }

    }

    private changeRiskCategory = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            riskCategory: event.target.value
        });
    }

    private changeRiskSubCategory = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            riskSubCategory: event.target.value
        });
    }

    private changeEntityType = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            entityType: event.target.value
        });
    }

    private changeProfileName = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            profileName: event.target.value
        });
    }

    private changeOperation = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            operation: event.target.value
        });
    }
    
    private changeRiskValueMappingDataset = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            riskValueMappingDataset: event.target.value
        });
    }

    private changeRiskScore = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            score: event.target.value
        });
    }

    private saveRiskMapping = () : void => {
        let request:Request = new Request();
        request.queries = [];
        let requestDataObj : RequestDataObject = new RequestDataObject();
        requestDataObj.riskMapping = [];
        if (this.props.create) {
            let riskMapping:RiskMapping = new RiskMapping();
            riskMapping.scenario = this.state.scenario;
            riskMapping.operation = this.state.operation;
            riskMapping.profileName = this.state.profileName;
            riskMapping.fieldName = this.state.fieldName;
            riskMapping.filterFieldNames = this.state.fieldNames;
            riskMapping.riskCategory = this.state.riskCategory;
            riskMapping.riskSubCategory = this.state.riskSubCategory;
            riskMapping.dataId = this.state.dataId;
            riskMapping.noValue = this.state.noValue;
            riskMapping.scenarioName = this.state.scenarioName;
            riskMapping.score = this.state.score;
            riskMapping.executionOrder = this.state.executionOrder;
            riskMapping.riskValueMappingDataset = this.state.riskValueMappingDataset;
            requestDataObj.riskMapping.push(riskMapping);
            
        } else if (this.props.riskMapping) {
            let riskMapping:RiskMapping = this.props.riskMapping;
            riskMapping.scenario = this.state.scenario;
            riskMapping.operation = this.state.operation;
            riskMapping.profileName = this.state.profileName;
            riskMapping.fieldName = this.state.fieldName;
            riskMapping.filterFieldNames = this.state.fieldNames;
            riskMapping.riskCategory = this.state.riskCategory;
            riskMapping.riskSubCategory = this.state.riskSubCategory;
            riskMapping.dataId = this.state.dataId;
            riskMapping.noValue = this.state.noValue;
            riskMapping.scenarioName = this.state.scenarioName;
            riskMapping.score = this.state.score;
            riskMapping.executionOrder = this.state.executionOrder;
            riskMapping.riskValueMappingDataset = this.state.riskValueMappingDataset;
            requestDataObj.riskMapping.push(riskMapping);
        }
        request.queries.push(requestDataObj);
        saveRiskConfiguration(request, this.props.screenakey);

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    screenakey : state.loginStore.screenaapikey 
})

export const RiskMappingDetailsPopup = connect(mapStateToProps, null)(RiskMappingDetailsPopupClass);