import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/attribute.css'
import '../../common/css/card.css'
import { formatMessage } from "../../common/translate/Translate";
import { Person } from "../../model/persons/Person";
import { IRootState } from "../../store";
import { AttributeDisplay } from "../../components/attribute/AttributeDisplay";
import { MultipleAttributeDisplay } from "../../components/attribute/MultipleAttributeDisplay";
import { MultipleAttributeDisplayName } from "../../components/attribute/MultipleAttributeDisplayName";

interface IntProps {
    person?: Person
}

interface IntState {

}

export class CustomerPropertiesClass extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <React.Fragment>
                {this.props.person &&
                    <div className="card attribute">
                        <AttributeDisplay field={formatMessage('person_details_dataid')}
                            value={this.props.person ? this.props.person.dataID : ''} />

                        {this.props.person?.names && this.props.person?.names.length > 0 &&
                            <MultipleAttributeDisplayName field={formatMessage('person_details_fullName')}
                                value={this.props.person.names} />
                        }

                        <AttributeDisplay field={formatMessage('person_details_sex')}
                            value={this.props.person ? this.props.person.sex : ''}
                            sex={true} />

                        {this.props.person?.titles && this.props.person?.titles.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_titles')} value={this.props.person?.titles} />
                        }

                        {this.props.person?.bic &&
                            <AttributeDisplay field={formatMessage('person_details_bic')} value={this.props.person?.bic} />
                        }

                        {this.props.person?.lei &&
                            <AttributeDisplay field={formatMessage('person_details_lei')} value={this.props.person?.lei} />
                        }

                        {this.props.person?.datesOfBuild && this.props.person?.datesOfBuild.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_dobuild')} value={this.props.person?.datesOfBuild} />
                        }

                        {this.props.person?.datesOfRegistry && this.props.person?.datesOfRegistry.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_doregistry')} value={this.props.person?.datesOfRegistry} />
                        }

                        {this.props.person?.datesOfBirth && this.props.person?.datesOfBirth.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_dob')} value={this.props.person.datesOfBirth} />
                        }

                        {this.props.person?.flags && this.props.person?.flags.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_flags')} value={this.props.person?.flags} />
                        }

                        {this.props.person?.nationalities && this.props.person?.nationalities.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_nationality')} value={this.props.person.nationalities} />
                        }

                        {this.props.person?.placesOfRegistry && this.props.person?.placesOfRegistry.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_pors')} value={this.props.person.placesOfRegistry} location={true}/>
                        }

                        {this.props.person?.placesOfBirth && this.props.person?.placesOfBirth.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_pobs')} value={this.props.person.placesOfBirth} location={true}/>
                        }

                        {this.props.person?.addresses && this.props.person?.addresses.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_location')} value={this.props.person.addresses} location={true}/>
                        }

                        {this.props.person?.identityDocuments && this.props.person?.identityDocuments.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_idDoc')} value={this.props.person.identityDocuments} identificationDocument={true}/>
                        }

                        {this.props.person?.additionalInformations && this.props.person?.additionalInformations.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_addinfo')} value={this.props.person?.additionalInformations} />
                        }      

                        {this.props.person?.contactInformation && this.props.person?.contactInformation.length > 0 &&
                            <MultipleAttributeDisplay field={formatMessage('person_details_contactinfo')} value={this.props.person?.contactInformation} />
                        }      

                    </div>
                }
            </React.Fragment>
        );
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    person: state.personStore.currentPerson
})

export const CustomerProperties = connect(mapStateToProps, null)(CustomerPropertiesClass);