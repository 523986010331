import React, { ReactElement } from 'react';
import ReactDOM from 'react-dom';
import {IntlProvider} from 'react-intl';
import './common/css/loader.css';
import './common/css/radar.css';
import { Provider } from 'react-redux';
import store from './store';
import reportWebVitals from './reportWebVitals';

import * as dotenv from 'dotenv';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from 'react-router-dom';
import { LoginHOC } from './scene/login/Login';
import { MainHOC } from './scene/main/Main';
import { globalConfig, globalConfigUrl } from './common/constant/GlobalVariable';
import axios from 'axios';
import { URLConstants } from './common/constant/URLConstant';


dotenv.config();

let path;
switch (process.env.NODE_ENV) {
  case "production":
    path = `../.env.production`;
    break;
  case "test":
    path = `../.env.test`;
    break;
  default:
    path = '../.env';
 }

dotenv.config({ path: path });

axios.get(globalConfigUrl)
  .then((response) => {
    globalConfig.config = response.data;    
    URLConstants.baseUrl = globalConfig.config.apiUrl;
    URLConstants.screenaBaseUrl = globalConfig.config.screenaUrl
    ReactDOM.render(
      <React.StrictMode>
        <IntlProvider locale={navigator.language}>
          <Provider store={store}>      
            <Router >
              <Switch>
                <Route path="/login">
                  <LoginHOC/>
                </Route> 
                <Route path="/main" >
                  <MainHOC/>
                </Route>                            
                <Route path="/">
                  <LoginHOC />
                </Route>
                <Redirect to="/"/>
              </Switch>
            </Router>          
          </Provider>
        </IntlProvider>
      </React.StrictMode>,
      document.getElementById('root')
    );
  })
  .catch(e => {
      return <p style={{color: "red", textAlign: "center"}}>Error while fetching global config</p>;
  })
  

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
