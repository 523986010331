import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { DataSets } from "../../model/dataset/DataSets";
import { MatchingRuleDefinition } from "../../model/screening/MatchingRuleDefinition";

import Select, { ActionMeta, OptionsType } from 'react-select';

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    matchingRuleDefinition?:MatchingRuleDefinition,
    create?:boolean,
    closePopup?:() => void,
    saveMatchingRuleDefinition?:(matchingRuleDef:MatchingRuleDefinition) => void,
    fullListOfDatasets?:DataSets[],    
}

interface IntState {
    name:string,  
    permutation:boolean,
    truncation:boolean,
    targetDataSets:DataSets[],
    treshold:number,
    errors:{        
        targetDataSets:string
    }
}

class MatchingRuleDefinitionDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {           
            name:'',
            permutation:false,
            truncation:false,
            targetDataSets:[],   
            treshold:-1,        
            errors:{
                targetDataSets:''
            }     
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                name:'',
                permutation:false,
                truncation:false,
                targetDataSets:[], 
                treshold:-1,       
                errors:{
                    targetDataSets:''
                }    
            })
        } else if (nextProps.matchingRuleDefinition) {             
          this.setState({
                name : nextProps.matchingRuleDefinition.name,
                truncation : nextProps.matchingRuleDefinition.truncation,
                permutation : nextProps.matchingRuleDefinition.permutation,
                treshold : nextProps.matchingRuleDefinition.treshold,
                targetDataSets : nextProps.matchingRuleDefinition.targetDataSets
          })
        }
           
      }

    public render() {    

       
        let optionsDatasets:{value: string;label: string;}[] = [];
        let selectedDataset:{value: string;label: string;}[] = [];
        if (this.props.fullListOfDatasets) {
            let i:number = 0;
            for (var dataset of this.props.fullListOfDatasets) {
                optionsDatasets.push(
                    {
                        value : String(dataset.id),
                        label : dataset.label
                    }
                )

                if (this.state.targetDataSets) {
                    for (var seldataset of this.state.targetDataSets) {
                        if (dataset.id===seldataset.id) {
                            selectedDataset.push (optionsDatasets[i]);
                        }
                    }
                }

                i++;
            }
        }

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal-sub" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "40rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                               
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div style={{ display: 'flex' }} >
                            <label className="o-label" style={{ width: '100px' }}>
                                {formatMessage('common_name')}
                            </label>
                            <input
                                    className="o-input"
                                    name="name"
                                    placeholder={formatMessage('common_name')}
                                    value={this.state.name}
                                    onChange={this.changeName}
                                    style={{ marginTop: '2px' }}
                                />                                
                        </div>

                        <div style={{ display: 'flex' }} >
                            <label className="o-label" style={{ width: '100px' }}>
                                {formatMessage('matchingRule_treshold')}
                            </label>
                            <input
                                    className="o-input"
                                    name="name"
                                    placeholder={formatMessage('matchingRule_treshold')}
                                    value={this.state.treshold}
                                    onChange={this.changeTreshold}
                                    style={{ marginTop: '2px' }}
                                />                                
                        </div>

                        <label className="o-label">{formatMessage('matchingRule_datasets')}</label>
                        <Select options={optionsDatasets}
                                 name="datasets"                                                                              
                                 isMulti
                                 className="basic-multi-select"
                                 classNamePrefix="select"
                                 onChange={this.changeDataSet}
                                 value={selectedDataset}
                        />

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveMatchingRule} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private changeName = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            name: event.target.value
        });
    }

    private changeTreshold = (event: { target: { value: any; }; }):void => {

        if (Number(event.target.value)===Number.NaN) {
            this.setState({                        
                ...this.state,      
                treshold: 0
            });    
        } else {
            this.setState({                        
                ...this.state,      
                treshold: Number(event.target.value)
            });
        }
    }

    private changeDataSet = (values: OptionsType<{value: string;label: string;}>, actionMeta: ActionMeta<{value: string;label: string;}>):void => {
        let arrayOfDataset = [];

        for (var value of values) {
            arrayOfDataset.push(this.getDatasetFromId(Number(value.value)));
        }

        this.setState({                        
            ...this.state, 
            targetDataSets : arrayOfDataset     
        });
    }

    private getDatasetFromId = (datasetId:number) : DataSets => {
        if (this.props.fullListOfDatasets) {          
            for (var dataset of this.props.fullListOfDatasets) {
                if (dataset.id === datasetId) {
                    return dataset;
                }
            }
        }

        return new DataSets();
    }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;
    }

    private saveMatchingRule = () : void => {
        
        if (this.props.saveMatchingRuleDefinition) {
            let matchRuleDef:MatchingRuleDefinition ;
            if (this.props.matchingRuleDefinition) {
                matchRuleDef = this.props.matchingRuleDefinition;
            } else {
                matchRuleDef = new MatchingRuleDefinition();
            }

            matchRuleDef.name = this.state.name;
            matchRuleDef.permutation = this.state.permutation;
            matchRuleDef.truncation = this.state.truncation;
            matchRuleDef.treshold = this.state.treshold;
            matchRuleDef.targetDataSets = this.state.targetDataSets;
            this.props.saveMatchingRuleDefinition(matchRuleDef);
        }

    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,  
    fullListOfDatasets: state.datasetStore.fullListOfDatasets,
})

export const MatchingRuleDefinitionDetailsPopup = connect(mapStateToProps, null)(MatchingRuleDefinitionDetailsPopupClass);