import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { Pagination } from "../../components/pagination/Pagination";
import { deleteUser, getPaginatedUsers, getTotalAmountOfUsers } from "../../store/user/action";
import { User } from "../../model/user/User";
import { UserDetailsPopup } from "./UserDetailsPopup";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { AdminContextMenu } from "./AdminContextMenu";

interface IntProps {
    isLoading?:Boolean,
    users?:User[],
    totalAmountOfUsers?:number
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,    

    userDetailsVisible:boolean,
    userCreate:boolean,
}

class UserClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            paginationRequest : new PaginationRequest(),
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false,
            userDetailsVisible: false,
            userCreate: false     
        }

        getPaginatedUsers(this.state.paginationRequest);
        getTotalAmountOfUsers(this.state.paginationRequest);
    }

    private maxPage:number = 0;

    public render() {
        
        if (this.props.totalAmountOfUsers) {
            this.maxPage = Math.ceil(this.props.totalAmountOfUsers / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        }
        
        return (
            <React.Fragment> 
                <UserDetailsPopup closePopup={this.closeUserDetails} visible={this.state.userDetailsVisible} 
                                    user={this.getCurrentUser(this.state.selectedRow)} 
                                    create={this.state.userCreate}
                                    paginationRequest={this.state.paginationRequest}/>
                <AdminContextMenu visible={this.state.contextMenuVisible} x={this.state.contextMenuX} y={this.state.contextMenuY}
                                    deleteAction={this.deleteUser}
                                    editAction={this.displayUserDetails}
                />
                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu}>  
                    <div style={{ marginLeft: '20px', marginRight: '10px', width: '98%', display: 'flex' }}>
                        <label className="o-label title" style={{ width: '98%'}}> {formatMessage('admin_main_user')}</label>
                        <button className="m-button m-button--small-margin-bottom"  onClick={this.createUser} style={{marginTop: '5px', width: '10rem'}}>
                            {formatMessage('button_create')}
                        </button>
                    </div>
                    <div className="grids-display">
                        <table className="c-table js-table">
                            <thead className={`c-table__head`}>
                                <tr>
                                    <HeaderColumn sortHeader={this.sortHeader} id="userId" display="common_id"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="userName" display="common_name"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="userEmail" display="user_email"/>

                                    <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedBy" display="common_updatedBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedOn" display="common_updatedOn"/>                             
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.users &&  this.props.users.map((rowElem, j) => {                                   
                                        return (
                                                <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                                    onDoubleClick={this.displayUserDetails}>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.userName}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.email}</td>                                                    
                                                    <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td>  
                                                    <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td>  
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>  
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedDate}</td>  
                                                </tr>
                                            );
                                    }                          
                                )}
                            </tbody>                            
                        </table> 
                    </div>
                    <div className="pagination-display">
                        {this.maxPage!==Number.NaN && this.maxPage>0 && 
                            <Pagination 
                                    changeInput={this.changeInput} 
                                    onChangeNext={this.onChangeNext} 
                                    onChangePrevious={this.onChangePrevious} 
                                    maxPage={this.maxPage} 
                                    pageNumber={this.state.paginationRequest.pageNumber}/>      
                        }
                    </div>  
                </div>  
            </React.Fragment>
        );
    }

    private deleteUser = () => {
        deleteUser(String(this.state.selectedRow), this.state.paginationRequest);
        this.setState({
            ...this.state,
            contextMenuVisible: false
        })
    }

    private getCurrentUser = (userId: number) : User=> {
        if (this.props.users) {
            for (var user of this.props.users) {
                if (user.id===userId) {
                    return user;
                }
            }
        }        
        return new User();
    }

    private createUser = () => {
        this.setState({
            ...this.state,
            userDetailsVisible:true,
            selectedRow:-1,
            userCreate:true
        })
    }

    private displayUserDetails = () => {
        this.setState({
            ...this.state,
            userDetailsVisible:true,
            userCreate:false,
            contextMenuVisible: false
        })
    }

    private closeUserDetails = () => {    
        this.setState({
            ...this.state,
            userDetailsVisible:false,
            contextMenuVisible: false,
            userCreate:false
        })
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                contextMenuVisible: false,
                paginationRequest:paginationRequest                
            })
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
        }        
    }

    private changeInput = (e: any) => {
        const value = e.target? e.target.value: e.value;
        if (value === '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
          return;
        }
        if (value < 1) {
          return;
        }       
    }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    users : state.userStore.listOfUsers,
    totalAmountOfUsers : state.userStore.amountOfUsers
})

export const UserAdmin = connect(mapStateToProps, null)(UserClass);