import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { Location } from "../../model//persons/Location"
import { IdentificationDocument } from "../../model/persons/IdentificationDocument";
import { NormDate } from "../../model/persons/NormDate";
import { getCountryDescription, getFlagUrl } from "../../common/helper/CountryHelper";
import { IRootState } from "../../store";
import { Country } from "../../model/library/Country";
import { getPersonNameFromName } from "../../common/helper/NameHelper";
import { formatDate } from "../../common/helper/DateHelper";
import { formatMessage } from "../../common/translate/Translate";
import { DigitalCurrency } from "../../model/persons/DigitalCurrency";
import { MatchingDate } from "../../model/screena/MatchingDate";

interface IntProps {
    field?: string,
    value?: any[],
    amountOnTarget?: number,
    countries?: Country[],
    identificationDocument?: boolean,
    digitalCurrencies?: boolean,
    location?: boolean,
    urls?: boolean,
    noUpperCase?: boolean
}

interface IntState {
    open: boolean
}

export class MultipleAttributeDisplayClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = ({
            open: false
        });
    }

    public render() {

        const addItems = [];

        if (!(this.props.value && this.props.value.length > 0)) {
            addItems.push(
                <div className="attribute body">
                    <span className="attribute label withvalue">
                        {this.props.field}
                    </span>
                    <span className="attribute value">
                    </span>
                </div>
            );
        }

        if (this.props.value && this.props.value.length > 0) {
            if (this.state.open || this.props.value.length === 1) {
                return (
                    <React.Fragment>
                        {this.props.value.map((elem, j) => {
                            let strValue: string = this.getStrValueFromObject(elem);
                            let numValue: number = j + 1;
                            return (
                                <React.Fragment>
                                    <div id={"div" + this.props.field + j} className="attribute body">
                                        <span id={"spanlbl" + this.props.field + j} className="attribute label withvalue">
                                            {j === 0 ? this.props.field : ''}
                                            {j > 0 && (this.props.identificationDocument || this.props.location || this.props.digitalCurrencies) && this.props.field}
                                            {(this.props.identificationDocument || this.props.location || this.props.digitalCurrencies)
                                                && this.props.value && this.props.value?.length > 1 ? ' ' + numValue : ''}
                                        </span>
                                        {!this.props.identificationDocument && !this.props.location && !this.props.urls && !this.props.digitalCurrencies &&
                                            <span id={"spanvlue" + this.props.field + j} className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                {elem["country"] &&
                                                    <React.Fragment>
                                                        <img src={getFlagUrl(elem["country"])} width="14px" height="12px" />
                                                        {' ' + getCountryDescription(elem["country"], this.props.countries)}
                                                    </React.Fragment>
                                                }
                                                {elem["state"] && ', ' + elem.state}
                                                {elem["city"] && ', ' + elem.city}
                                                {elem["street"] && ', ' + elem.street}
                                                {strValue}
                                            </span>
                                        }

                                        {this.props.urls &&
                                            <React.Fragment>
                                                {strValue.startsWith('http') &&
                                                    <a href={strValue} target="_blank" className="attribute value a">{strValue}</a>
                                                }
                                                {!strValue.startsWith('http') &&
                                                    <span id={"spanvlue" + this.props.field + j} className="attribute value a">
                                                        {strValue}
                                                    </span>
                                                }
                                            </React.Fragment>
                                        }

                                        {j === 0 && this.props.value && this.props.value.length > 1 &&
                                            <span id={"bdg" + this.props.field + j} className="badge" onClick={this.openAttribute}>
                                                {'-'}
                                            </span>
                                        }
                                    </div>

                                    {this.props.digitalCurrencies &&
                                        <React.Fragment>
                                            {(elem as DigitalCurrency).currency &&
                                                <div className="attribute body" >
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('digitalcurrency_currency')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as DigitalCurrency).currency}
                                                    </span>
                                                </div>
                                            }

                                            {(elem as DigitalCurrency).identifier &&
                                                <div className="attribute body" >
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('digitalcurrency_identifier')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as DigitalCurrency).identifier}
                                                    </span>
                                                </div>
                                            }
                                        </React.Fragment>
                                    }

                                    {this.props.location &&
                                        <React.Fragment>

                                            {(elem as Location).street &&
                                                <div className="attribute body" >
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('location_street')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as Location).street}
                                                        {(elem as Location).streetNumber && ',' + (elem as Location).streetNumber}
                                                    </span>
                                                </div>
                                            }

                                            {(elem as Location).streetName &&
                                                <div className="attribute body" >
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('location_street')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as Location).streetName}
                                                        {(elem as Location).streetNumber && ',' + (elem as Location).streetNumber}
                                                    </span>
                                                </div>
                                            }

                                            {(elem as Location).city &&
                                                <div className="attribute body" >
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('location_city')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as Location).city}
                                                    </span>
                                                </div>
                                            }

                                            {(elem as Location).state &&
                                                <div className="attribute body" >
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('location_state')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as Location).state}
                                                    </span>
                                                </div>
                                            }

                                            {(elem as Location).zipCode &&
                                                <div className="attribute body" >
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('location_zipcode')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as Location).zipCode}
                                                    </span>
                                                </div>
                                            }

                                            {elem["country"] &&
                                                <div className="attribute body">
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('location_country')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {elem["country"] &&
                                                            <img src={getFlagUrl(elem["country"])} width="14px" height="12px" />
                                                        }
                                                        {elem["country"] &&
                                                            ' ' + getCountryDescription(elem["country"], this.props.countries)
                                                        }
                                                    </span>
                                                </div>
                                            }

                                        </React.Fragment>
                                    }

                                    {
                                        this.props.identificationDocument &&
                                        <React.Fragment>
                                            {(elem as IdentificationDocument).number &&
                                                <div className="attribute body">
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('iddoc_number')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as IdentificationDocument).number}
                                                    </span>
                                                </div>
                                            }

                                            {(elem as IdentificationDocument).type &&
                                                <div className="attribute body" >
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('iddoc_type')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as IdentificationDocument).type}
                                                    </span>
                                                </div>
                                            }

                                            {(elem as IdentificationDocument).country &&
                                                <div className="attribute body">
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('iddoc_country')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {elem["country"] &&
                                                            <img src={getFlagUrl((elem as IdentificationDocument).country)} width="14px" height="12px" />
                                                        }
                                                        {elem["country"] &&
                                                            ' ' + getCountryDescription((elem as IdentificationDocument).country, this.props.countries)
                                                        }
                                                    </span>
                                                </div>
                                            }

                                            {(elem as IdentificationDocument).dateOfIssue &&
                                                <div className="attribute body">
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('iddoc_dateofissue')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {formatDate((((elem as IdentificationDocument).dateOfIssue) as MatchingDate).date)}
                                                    </span>
                                                </div>
                                            }

                                            {(elem as IdentificationDocument).dateOfExpiry &&
                                                <div className="attribute body">
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('iddoc_dateofexpiry')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {formatDate((((elem as IdentificationDocument).dateOfExpiry) as MatchingDate).date)}
                                                    </span>
                                                </div>
                                            }

                                            {(elem as IdentificationDocument).description &&
                                                <div className="attribute body">
                                                    <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                                        {formatMessage('common_description')}
                                                    </span>
                                                    <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                                        {(elem as IdentificationDocument).description}
                                                    </span>
                                                </div>
                                            }

                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            );
                        })
                        }
                        {addItems}
                    </React.Fragment>
                );
            } else {

                let strValue: string = this.getStrValueFromObject(this.props.value[0]);

                return (
                    <React.Fragment>
                        <div className="attribute body">
                            <span className="attribute label withvalue">
                                {this.props.field}
                                {(this.props.identificationDocument || this.props.location || this.props.digitalCurrencies)
                                    && this.props.value.length > 1 ? ' 1' : ''
                                }
                            </span>
                            {!this.props.identificationDocument && !this.props.location && !this.props.urls && !this.props.digitalCurrencies &&
                                <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                    {this.props.value[0]["country"] &&
                                        <img src={getFlagUrl(this.props.value[0]["country"])} width="14px" height="12px" />
                                    }
                                    {this.props.value[0]["country"] &&
                                        ' ' + getCountryDescription(this.props.value[0]["country"], this.props.countries)
                                    }
                                    {this.props.value[0]["state"] && ', ' + this.props.value[0].state}
                                    {this.props.value[0]["city"] && ', ' + this.props.value[0].city}
                                    {this.props.value[0]["street"] && ', ' + this.props.value[0].street}
                                    {strValue}
                                </span>
                            }

                            {this.props.urls &&
                                <React.Fragment>
                                    {strValue.startsWith('http') &&
                                        <a href={strValue} target="_blank" className="attribute value a">{strValue}</a>
                                    }
                                    {!strValue.startsWith('http') &&
                                        <span id={"spanvlue" + this.props.field} className="attribute value a">
                                            {strValue}
                                        </span>
                                    }
                                </React.Fragment>
                            }
                            <span className="badge" onClick={this.openAttribute}>
                                {'+' + String(this.props.value.length - 1)}
                            </span>
                        </div>

                        {this.props.digitalCurrencies &&
                            <React.Fragment>
                                {(this.props.value[0] as DigitalCurrency).currency &&
                                    <div className="attribute body" >
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('digitalcurrency_currency')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as DigitalCurrency).currency}
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as DigitalCurrency).identifier &&
                                    <div className="attribute body" >
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('digitalcurrency_identifier')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as DigitalCurrency).identifier}
                                        </span>
                                    </div>
                                }
                            </React.Fragment>
                        }

                        {this.props.identificationDocument &&
                            <React.Fragment>

                                {(this.props.value[0] as IdentificationDocument).number &&
                                    <div className="attribute body" >
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('iddoc_number')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as IdentificationDocument).number}
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as IdentificationDocument).type &&
                                    <div className="attribute body" >
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('iddoc_type')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as IdentificationDocument).type}
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as IdentificationDocument).country &&
                                    <div className="attribute body">
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('iddoc_country')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {this.props.value[0]["country"] &&
                                                <img src={getFlagUrl(this.props.value[0]["country"])} width="14px" height="12px" />
                                            }
                                            {this.props.value[0]["country"] &&
                                                ' ' + getCountryDescription(this.props.value[0]["country"], this.props.countries)
                                            }
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as IdentificationDocument).dateOfIssue &&
                                    <div className="attribute body">
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('iddoc_dateofissue')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {formatDate((((this.props.value[0] as IdentificationDocument).dateOfIssue) as MatchingDate).date)}
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as IdentificationDocument).dateOfExpiry &&
                                    <div className="attribute body">
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('iddoc_dateofexpiry')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {formatDate((((this.props.value[0] as IdentificationDocument).dateOfExpiry) as MatchingDate).date)}
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as IdentificationDocument).description &&
                                    <div className="attribute body">
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('common_description')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as IdentificationDocument).description}
                                        </span>
                                    </div>
                                }
                            </React.Fragment>
                        }

                        {this.props.location &&
                            <React.Fragment>

                                {(this.props.value[0] as Location).street &&
                                    <div className="attribute body" >
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('location_street')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as Location).street}
                                            {(this.props.value[0] as Location).streetNumber && ',' + (this.props.value[0] as Location).streetNumber}
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as Location).streetName &&
                                    <div className="attribute body" >
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('location_street')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as Location).streetName}
                                            {(this.props.value[0] as Location).streetNumber && ',' + (this.props.value[0] as Location).streetNumber}
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as Location).city &&
                                    <div className="attribute body" >
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('location_city')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as Location).city}
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as Location).state &&
                                    <div className="attribute body" >
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('location_state')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as Location).state}
                                        </span>
                                    </div>
                                }

                                {(this.props.value[0] as Location).zipCode &&
                                    <div className="attribute body" >
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('location_zipcode')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {(this.props.value[0] as Location).zipCode}
                                        </span>
                                    </div>
                                }

                                {this.props.value[0]["country"] &&
                                    <div className="attribute body">
                                        <span className="attribute label withvalue" style={{ paddingLeft: '20px' }}>
                                            {formatMessage('location_country')}
                                        </span>
                                        <span className={this.props.noUpperCase?"attribute value notransform":"attribute value"}>
                                            {this.props.value[0]["country"] &&
                                                <img src={getFlagUrl(this.props.value[0]["country"])} width="14px" height="12px" />
                                            }
                                            {this.props.value[0]["country"] &&
                                                ' ' + getCountryDescription(this.props.value[0]["country"], this.props.countries)
                                            }
                                        </span>
                                    </div>
                                }

                            </React.Fragment>
                        }
                    </React.Fragment>

                );
            }
        } else {
            return (
                <React.Fragment>
                    {addItems}
                </React.Fragment>
            );
        }
    }

    private getStrValueFromObject = (elem: any): string => {
        let strValue: string = '';

        if (this.props.identificationDocument || this.props.location) {
            return '';
        }

        if (elem["date"]) {
            strValue = formatDate((elem as NormDate).date);
        } else if (elem["type"] && elem["value"]) {
            strValue = elem["type"] + ': ' + elem["value"];
        } else if (elem["fullName"] || elem["surname"] || elem["givenName"]) {
            strValue = getPersonNameFromName(elem);
        } else if (elem["number"]) {
            strValue = elem["number"];
        } else if (elem["keyword"]) {
            strValue = elem["keyword"];
        }

        if (typeof elem === 'string' || typeof elem === 'number') {
            return (elem as string);
        }

        return strValue;
    }

    private openAttribute = () => {
        this.setState({
            open: !this.state.open
        });
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    countries: state.countryStore.fullListOfCountries
})

export const MultipleAttributeDisplay = connect(mapStateToProps, null)(MultipleAttributeDisplayClass);