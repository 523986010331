import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { formatMessage } from "../../common/translate/Translate";
import { IRootState } from "../../store";
import { User } from "../../model/user/User";
import { Group } from "../../model/user/Group";
import Select, { ActionMeta, OptionsType } from 'react-select';

interface IntProps {
    fullListOfUsers?:User[],
    fullListOfGroups?:Group[],
    showGroup?:boolean,
    showUser?:boolean,
    visible?:boolean,
    x?:number,
    y?:number,
    setComment?:(comment:string) => void,
    setAssignToUsers?:(assignToUser : number) => void,
    setAssignToGroups?:(assignToGroup : number) => void,
    cancelPopup?:() => void,
    savePopup?:() => void
}

interface IntState {
    assignToGroup : number,
    assignToUser : number
}

class AlertsWorkflowCommentPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);
        
        this.state = {
            assignToGroup : -1,
            assignToUser : -1
        }
    }

    public render() {  
        let optionGroup:{value: string;label: string;}[] = [];        
        if (this.props.fullListOfGroups) {
            for (var group of this.props.fullListOfGroups) {
                optionGroup.push(
                    {
                        value : String(group.id),
                        label : group.name
                    }
                )
            }
        }

        let optionUsers:{value: string;label: string;}[] = [];        
        if (this.props.fullListOfUsers) {
            for (var user of this.props.fullListOfUsers) {
                optionUsers.push(
                    {
                        value : String(user.id),
                        label : user.userName
                    }
                )
            }
        }

        return (
                <React.Fragment>    
                    <div className={this.props.visible===true?"d-modal": "c-popover c-lc-popover js-lc-context"} style={{overflow:'visible'}}>
                        <div style={{padding:'10px', overflow:'visible'}}>
                            <label className="o-label">{formatMessage('comment_text')}</label>
                            <textarea
                                className="o-input"                               
                                name="keyword"
                                placeholder={formatMessage('comment_text')}    
                                onChange={this.changeComment}                          
                                style={{marginTop: '2px'}}              
                            /> 

                            {this.props.showGroup &&
                                <React.Fragment>
                                    <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_groups')}</label>
                                    <Select name="assignToGroups"                                                                                                                     
                                                    className="basic-multi-select"                        
                                                    options={optionGroup}
                                                    classNamePrefix="select"
                                                    onChange={this.changeGroupsSelect}
                                            />
                                </React.Fragment>
                            }

                            {this.props.showUser &&
                                    <React.Fragment>
                                        <label className="o-label" style={{marginBottom: '5px'}} >{formatMessage('common_users')}</label>
                                        <Select name="assignToUsers"                                                                                                                     
                                                        className="basic-multi-select"                        
                                                        options={optionUsers}
                                                        classNamePrefix="select"
                                                        onChange={this.changeUsersSelect}
                                                />
                                    </React.Fragment>
                            }
                            <div style={{display : 'flex', paddingTop:'10px'}}>
                                    <button className="m-button m-button--small-margin-bottom" onClick={this.props.cancelPopup} style={{margin: '5px'}}>
                                        {formatMessage('button_cancel')}
                                    </button> 
                                    <button className="m-button m-button--small-margin-bottom" onClick={this.props.savePopup} style={{margin: '5px'}}>
                                        {formatMessage('button_save')}
                                    </button> 
                            </div>
                        </div>
                    </div>                
                </React.Fragment>
        );
    }

    private changeComment = (event: { target: { value: any; }; }):void => {
        if (this.props.setComment) {
            this.props.setComment(event.target.value);
        }
    }

    private changeGroupsSelect = (value: {value: string;label: string;} | null, actionMeta: ActionMeta<{value: string;label: string;}>) : void => { 
        
        let grp:number = -1;

        if (value && !Number.isNaN(Number(value?.value))) {
            grp = Number(value.value);
        }

        if (this.props.setAssignToGroups) {
            this.props.setAssignToGroups(grp)
        }

        this.setState({                        
            ...this.state,  
            assignToGroup : grp              
        });

    }

    private changeUsersSelect = (value: {value: string;label: string;} | null, actionMeta: ActionMeta<{value: string;label: string;}>) : void => {       
        let user:number = -1;
        
        if (value && !Number.isNaN(Number(value?.value))) {
            user = Number(value.value);
        }

        if (this.props.setAssignToUsers) {
            this.props.setAssignToUsers(user)
        }

        this.setState({                        
            ...this.state,  
            assignToUser : user
        });
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    fullListOfUsers : state.userStore.fullListOfUsers,
    fullListOfGroups : state.groupStore.fullListOfGroups
})

export const AlertsWorkflowCommentPopup = connect(mapStateToProps, null)(AlertsWorkflowCommentPopupClass);