import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { AdminContextMenu } from "./AdminContextMenu";
import { MatchingRule } from "../../model/screening/MatchingRule";
import { deleteMatchingRules, getAllMatchingRules } from "../../store/screening/action";
import { MatchingConfigurationDetailsPopup } from "./MatchingConfigurationDetailsPopup";

interface IntProps {
    isLoading?:Boolean,
    listOfMatchingRules?:MatchingRule[]    
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,    

    matchingRulegDetailPopupVisible:boolean,
    matchingRuleCreate:boolean
}

class MatchingRuleConfigurationClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            paginationRequest : new PaginationRequest(),
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false,
            matchingRulegDetailPopupVisible:false,
            matchingRuleCreate:false
        }

        getAllMatchingRules();
    }

    public render() {
        
        return (
            <React.Fragment>                 
                <AdminContextMenu visible={this.state.contextMenuVisible} x={this.state.contextMenuX} y={this.state.contextMenuY}
                                    deleteAction={this.deleteMatchingRule}
                                    editAction={this.displayMatchingRuleDetails}
                />

                <MatchingConfigurationDetailsPopup visible={this.state.matchingRulegDetailPopupVisible} 
                                    closePopup={this.closeMatchingRuleDetails}
                                    matchingRule={this.getCurrentMatchingRuleMapping(this.state.selectedRow)}
                                    create={this.state.matchingRuleCreate}
                                    />

                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu}>  
                    <button className="m-button m-button--small-margin-bottom"  onClick={this.createMatchingRule} style={{marginTop: '5px', width: '10rem'}}>
                         {formatMessage('button_create')}
                    </button>                    
                    <div className="grids-display">
                        <table className="c-table js-table">
                            <thead className={`c-table__head`}>
                                <tr>
                                    <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="name" display="common_name"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="description" display="common_description"/>

                                    <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedBy" display="common_updatedBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedOn" display="common_updatedOn"/>                                                              
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.listOfMatchingRules &&  this.props.listOfMatchingRules.map((rowElem, j) => {                                   
                                        return (
                                                <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} 
                                                    onClick={this.selectRow}
                                                    onDoubleClick={this.displayMatchingRuleDetails}>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.dataId}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.sourceDataSet?rowElem.sourceDataSet.label:''}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updateDate}</td>
                                                </tr>
                                            );
                                    }                          
                                )}
                            </tbody>                            
                        </table> 
                    </div>                     
                </div>  
            </React.Fragment>
        );
    }

    private deleteMatchingRule = () => {
        deleteMatchingRules(String(this.state.selectedRow));
        this.setState({
            ...this.state,
            contextMenuVisible: false
        })
    }

    private displayMatchingRuleDetails = () => {
        this.setState({
            ...this.state,
            matchingRulegDetailPopupVisible : true,
            matchingRuleCreate:false,
            contextMenuVisible: false
        })
    }

    private closeMatchingRuleDetails = () => {
        this.setState({
            ...this.state,
            matchingRulegDetailPopupVisible : false,
            matchingRuleCreate:false,
            contextMenuVisible: false

        })
    }

    private createMatchingRule = () => {
        this.setState({
            ...this.state,
            selectedRow:-1,
            matchingRulegDetailPopupVisible : true,
            matchingRuleCreate:true
        })
    }

    private getCurrentMatchingRuleMapping = (riskId: number) : MatchingRule => {
        if (this.props.listOfMatchingRules) {
            for (var matchRule of this.props.listOfMatchingRules) {
                if (matchRule.id===riskId) {
                    return matchRule;
                }
            }
        }        
        return new MatchingRule();
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    listOfMatchingRules : state.matchingRuleStore.listOfMatchingRules
})

export const MatchingRuleConfiguration = connect(mapStateToProps, null)(MatchingRuleConfigurationClass);