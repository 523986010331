import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { DatasetConstant } from "../../common/constant/DatasetConstant";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { DataSets } from "../../model/dataset/DataSets";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { Constants } from "./constant";

export const getResultOfDataSetSearch = (request:PaginationRequest, type:number) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    let inType:string;

    if (DatasetConstant.ALERTS===type) {
        inType = Constants.LISTOFALERTS;
    } else  if (DatasetConstant.ACCOUNT===type) {
        inType = Constants.LISTOFACCOUNTS;
    } else {
        inType = Constants.LISTOFPERSONS;
    }

    Axios.post(URLConstants.datasetSearch, JSON.stringify(request),axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();     
        console.log(response.data);
        return store.dispatch({
                payload: response.data,
                type: inType
            }); 
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        manageAxiosError(error);        
    })
}

export const getResultOfDataSetSearchCount = (request:PaginationRequest, type:number) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    let inType:string;
    if (DatasetConstant.ALERTS===type) {
        inType = Constants.LISTOFALERTSCOUNT;
    } else  if (DatasetConstant.ACCOUNT===type) {
        inType = Constants.LISTOFACCOUNTSCOUNT;
    } else {
        inType = Constants.LISTOFPERSONSCOUNT;
    }

    Axios.post(URLConstants.datasetSearchCount, JSON.stringify(request),axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();     

        return store.dispatch({
                payload: response.data,
                type: Constants.LISTOFPERSONSCOUNT
            }); 
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        manageAxiosError(error);        
    })
}

export const cleanLatestSearches = () => {   
    return store.dispatch({
              payload: [],
              type: Constants.CLEANSEARCHES
    }); 
    
}

export const getResultOfDataSetSearchAsCSV = (request:PaginationRequest) => {
    displayLoadingStatus();
        
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Authorization': '' + localStorage.getItem('jwt'),             
            'Content-Type': 'application/json',
        },
        responseType : "blob"
    }

    Axios.post(URLConstants.datasetExport, JSON.stringify(request), axioConf).then((response: AxiosResponse<any>) => {        
        stopLoadingStatus();        
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'person.zip'); //any other extension
        document.body.appendChild(link);
        link.click();  
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}



// Screena Direct Search
export const searchScreenaDataSets = (request:Request, screenakey : string) => {
    displayLoadingStatus();

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaSearchInDatasets, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.SCREENA_SEARCH_RESULTS
        });        
    })
    .catch((error: AxiosError<String>) => {
        stopLoadingStatus();
        manageAxiosError(error);        
    })
}

export const getScreenaDataSetsRecord = (request:Request, screenakey : string, recordsId?:string) => {

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaBrowseInDatasets, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {  
        return store.dispatch({
            payload: response.data,
            displayRecordsId : recordsId,
            type: Constants.SCREENA_DATASETS_RECORD
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
    })
}

export const getScreenaDataSetsRecordCount = (request:Request, screenakey : string, recordsId?:string) => {

    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json', 
            'X-Screena-API-Key': screenakey
        }
    }

    Axios.post(URLConstants.screenaAmountOfRecordsBrowseInDatasets, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {  
        return store.dispatch({
            payload: response.data,
            displayRecordsId : recordsId,
            type: Constants.SCREENA_DATASETS_RECORD_COUNT
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
    })
}


export const getResultOfScreenaDataSetSearchAsCSV = (dataset:string, screenakey : string) => {
    displayLoadingStatus();
        
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
                'Content-Type': 'application/json', 
                'X-Screena-API-Key': screenakey
        },
        responseType : "blob"
    }

    let request:Request = new Request();
    request.datasets = [] ;
    let datasetObj:DataSets = new DataSets();
    datasetObj.label = dataset;
    request.datasets.push(datasetObj);

    Axios.post(URLConstants.screenaExportDatasets, JSON.stringify(request), axioConf).then((response: AxiosResponse<any>) => {        
        stopLoadingStatus();        
        const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', 'watchlist.zip'); //any other extension
        document.body.appendChild(link);
        link.click();  
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}