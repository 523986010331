import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import { IRootState } from "../../store";
import { formatMessage } from "../../common/translate/Translate";
import { IconsEnum } from "../../common/constant/IconsEnum";
import { Request } from "../../model/common/Request";
import { Job } from "../../model/job/Job";
import { createJob, updateJob } from "../../store/scheduler/action";
import Select, { ActionMeta, OptionsType } from 'react-select';
import { AdminConstant } from "../../common/constant/AdminConstant";
import { JobParam } from "../../model/job/JobParam";
import { PaginationRequest } from "../../model/common/PaginationRequest";

interface IntProps {
    visible?:boolean,
    x?:number,
    y?:number,
    job?:Job,
    closePopup?:() => void,
    create?:boolean,
    paginationRequest?:PaginationRequest
}

interface IntState {
    schedulerName:string,  
    schedulerDescription:string,
    schedulerCronexpression:string,
    schedulerType:string,
    schedulerActive:boolean,
    schedulerParam:JobParam[],
    errors:{
        schedulerName:string,
        schedulerCronexpression:string
    }
}

class SchedulerDetailsPopupClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props)  
        
        this.state = {
            schedulerName:'',
            schedulerDescription:'',
            schedulerCronexpression:'',
            schedulerType:'',
            schedulerActive:false,
            schedulerParam:[],
            errors:{
                schedulerName:'',
                schedulerCronexpression:''
            }
        }
    }

    public componentWillReceiveProps(nextProps: IntProps) {
        if (nextProps.create) {
            this.setState({
                schedulerName : '',
                schedulerDescription : '',
                schedulerCronexpression:'',
                schedulerType:'',
                schedulerActive:false,
                schedulerParam:[],
                errors:{
                    schedulerName:'',
                    schedulerCronexpression:''
                }
            })
        } else if (nextProps.job) {             
          this.setState({
                schedulerName : nextProps.job.name,
                schedulerDescription : nextProps.job.description,
                schedulerCronexpression : nextProps.job.cronExpression,
                schedulerActive:nextProps.job.active,
                schedulerType:nextProps.job.jobType,
                schedulerParam:nextProps.job.jobParams,
                errors:{
                    schedulerName:'',
                    schedulerCronexpression:''
                }
          })
        }
           
      }

    public render() {    

        let optionGroup:{value: string;label: string;}[] = [];
        optionGroup.push({
            value : AdminConstant.MATCHING,
            label : formatMessage('job_type_matching')
        });
        optionGroup.push({
            value : 'Import',
            label : formatMessage('job_type_import')
        });
        optionGroup.push({
            value : 'Export',
            label : formatMessage('job_type_export')
        });

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "d-modal" : "c-popover c-lc-popover js-lc-context"} style={{ width: "50rem", height: "26rem" }}>
                    <div className="d-modal__view js-modal-view">
                        <div className="d-modal__head">
                            <legend className="d-modal__title">
                                {formatMessage('alert_details_information_title')}
                            </legend>
                            <div>                               
                                <button className="m-button m-button--close d-modal__close" type="button" onClick={this.props.closePopup}>
                                    <svg className="o-icon o-icon--close o-icon--prepended">
                                        <title>{IconsEnum.CLOSE.title}</title>
                                        <use href={IconsEnum.CLOSE.url} />
                                    </svg>
                                </button>
                            </div>
                        </div>

                        <div className="d-modal__view js-modal-view" style={{ margin: "1rem" }}>
                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '120px' }}>
                                    {formatMessage('common_name')}
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["schedulerName"]? "o-input error" : "o-input"}
                                    name="jobname"
                                    placeholder={formatMessage('common_name')}
                                    value={this.state.schedulerName}
                                    onChange={this.changeJobName}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '120px' }}>
                                    {formatMessage('common_description')}
                                </label>
                                <input
                                    className="o-input"
                                    name="jobdescription"
                                    placeholder={formatMessage('common_description')}
                                    value={this.state.schedulerDescription}
                                    onChange={this.changeJobDescription}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '120px' }}>
                                    {formatMessage('jobs_cronexpression')}
                                </label>
                                <input
                                    className={this.state.errors && this.state.errors["schedulerCronexpression"]? "o-input error" : "o-input"}
                                    name="jobcronexpression"
                                    placeholder={formatMessage('jobs_cronexpression')}
                                    value={this.state.schedulerCronexpression}
                                    onChange={this.changeJobCronexpression}
                                    style={{ marginTop: '2px' }}
                                />
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '120px' }}>
                                    {formatMessage('jobs_active')}                                                                   
                                </label>
                                <input                                        
                                    type="checkbox"
                                    name="jobactive"
                                    checked={this.state.schedulerActive}   
                                    onChange={this.changeJobActive}                                 
                                    style={{ marginTop: '2px' }}
                                />
                            </div>

                            <div style={{ display: 'flex' }} >
                                <label className="o-label" style={{ width: '120px' }}>
                                    {formatMessage('common_type')}
                                </label>
                                <Select options={optionGroup}
                                    name="jobType"
                                    className="basic-multi-select"
                                    classNamePrefix="select"
                                    onChange={this.changeJobType}
                                    style={{ width: '120px' }}
                                />
                            </div>

                            {this.state.schedulerType===AdminConstant.MATCHING &&
                                <React.Fragment>
                                    <div style={{ display: 'flex' }} >
                                        <label className="o-label" style={{ width: '120px' }}>
                                            {formatMessage('jobs_source')}                                                                   
                                        </label>
                                        <input
                                            className="o-input"
                                            name="jobsource"
                                            placeholder={formatMessage('jobs_source')}
                                            value={this.getValueFromParam(AdminConstant.PARAM_MATCHING_SOURCE)}
                                            onChange={this.addSourceDatasetMatchingJob}
                                            style={{ marginTop: '2px' }}
                                        />                                       
                                    </div>   
                                </React.Fragment>
                            }

                        </div>

                        <div style={{display : 'flex'}}>
                                <button className="m-button m-button--small-margin-bottom" onClick={this.props.closePopup} style={{margin: '5px'}}>
                                    {formatMessage('button_cancel')}
                                </button> 
                                
                                <button className="m-button m-button--small-margin-bottom" onClick={this.saveJob} style={{margin: '5px'}}>
                                    {formatMessage('button_save')}
                                </button> 
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private addSourceDatasetMatchingJob = (event: { target: { value: any; }; }):void => {
         let jobParams:JobParam[] = [];

        if (this.state.schedulerParam.length>0) {
            for (var jobPrm of this.state.schedulerParam) {
                if (jobPrm.key!==AdminConstant.PARAM_MATCHING_SOURCE) {
                    jobParams.push(jobPrm);
                }
                
            }
        }

        let jobParam:JobParam = new JobParam();
        jobParam.key = AdminConstant.PARAM_MATCHING_SOURCE;
        jobParam.value = event.target.value;
        jobParams.push(jobParam);

        this.setState({
            ...this.state,
            schedulerParam: jobParams,
        });
        
    }

    private getValueFromParam = (key:string)  => {
        if (this.state.schedulerParam.length>0) {
            for (var jobPrm of this.state.schedulerParam) {
                if (jobPrm.key===key) {
                    return jobPrm.value;
                }
            }
        }
    }

    private changeJobType  = (value: { value: string; label: string; } | null): void => {
       
        if (value && value?.value) {
            let typeValue:string = String(value?.value);

            this.setState({
                ...this.state,
                schedulerType: typeValue,
            });
        }
    }

    private changeJobActive = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            schedulerActive: !this.state.schedulerActive
        });
    }

    private changeJobName = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            schedulerName: event.target.value
        });
    }

    private changeJobDescription = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            schedulerDescription: event.target.value
        });
    }

    private changeJobCronexpression = (event: { target: { value: any; }; }):void => {
        this.setState({                        
            ...this.state,      
            schedulerCronexpression: event.target.value
        });
    }

    private saveJob = () : void => {

        let noError:boolean = true;

        let errors = {
            schedulerName  : '',
            schedulerCronexpression  : ''
        };

        if (!this.state.schedulerName || this.state.schedulerName==='') {
            errors.schedulerName = 'No scheduler name';    
            noError = false;       
        }

        if (!this.state.schedulerCronexpression || this.state.schedulerCronexpression==='') {
            errors.schedulerCronexpression = 'No scheduler expression';  
            noError = false;          
        }

        if (!noError) {
            this.setState({
                ...this.state, 
                errors:errors       
           })
    
           return;
        }

        let request:Request = new Request();
        if (this.props.create) {
            let job:Job = new Job();
            job.name = this.state.schedulerName;
            job.description = this.state.schedulerDescription;
            job.cronExpression = this.state.schedulerCronexpression; 
            job.active = this.state.schedulerActive;  
            job.jobType = this.state.schedulerType;
            job.jobParams = this.state.schedulerParam;
            request.job = job;
            createJob(request, this.props.paginationRequest);
        } else if (this.props.job) {
            let job:Job = this.props.job;
            job.name = this.state.schedulerName;
            job.description = this.state.schedulerDescription;
            job.cronExpression = this.state.schedulerCronexpression;
            job.active = this.state.schedulerActive;
            job.jobType = this.state.schedulerType;   
            job.jobParams = this.state.schedulerParam;   
            request.job = job;
            updateJob(request, this.props.paginationRequest);
        }

        if (this.props.closePopup) {
            this.props.closePopup();
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
})

export const SchedulerDetailsPopup = connect(mapStateToProps, null)(SchedulerDetailsPopupClass);