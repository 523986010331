import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { Pagination } from "../../components/pagination/Pagination";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { formatMessage } from "../../common/translate/Translate";
import { deleteJob, getPaginatedJobs, getTotalAmountOfJobs, startJob } from "../../store/scheduler/action";
import { Job } from "../../model/job/Job";
import { SchedulerDetailsPopup } from "./SchedulerDetailPopup";
import { AdminContextMenu } from "./AdminContextMenu";

interface IntProps {
    isLoading?:Boolean,
    jobs?:Job[],
    totalAmountOfJobs?:number
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,

    schedulerDetailsVisible: boolean,
    createScheduler: boolean
}

class SchedulerClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            paginationRequest : new PaginationRequest(),
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false ,
            schedulerDetailsVisible: false ,
            createScheduler : false   
        }

        getPaginatedJobs(this.state.paginationRequest);
        getTotalAmountOfJobs(this.state.paginationRequest);
    }

    private maxPage:number = 0;

    public render() {
        
        if (this.props.totalAmountOfJobs) {
            this.maxPage = Math.ceil(this.props.totalAmountOfJobs / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        }
        
        console.log(this.props.jobs);

        return (
            <React.Fragment>   
                <SchedulerDetailsPopup visible={this.state.schedulerDetailsVisible} 
                                    closePopup={this.closeSchedulerDetails}
                                    job={this.getCurrentJob(this.state.selectedRow)}
                                    create={this.state.createScheduler}
                                    paginationRequest={this.state.paginationRequest}/>      
                <AdminContextMenu visible={this.state.contextMenuVisible} x={this.state.contextMenuX} y={this.state.contextMenuY}
                                    editAction={this.displaySchedulerDetails}
                                    deleteAction={this.deleteJob}
                                    startAction={this.startJob}
                />      
                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu}>  
                    <div style={{ marginLeft: '20px', marginRight: '10px', width: '98%', display: 'flex' }}>
                        <label className="o-label title" style={{ width: '98%'}}> {formatMessage('admin_main_scheduler')}</label>
                        <button className="m-button m-button--small-margin-bottom"  onClick={this.createScheduler} style={{marginTop: '5px', width: '10rem'}}>
                            {formatMessage('button_create')}
                        </button>
                    </div>
                    <div className="grids-display">
                        <table className="c-table js-table">
                            <thead className={`c-table__head`}>
                                <tr>
                                    <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="name" display="common_name"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="description" display="common_description"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="active" display="jobs_active"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="lastexecutiontime" display="job_lastexecutiontime"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="nextexecutiontime" display="job_nextexecutiontime"/>

                                    <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedBy" display="common_updatedBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedOn" display="common_updatedOn"/>                                                                  
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.jobs && this.props.jobs.map((rowElem, j) => {                                   
                                        return (
                                                <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} 
                                                    onClick={this.selectRow}
                                                    onDoubleClick={this.displaySchedulerDetails}>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.name}</td>                                                 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.cronExpression}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.active}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.lastExecutionTime}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.nextExecutionTime}</td> 

                                                    <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedDate}</td> 
                                                </tr>
                                            );
                                    }                          
                                )}
                            </tbody>                            
                        </table> 
                    </div>
                    <div className="pagination-display">
                        {this.maxPage!==Number.NaN && this.maxPage>0 && 
                           <Pagination 
                                changeInput={this.changeInput} 
                                onChangeNext={this.onChangeNext} 
                                onChangePrevious={this.onChangePrevious} 
                                maxPage={this.maxPage} 
                                pageNumber={this.state.paginationRequest.pageNumber}/>      
                        }
                    </div>  
                </div>  
            </React.Fragment>
        );
    }

    private getCurrentJob = (jobId: number) : Job => {
        if (this.props.jobs) {
            for (var job of this.props.jobs) {
                if (job.id===jobId) {
                    return job;
                }
            }
        }        
        return new Job();
    }

    private deleteJob = () => {
        deleteJob(String(this.state.selectedRow), this.state.paginationRequest);

        this.setState({
            ...this.state,
            contextMenuVisible: false
        })
    }

    private startJob = () => {
        startJob(String(this.state.selectedRow), this.state.paginationRequest);

        this.setState({
            ...this.state,
            contextMenuVisible: false
        })
    }

    private displaySchedulerDetails = () => {
        this.setState({
            ...this.state,
            schedulerDetailsVisible:true,
            createScheduler:false,
            contextMenuVisible: false
        })
    }

    private createScheduler = () => {
        this.setState({
            ...this.state,
            schedulerDetailsVisible:true,
            createScheduler:true,
            selectedRow:-1
        })
    }

    private closeSchedulerDetails = () => {
        this.setState({
            ...this.state,
            schedulerDetailsVisible:false,
            contextMenuVisible: false

        })
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                contextMenuVisible: false,
                paginationRequest:paginationRequest                
            })
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
        }        
    }

    private changeInput = (e: any) => {
        const value = e.target? e.target.value: e.value;
        if (value === '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
          return;
        }
        if (value < 1) {
          return;
        }       
    }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    jobs : state.jobStore.listOfJobs,
    totalAmountOfJobs : state.jobStore.amountOfJobs
})

export const Scheduler = connect(mapStateToProps, null)(SchedulerClass);