import React from "react";
import { connect } from "react-redux";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/tabs.css'
import '../../common/css/card.css'
import { IRootState } from "../../store";
import { Person } from "../../model/persons/Person";
import { getPersonRelated, getPersonScoreCard, getSinglePersons } from "../../store/customer/action";
import { CustomerAlerts } from "./CustomerAlerts";
import { CustomerScoreCard } from "./CustomerScoreCard";
import { CustomerProperties } from "./CustomerProperties";
import { CustomerLinks } from "./CustomerLinks";
import { CustomerTransactions } from "./CustomerTransactions";
import { CustomerAccounts } from "./CustomerAccounts";
import { getRelatedAccountsToCustomer } from "../../store/account/action";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { getCountTransactionsLinkedToPerson, getPaginatedTransactionsLinkedToPerson } from "../../store/transactions/action";
import { Alert } from "../../model/alerts/Alert";
import { getPaginatedAlert, getTotalAmountOfAlert } from "../../store/alerts/action";
import { Constants } from "../../store/alerts/constant";
import { HeaderConstant } from "../../common/constant/HeaderConstant";
import { formatMessage } from "../../common/translate/Translate";
import { CustomerCommentPopup } from "./CustomerCommentPopup";
import { CustomerFiles } from "./CustomerFiles";
import { CustomerRisks } from "./CustomerRisk";
import { capitalizeWords } from "../../common/helper/StringHelper";
import { getPersonNameFromName } from "../../common/helper/NameHelper";

interface IntProps {
    visible?: boolean,
    customerId?: number,
    customer?: Person,
    closePopup?: () => void
}

interface IntState {
    tabSelected: string
}

class CustomerTabClass extends React.Component<IntProps, IntState> {

    private componentVisible: boolean;

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);
        this.componentVisible = false;

        this.state = {
            tabSelected: HeaderConstant.RELATED_ALERTS
        }
    }

    public render() {
        if (this.props.visible && this.componentVisible !== this.props.visible) {
            getSinglePersons(String(this.props.customerId));
            getPersonScoreCard(String(this.props.customerId));
            getPersonRelated(String(this.props.customerId));
            getRelatedAccountsToCustomer(String(this.props.customerId));

            let paginationRequest: PaginationRequest = new PaginationRequest();
            paginationRequest.pageNumber = 0;
            paginationRequest.maxPerPage = 5;

            if (this.props.customerId) {
                let person: Person = new Person();
                person.id = this.props.customerId;
                paginationRequest.person = person;
                getPaginatedTransactionsLinkedToPerson(paginationRequest);
                getCountTransactionsLinkedToPerson(paginationRequest);

                let alert: Alert = new Alert();
                alert.source = new Person();
                alert.source.id = this.props.customerId;
                paginationRequest.alert = alert;
                getTotalAmountOfAlert(paginationRequest, Constants.PERSONTOTALAMOUNTOFALERTS);
                getPaginatedAlert(paginationRequest, Constants.PERSONPAGEDLISTS);
            }
            this.componentVisible = this.props.visible;
        }

        let header: Array<{ id: string, name: string, visibleTab: string, closeAble: boolean }> = this.getHeader();

        return (
            <React.Fragment>
                <div className="main-customer" style={{ overflow: 'visible', height: '96%', width: '100%' }}>
                    <div className={`entity-card js-horizontal-card`}>
                        <div className="header" style={{ width: '100%', height: '10%' }}>
                            <p className="entity-card__header" style={{ paddingLeft: '20px' }}>
                                {this.props.customer ? this.props.customer.names ? capitalizeWords(getPersonNameFromName(this.props.customer.names[0])) : '' : ''}
                            </p>
                            <p className="entity-card__label">
                                <span className="header label-entity-type">
                                    {this.props.customer ? this.props.customer.entityType : ''}
                                </span>
                                <span className="header label-dataset" style={{ marginLeft: '5px' }}>
                                    {this.props.customer ? this.props.customer.dataset ? this.props.customer.dataset.label : '' : ''}
                                </span>
                            </p>
                        </div>
                    </div>

                    <div className="wrap-column" style={{ width: '100%', height: '80%' }}>
                        <div className="wrap-row">
                            <CustomerProperties />
                            <CustomerScoreCard />
                        </div>

                        <div className="customer-tabs-largebox" >
                            <div className="customer-tab-largebox-head" >
                                <nav className="c-tabs__nav">
                                    <ul className="c-tabs__list">
                                        {header.map((head, index) => {
                                            return (
                                                <li className="c-tabs__title" key={index}>
                                                    <button className={`c-tabs__link js-tabs-link ${this.state.tabSelected === head.visibleTab ? 'is-active' : ''}`}
                                                        id={head.id}
                                                        name={head.id} onClick={this.changeVisibility}>
                                                        <span id={head.id}>{head.name}</span>
                                                    </button>
                                                </li>
                                            )
                                        })}
                                    </ul>
                                </nav>
                            </div>
                            <div className="customer-tab-largebox" style={{ display: 'block', textAlign: 'left' }}>
                                {this.state.tabSelected === HeaderConstant.RELATED_ALERTS &&
                                    <CustomerAlerts />
                                }

                                {this.state.tabSelected === HeaderConstant.RELATED_ACCOUNTS &&
                                    <CustomerAccounts />
                                }

                                {this.state.tabSelected === HeaderConstant.RELATED_TRXNS &&
                                    <CustomerTransactions />
                                }

                                {this.state.tabSelected === HeaderConstant.RELATED_PERSONS &&
                                    <CustomerLinks />
                                }

                                {this.state.tabSelected === HeaderConstant.RELATED_COMMENTS &&
                                    <CustomerCommentPopup />
                                }

                                {this.state.tabSelected === HeaderConstant.RELATED_FILES &&
                                    <CustomerFiles />
                                }

                                {this.state.tabSelected === HeaderConstant.RISK_DETAILS &&
                                    <CustomerRisks />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private getHeader = () => {
        const header: Array<{ id: string, name: string, visibleTab: string, closeAble: boolean }> = [];

        header.push({ id: HeaderConstant.RELATED_ALERTS, name: formatMessage('customer_tab_header_alerts'), visibleTab: HeaderConstant.RELATED_ALERTS, closeAble: false });
        header.push({ id: HeaderConstant.RELATED_ACCOUNTS, name: formatMessage('customer_tab_header_accounts'), visibleTab: HeaderConstant.RELATED_ACCOUNTS, closeAble: false });
        header.push({ id: HeaderConstant.RELATED_TRXNS, name: formatMessage('customer_tab_header_trxns'), visibleTab: HeaderConstant.RELATED_TRXNS, closeAble: false });
        header.push({ id: HeaderConstant.RELATED_PERSONS, name: formatMessage('customer_tab_header_persons'), visibleTab: HeaderConstant.RELATED_PERSONS, closeAble: false });
        header.push({ id: HeaderConstant.RELATED_COMMENTS, name: formatMessage('customer_tab_header_comments'), visibleTab: HeaderConstant.RELATED_COMMENTS, closeAble: false });
        header.push({ id: HeaderConstant.RELATED_FILES, name: formatMessage('customer_tab_header_files'), visibleTab: HeaderConstant.RELATED_FILES, closeAble: false });
        header.push({ id: HeaderConstant.RISK_DETAILS, name: formatMessage('customer_tab_header_risks'), visibleTab: HeaderConstant.RISK_DETAILS, closeAble: false });
        return header;
    }

    private changeVisibility = (event: any) => {
        this.setState({
            ...this.state,
            tabSelected: event.target.id
        })
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    customer: state.personStore.currentPerson
})

export const CustomerTab = connect(mapStateToProps, null)(CustomerTabClass);
