import React from "react";
import '../../common/css/popover.css'
import '../../common/css/button.css'
import '../../common/css/radar.css'
import { formatMessage } from "../../common/translate/Translate";
import { isUserHasPermission } from "../../common/helper/PermissionHelper";
import { IRootState } from "../../store";
import { connect } from "react-redux";
import { Permissions } from "../../common/constant/Permissions";
import { User } from "../../model/user/User";

interface IntProps {
    currentUser?: User,
    visible?: boolean,
    x?: number,
    y?: number,
    showChangePassword?: (e: any) => void,
    showUserPreferences?: (e: any) => void,
    logout?: (e: any) => void
}

interface IntState {

}

export class UserMenuClass extends React.Component<IntProps, IntState> {

    public render() {
        let topx: number = 400;
        let width: number = 250;

        if (this.props.x) {
            topx = this.props.x;

            if ((isUserHasPermission(this.props.currentUser, Permissions.ALERTS)
                || isUserHasPermission(this.props.currentUser, Permissions.RISKS)
                || isUserHasPermission(this.props.currentUser, Permissions.DATASETS))) {
                topx -= 305;
                width = 300;
            } else {
                topx -= 255;
                width = 250;
            }
        }

        let topy: number = 0;

        if (this.props.y) {
            topy = this.props.y + 5;
        }

        return (
            <React.Fragment>
                <div className={this.props.visible === true ? "c-popover-right-click c-lc-popover.is-visible js-lc-context" : "c-popover c-lc-popover js-lc-context"}
                    style={this.props.visible === true ? { position: 'absolute', left: topx + 'px', top: topy + 'px', width: width + 'px', minWidth:'unset' }:{}}>
                    <div className="c-popover__body" style={{ width: width + 'px' }}>
                        <ul className="d-list d-list--borders" style={{ width: width + 'px' }}>
                            <li className="d-list__item" style={{ width: width + 'px' }}>

                                <button className="m-button m-button--inline strong" type="button" onClick={this.props.showChangePassword}
                                    style={{ width: width + 'px' }}>
                                    {formatMessage('user_menu_show_change_password')}
                                </button>
                                {(isUserHasPermission(this.props.currentUser, Permissions.ALERTS)
                                    || isUserHasPermission(this.props.currentUser, Permissions.RISKS)
                                    || isUserHasPermission(this.props.currentUser, Permissions.DATASETS)) &&

                                    <button className="m-button m-button--inline strong" type="button" onClick={this.props.showUserPreferences}>
                                        {formatMessage('user_menu_show_user_preferences')}
                                    </button>
                                }
                                <button className="m-button m-button--inline strong" type="button" onClick={this.props.logout}
                                    style={{ width: width + 'px' }}>
                                    {formatMessage('user_menu_logout')}
                                </button>
                            </li>
                        </ul>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: IRootState) => ({
    currentUser: state.userStore.currentUser
})

export const UserMenu = connect(mapStateToProps, null)(UserMenuClass);