import * as React from 'react';
import { connect } from 'react-redux';
import { IRootState } from '../../store';
import '../../common/css/notification.css'
import { IconsEnum } from '../../common/constant/IconsEnum';

export interface IntBottomNotificationProps {
  message?: string,
  loadingCurrent?: string,
  loadingMax?: string,
  time?: string,
  variants?:string
  hide?: boolean
}

interface IntState {
  showMe: boolean
}

class BottomNotificationComponent extends React.Component<IntBottomNotificationProps, IntState> {
  constructor(props: IntBottomNotificationProps) {
    super(props);
    this.state = {
      showMe: props.message !== undefined
    }
  }

  public componentWillReceiveProps(nextProps: IntBottomNotificationProps) {
    console.log(this.props.time)
    if (this.props.time !== nextProps.time) {
      this.setState({
        showMe: true
      })
    }

    if (this.props.loadingCurrent !== nextProps.loadingCurrent) {
      this.setState({
        showMe: true
      })
    }
  }

  public render() {
    let classAttribute: string = 'c-notification-card is-open js-notification-card';
    let subAttribute: string = ''; 
    if (this.props.variants === 'Error') {
       classAttribute += ' u-attention-required';
    } else {
       classAttribute += ' c-notification-card--primary';
    }

    if (this.props.loadingCurrent && this.props.loadingMax) {
      subAttribute = (Number(this.props.loadingCurrent) / Number(this.props.loadingMax) * 100).toFixed(0) + '%';
    } else if (this.props.time) {
      subAttribute = this.props.time;
    } 

     if (this.state.showMe && !(this.props.variants === 'ANIMATED')) {
         setTimeout(this.hideMe, 4000);
     }

     console.log(this.state.showMe)
     if (this.state.showMe) {
       if (this.props.hide || !subAttribute) {           
           this.hideMe();
       }
       return (
         <div className="notification">
           <div className={classAttribute}>
              <button className='m-button m-button--transparent c-notification-card__btn' type='button' onClick={this.hideMe}>
                <svg className="o-icon o-icon--cross"><title>{IconsEnum.CLOSE.title}</title>
                  <use xlinkHref={IconsEnum.CLOSE.url}/>
                </svg>
              </button>
             <span className='c-notification-card__label'>{this.props.message}</span>            
              <span className='c-notification-card__time'>
                  {subAttribute}
              </span>
             
            </div>
          </div>
       );
     } else {
       return null;
     }

  }

  private hideMe = () => {
    this.setState({ showMe: false })
  }
}

const mapStateToProps = (state: IRootState) => ({
  loadingCurrent: state.notificationDisplayStore.loadingCurrent,
  loadingMax: state.notificationDisplayStore.loadingMax,
  message: state.notificationDisplayStore.message,
  time: state.notificationDisplayStore.time,
  variants: state.notificationDisplayStore.variants,
  hide: state.notificationDisplayStore.hide,
});

export default connect( mapStateToProps,   null )(BottomNotificationComponent);
