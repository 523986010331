import React from "react";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { IconsEnum } from "../../common/constant/IconsEnum";
import { formatMessage } from "../../common/translate/Translate";

interface IntProps {    
    sortHeader: (e:any) => void,
    display:string,
    id:string
}

interface IntState {
    
}

export class HeaderColumn extends React.Component<IntProps, IntState> {

    public render() {
        return (
            <th key={this.props.id} className={`c-table__head-data c-table__head-data--sortable js-sortable-head`} >                               
                <span className="c-table__sort">
                    <svg id={this.props.id}  width="14px" height="12px" className="o-icon o-icon--dropdown c-table__sort-icon" onClick={this.props.sortHeader} style={{paddingRight:'5px', paddingTop:'3px'}}>
                        <title></title>
                        <use href={IconsEnum.DROPDOWN.url}/>
                    </svg>  
                    {formatMessage(this.props.display).toUpperCase()}
                </span>
            </th>
         );
    }
}