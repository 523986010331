import React, { RefObject } from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { Alert } from "../../model/alerts/Alert";
import { AlertFieldsConstant } from "../../common/constant/AlertFieldsConstant";
import { Pagination } from "../../components/pagination/Pagination";
import { DatasetsSearch } from "./DatasetsSearch";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { Person } from "../../model/persons/Person";
import { DataSetContextMenu } from "./DataSetContextMenu";
import { DatasetConstant } from "../../common/constant/DatasetConstant";
import { WatchListPerson } from "../../model/persons/WatchListPerson";
import { cleanImportAudit, importFile } from "../../store/dataset/action";
import { Account } from "../../model/account/Account";
import { getPersonName } from "../../common/helper/NameHelper";
import { DataSetImportStatus } from "./DataSetImportStatus";
import { PersonFieldsConstant } from "../../common/constant/PersonFieldsConstant";

interface IntProps {
    isLoading?: Boolean,
    listOfPersons?: Person[],
    amountOfPersons?: number,

    listOfAccounts?: Account[],
    amountOfAccounts?: number,

    addPersonTab?: (customerId: number) => void,
    addWatchlistPersonTab?: (watchlistId: number, watchListPerson: Person) => void,

    listOfAlerts?: Alert[],
    amountOfAlerts?: number,
}

interface IntState {
    paginationRequest: PaginationRequest,
    selectedRow: number,
    contextMenuX: number,
    contextMenuY: number,
    contextMenuId: string,
    contextMenuVisible: boolean,

    importStatusVisible: boolean,
}

class DatasetsClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        let paginationRequest: PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;

        this.inputOpenFileRef = React.createRef()

        this.state = {
            paginationRequest: paginationRequest,
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false,
            contextMenuId: '',

            importStatusVisible: false,
        }
    }

    private maxPage: number = 0;
    private selectedDataset: number = Number.NaN;
    private selectedDatasetType: string = '';

    componentDidMount () {
        this.selectedDataset = Number.NaN;
    }

    private readonly inputOpenFileRef: RefObject<HTMLInputElement>

    public render() {

        if (this.props.amountOfPersons) {
            this.maxPage = Math.ceil(this.props.amountOfPersons / this.state.paginationRequest.maxPerPage);
        } else {
            this.maxPage = 0;
        }

        return (
            <React.Fragment>
                <DataSetContextMenu visible={this.state.contextMenuVisible}
                    x={this.state.contextMenuX}
                    y={this.state.contextMenuY}
                    showCustomerDetails={this.showCustomerDetails}
                    type={this.selectedDatasetType}
                />
                
                <DataSetImportStatus visible={this.state.importStatusVisible}
                                    close={this.closeImportStatus}/>

                <DatasetsSearch selectDataset={this.setSelectedDataSet}
                    pageNumber={this.state.paginationRequest.pageNumber}
                    sortField={this.state.paginationRequest.sortField}
                    sortOrder={this.state.paginationRequest.sortOrder}
                    setPageNumber={this.changeInput}
                />
                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu}>
                    <div className="grids-display">

                        {this.selectedDataset > Number.NEGATIVE_INFINITY &&
                            <React.Fragment>
                                <input ref={this.inputOpenFileRef} type="file" style={{ display: "none" }} onChange={this.handleFileInput} />

                                <button className="m-button m-button--small-margin-bottom" onClick={this.importList} style={{ marginTop: '5px', width: '10rem' }}>
                                    {formatMessage('button_import')}
                                </button>
                            </React.Fragment>
                        }
                        {this.props.listOfAlerts && this.props.listOfAlerts.length > 0 &&
                            <table className="c-table js-table">
                                <thead className={`c-table__head`}>
                                    <tr>
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertName" display="alert_alertName" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertId" display="alert_alertId" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertScore" display="alert_alertScore" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertPriority" display="alert_alertPriority" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertMessageType" display="alert_alertMessageType" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertTrxnReference" display="alert_alertTrxnReference" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertTrxnAmount" display="alert_alertTrxnAmount" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertDateTime" display="alert_alertDateTime" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertSourceName" display="alert_alertSourceName" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertType" display="alert_alertType" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="alertStatus" display="alert_alertStatus" />
                                    </tr>
                                </thead>
                                <tbody className="c-table__body">
                                    {this.props.listOfAlerts && this.props.listOfAlerts.map((rowElem, j) => {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow === rowElem.id ? " is-selected" : ""}`} onClick={this.selectRow}>
                                                <td className="c-table__data c-table__smallfont">{rowElem.name}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.score}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.priority}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.messageType}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.transactionReference}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.transactionAmount}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.alertDate}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.source && rowElem.source.names && rowElem.source.names.length > 0 && rowElem.source.names[0].fullName}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.type}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.status ? rowElem.status.code : ''}</td>
                                            </tr>
                                        );
                                    }
                                    )}
                                </tbody>
                            </table>
                        }

                        {this.props.listOfPersons && this.props.listOfPersons.length > 0 && this.selectedDatasetType !== DatasetConstant.WATCHLIST &&
                            <table className="c-table js-table">
                                <thead className={`c-table__head`}>
                                    <tr>
                                        <HeaderColumn sortHeader={this.sortHeader} id="personId" display="common_id" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personDataId" display="common_dataid" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personName" display="person_details_fullName" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="entityType" display="person_details_entityType" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personScore" display="person_risk_score" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personCreatedBy" display="common_createdBy" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personCreatedOn" display="common_createdOn" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personUpdatedBy" display="common_updatedBy" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personUpdatedOn" display="common_updatedOn" />
                                    </tr>
                                </thead>
                                <tbody className="c-table__body">
                                    {this.props.listOfPersons && this.props.listOfPersons.map((rowElem, j) => {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow === rowElem.id ? " is-selected" : ""}`}
                                                onClick={this.selectRow}>
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.dataID}</td>
                                                <td className="c-table__data c-table__smallfont">{getPersonName(rowElem)}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.entityType}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.score}</td>                                                
                                                <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td>                                                
                                                <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.updatedDate}</td>
                                            </tr>
                                        );
                                    }
                                    )}
                                </tbody>
                            </table>
                        }

                        {this.props.listOfPersons && this.props.listOfPersons.length > 0 && this.selectedDatasetType === DatasetConstant.WATCHLIST &&
                            <table className="c-table js-table">
                                <thead className={`c-table__head`}>
                                    <tr>
                                        <HeaderColumn sortHeader={this.sortHeader} id="personId" display="common_id" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personDataId" display="common_dataid" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personName" display="person_details_fullName" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="entityType" display="person_details_entityType" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="keywords" display="person_details_entityType" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personCreatedBy" display="common_createdBy" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personCreatedOn" display="common_createdOn" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personUpdatedBy" display="common_updatedBy" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personUpdatedOn" display="common_updatedOn" />
                                    </tr>
                                </thead>
                                <tbody className="c-table__body">
                                    {this.props.listOfPersons && this.props.listOfPersons.map((rowElem, j) => {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow === rowElem.id ? "is-selected" : ""}`}
                                                onClick={this.selectRow}>
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.dataID}</td>
                                                <td className="c-table__data c-table__smallfont">{getPersonName(rowElem)}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.entityType}</td>
                                                <td className="c-table__data c-table__smallfont">{this.getMultipleStringDisplay(rowElem.keywords)}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.updatedDate}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>
                                            </tr>
                                        );
                                    }
                                    )}
                                </tbody>
                            </table>
                        }

                        {this.props.listOfAccounts && this.props.listOfAccounts.length > 0 &&
                            <table className="c-table js-table">
                                <thead className={`c-table__head`}>
                                    <tr>
                                        <HeaderColumn sortHeader={this.sortHeader} id="personId" display="common_id" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personDataId" display="common_dataid" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personCreatedBy" display="common_createdBy" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personCreatedOn" display="common_createdOn" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personUpdatedBy" display="common_updatedBy" />
                                        <HeaderColumn sortHeader={this.sortHeader} id="personUpdatedOn" display="common_updatedOn" />
                                    </tr>
                                </thead>
                                <tbody className="c-table__body">
                                    {this.props.listOfAccounts && this.props.listOfAccounts.map((rowElem, j) => {
                                        return (
                                            <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow === rowElem.id ? "is-selected" : ""}`}
                                                onClick={this.selectRow}>
                                                <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.fullAccountNumber}</td>

                                                <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.updateDate}</td>
                                                <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>
                                            </tr>
                                        );
                                    }
                                    )}
                                </tbody>
                            </table>
                        }
                    </div>
                    <div className="pagination-display">
                        {this.maxPage !== Number.NaN && this.maxPage > 1 &&
                            <Pagination
                                changeInput={this.changeInput}
                                onChangeNext={this.onChangeNext}
                                onChangePrevious={this.onChangePrevious}
                                maxPage={this.maxPage}
                                pageNumber={this.state.paginationRequest.pageNumber} />
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private getMultipleStringDisplay(multipleString: string[]): string {
        if (multipleString) {
            let finalStr: string = '';
            for (var str of multipleString) {
                if (finalStr.length > 0) {
                    finalStr += ',';
                }
                finalStr += str;
            }
        }
        return '';
    }

    private importList = (event: any) => {
        if (this.inputOpenFileRef && this.inputOpenFileRef.current) {
            this.inputOpenFileRef.current.click()
        }

    }

    private handleFileInput = (event: any) => {
        if (event.target.files && event.target.files[0]) {
            let formData: FormData = new FormData();

            formData.append("upload_file",
                event.target.files[0],
                event.target.files[0].name
            );

            formData.append("dataset",
                String(this.selectedDataset)
            );

            importFile(formData);

            this.setState({
                ...this.state,
                importStatusVisible:true
            })
        }
    }

    private closeImportStatus = () => {
        cleanImportAudit();
        if (this.inputOpenFileRef && this.inputOpenFileRef.current) {
            this.inputOpenFileRef.current.value = '';
        }
        this.setState({
            ...this.state,
            importStatusVisible:false
        })

    }

    private showCustomerDetails = () => {
        if (this.state.contextMenuId !== '' && this.selectedDatasetType === DatasetConstant.WATCHLIST && this.props.addWatchlistPersonTab) {
            this.props.addWatchlistPersonTab(Number(this.state.contextMenuId), this.getWatchlistPersonFromPersonId());
        } else if (this.state.contextMenuId !== '' && this.props.addPersonTab) {
            this.props.addPersonTab(Number(this.state.contextMenuId));
        }

        this.setState({
            ...this.state,
            contextMenuVisible: false,
        })
    }

    private getWatchlistPersonFromPersonId = (): Person => {
        if (this.props.listOfPersons) {
            for (var wcPerson of this.props.listOfPersons) {
                if (String(wcPerson.id) === this.state.contextMenuId) {
                    return wcPerson;
                }
            }
        }

        return new WatchListPerson();
    }

    private setSelectedDataSet = (datasetId: number, datasettype: string) => {
        this.selectedDataset = datasetId;
        this.selectedDatasetType = datasettype;
    }

    private onGridDisplayContextMenu = (event: any) => {
        event.preventDefault();
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX,
            contextMenuY: event.pageY,
            contextMenuId: event.target && event.target.parentElement ? event.target.parentElement.id : ''
        })
    }

    // Pagination
    private onChangeNext = (): void => {
        if (this.state.paginationRequest.pageNumber < this.maxPage) {
            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = this.state.paginationRequest.pageNumber + 1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest: paginationRequest
            })
        }
    }

    private onChangePrevious = (): void => {
        if (this.state.paginationRequest.pageNumber > 0) {
            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = this.state.paginationRequest.pageNumber - 1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest: paginationRequest
            })
        }
    }

    private changeInput = (e: any) => {
        const value = e;
        if (value !== '') {
            let paginationRequest: PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest: paginationRequest
            })
            return;
        }
        if (value < 1) {
            return;
        }
    }

    private selectRow = (e: any) => {
        const value = e.target.parentNode ? e.target.parentNode.id : e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow: Number(value)
        })
    }

    private sortHeader = (e: any) => {
        const value = e.target ? e.target.id : e.id;

        let paginationRequest: PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder: string = paginationRequest.sortField;
        if (value === 'risk_name') {
            paginationRequest.sortField = AlertFieldsConstant.NAMEFIELD;
        }

        if (value === 'risk_type') {
            paginationRequest.sortField = AlertFieldsConstant.TYPEFIELD;
        }

        if (value === 'risk_openAlerts') {
            paginationRequest.sortField = AlertFieldsConstant.OPENALERTSFIELD;
        }

        if (value === 'risk_score') {
            paginationRequest.sortField = AlertFieldsConstant.SCOREFIELD;
        }

        if (value === 'risk_aggregate') {
            paginationRequest.sortField = AlertFieldsConstant.AGGREGATEFIELD;
        }

        if (value === 'risk_accountNumber') {
            paginationRequest.sortField = AlertFieldsConstant.ACCOUNTNUMBERFIELD;
        }

        if (value === 'risk_lastUpdate') {
            paginationRequest.sortField = AlertFieldsConstant.LASTUPDATEFIELD;
        }

        if (value === 'risk_status') {
            paginationRequest.sortField = AlertFieldsConstant.STATUSFIELD;
        }

        if (value === 'risk_investigatedby') {
            paginationRequest.sortField = AlertFieldsConstant.INVESTIGATEDBYFIELD;
        }

        if (value === 'risk_alertAge') {
            paginationRequest.sortField = AlertFieldsConstant.ALERTAGEFIELD;
        }

        // Person fields
        if (value === 'personId') {
            paginationRequest.sortField = PersonFieldsConstant.IDFIELD;
        }

        if (value === 'personDataId') {
            paginationRequest.sortField = PersonFieldsConstant.DATAIDFIELD;
        }

        if (value === 'personName') {
            paginationRequest.sortField = PersonFieldsConstant.NAMEFIELD;
        }

        if (value === 'entityType') {
            paginationRequest.sortField = PersonFieldsConstant.ENTITYTYPEFIELD;
        }

        if (value === 'personScore') {
            paginationRequest.sortField = PersonFieldsConstant.RISKSCOREFIELD;
        }

        if (value === 'personCreatedBy') {
            paginationRequest.sortField = PersonFieldsConstant.CREATED_BY;
        }

        if (value === 'personCreatedOn') {
            paginationRequest.sortField = PersonFieldsConstant.CREATED_ON;
        }

        if (value === 'personUpdatedBy') {
            paginationRequest.sortField = PersonFieldsConstant.UPDATED_BY;
        }

        if (value === 'personUpdatedOn') {
            paginationRequest.sortField = PersonFieldsConstant.UPDATED_ON;
        }

        if (paginationRequest.sortField === previousFieldOrder) {
            if (paginationRequest.sortOrder === 'DESC') {
                paginationRequest.sortOrder = 'ASC';
            } else {
                paginationRequest.sortOrder = 'DESC';
            }
        } else {
            paginationRequest.sortOrder = 'DESC';
        }

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            paginationRequest: paginationRequest
        })
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading: state.isLoadingStore.isLoading,
    listOfPersons: state.searchDatasetStore.listOfPersons,
    listOfAccounts: state.searchDatasetStore.listOfAccounts,
    listOfAlerts: state.searchDatasetStore.listOfAlerts,
    amountOfPersons: state.searchDatasetStore.amountOfPersons,
    amountOfAlerts: state.searchDatasetStore.amountOfAlerts,
    amountOfAccounts: state.searchDatasetStore.amountOfAccounts
})

export const Datasets = connect(mapStateToProps, null)(DatasetsClass);