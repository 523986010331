import { Person } from "../persons/Person";
import { MatchingData } from "../screena/MatchingData";
import { Group } from "../user/Group";
import { OrganizationUnit } from "../user/OrganizationUnit";
import { User } from "../user/User";
import { DataSetsFolder } from "./DataSetsFolder";

export class DataSets {
    public id!:number;
	public label!:string;
	public description!:string;
	public classification!:string;
	public category!:string;

	public active!:boolean;
	public totalCount!:number;

	// Type can be watchlist / Customer, Beneficiary
	public type!:string;

	// Person, Alerts, Transactions, ....
	public sourceOfData!:number;

	public records!:MatchingData[];

	public dataSetFolder!:DataSetsFolder;

	public creationDate!:string;
	public createdBy!:string;
	public updatedDate!:string;
	public updatedBy!:string;

	// Security
	public organizations!:OrganizationUnit[];
	public groups!:	Group[];
	public users!: User[];
}