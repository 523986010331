import Axios, { AxiosError, AxiosResponse, AxiosRequestConfig } from "axios";
import store from "..";
import { URLConstants } from "../../common/constant/URLConstant";
import { manageAxiosError } from "../../common/helper/AxiosErrorManagement";
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Request } from "../../model/common/Request";
import { Response } from "../../model/common/Response";
import { displayLoadingStatus, stopLoadingStatus } from "../loader/action";
import { showNotification } from "../notification/action";
import { Constants } from "./constant";

export const getAllDatasets = () => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.fullDatasetList, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.FULLDATASETS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);        
        stopLoadingStatus();
    })
}

export const getCustomerDatasets = () => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.get(URLConstants.customerDatasetList, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.CUSTOMERDATASETS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);        
        stopLoadingStatus();
    })
}

// 

export const getPaginatedDatasets = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.datasetList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.PAGEDDATASETS
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const getTotalAmountOfDataset = (request:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.datasetCount, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        return store.dispatch({
            payload: response.data,
            type: Constants.TOTALAMOUNTOFDATASETS
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}

export const createDataset = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.datasetCreate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('dataset_action_created'), null, null) ;
        if (paginationRequest) {
            getPaginatedDatasets(paginationRequest);
        }
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEDATASET
        });        
    })
    .catch((error: AxiosError<String>) => {  
        manageAxiosError(error);      
        stopLoadingStatus();
    })
}

export const updateDataset = (request:Request, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.datasetUpdate, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('dataset_action_updated'), null, null) ;
        if (paginationRequest) {
            getPaginatedDatasets(paginationRequest);
        }
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEDATASET
        });        
    })
    .catch((error: AxiosError<String>) => {   
        manageAxiosError(error);     
        stopLoadingStatus();
    })
}

export const importFile = (formData:FormData) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    store.dispatch({
        type: Constants.IMPORTFILESIMPORTING
    });

    Axios.post(URLConstants.datasetImport, formData, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();   
       
        return store.dispatch({
            payload: response.data,
            type: Constants.IMPORTFILES
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);
        stopLoadingStatus();
    })
}

export const cleanImportAudit = () => {
    return store.dispatch({
        type: Constants.IMPORTFILESERRORCLEAN
    })
}

export const deleteDataset = (datasetId:string, paginationRequest?:PaginationRequest) => {
    displayLoadingStatus();
 
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.delete(URLConstants.datasetDelete + datasetId, axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();
        showNotification('', formatMessage('dataset_action_deleted'), null, null) ;   

        if (paginationRequest) {
            getPaginatedDatasets(paginationRequest);
            getTotalAmountOfDataset(paginationRequest);
            getAllDatasetFolders(paginationRequest);
        }

        return store.dispatch({
            payload: response.data,
            type: Constants.DELETEDATASET
        });        
    })
    .catch((error: AxiosError<String>) => {        
        stopLoadingStatus();
        manageAxiosError(error);
    })
}

// Dataset Folders
export const getAllDatasetFolders = (request:PaginationRequest) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
        headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.fullDatasetFolderList, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.FULLDATASETSFOLDER
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);        
        stopLoadingStatus();
    })
}

export const createDatasetFolders = (request:Request) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.createDatasetFolder, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.CREATEDATASETSFOLDER
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);        
        stopLoadingStatus();
    })
}

export const updateDatasetFolders = (request:Request) => {
    displayLoadingStatus();
     
    let axioConf:AxiosRequestConfig = {
        withCredentials : true,
            headers: {
            'Content-Type': 'application/json',
            'Authorization': '' + localStorage.getItem('jwt')
        }
    }

    Axios.post(URLConstants.updateDatasetFolder, JSON.stringify(request), axioConf).then((response: AxiosResponse<Response>) => {        
        stopLoadingStatus();       
        return store.dispatch({
            payload: response.data,
            type: Constants.UPDATEDATASETSFOLDER
        });        
    })
    .catch((error: AxiosError<String>) => {
        manageAxiosError(error);        
        stopLoadingStatus();
    })
}