import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { formatMessage } from "../../common/translate/Translate";
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { Pagination } from "../../components/pagination/Pagination";
import { Group } from "../../model/user/Group";
import { deleteGroup, getPaginatedGroups, getTotalAmountOfGroups } from "../../store/groups/action";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { GroupDetailsPopup } from "./GroupDetailPopup";
import { AdminContextMenu } from "./AdminContextMenu";

interface IntProps {
    isLoading?:Boolean,
    groups?:Group[],
    totalAmountOfGroups?:number
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuVisible:boolean,    

    groupDetailPopupVisible:boolean,
    groupCreate:boolean
}

class GroupsClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);

        this.state = {
            paginationRequest : new PaginationRequest(),
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false,
            groupDetailPopupVisible:false,
            groupCreate:false
        }

        getPaginatedGroups(this.state.paginationRequest);
        getTotalAmountOfGroups(this.state.paginationRequest);
    }

    private maxPage:number = 0;

    public render() {
        
        if (this.props.totalAmountOfGroups) {
            this.maxPage = Math.ceil(this.props.totalAmountOfGroups / this.state.paginationRequest.maxPerPage);           
        } else {
            this.maxPage = 0;
        }
        
        return (
            <React.Fragment> 
                <GroupDetailsPopup visible={this.state.groupDetailPopupVisible} 
                                    closePopup={this.closeGroupDetails} 
                                    group={this.getCurrentGroup(this.state.selectedRow)}
                                    create={this.state.groupCreate}
                                    paginationRequest={this.state.paginationRequest}
                                    />
                <AdminContextMenu visible={this.state.contextMenuVisible} x={this.state.contextMenuX} y={this.state.contextMenuY}
                                    deleteAction={this.deleteGroup}
                                    editAction={this.displayGroupDetails}
                />
                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu}>  
                     <div style={{ marginLeft: '20px', marginRight: '10px', width: '98%', display: 'flex' }}>
                        <label className="o-label title" style={{ width: '98%'}}> {formatMessage('admin_main_group')}</label>
                        <button className="m-button m-button--small-margin-bottom"  onClick={this.createGroup} style={{marginTop: '5px', width: '10rem'}}>
                            {formatMessage('button_create')}
                        </button>
                    </div>
                    <div className="grids-display">
                        <table className="c-table js-table">
                            <thead className={`c-table__head`}>
                                <tr>
                                    <HeaderColumn sortHeader={this.sortHeader} id="id" display="common_id"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="name" display="common_name"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="description" display="common_description"/>

                                    <HeaderColumn sortHeader={this.sortHeader} id="createdBy" display="common_createdBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="createdOn" display="common_createdOn"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedBy" display="common_updatedBy"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="updatedOn" display="common_updatedOn"/>                                                              
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.groups &&  this.props.groups.map((rowElem, j) => {                                   
                                        return (
                                                <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}
                                                    onDoubleClick={this.displayGroupDetails}>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.id}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.name}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.description}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.createdBy}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.creationDate}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedDate}</td>                                                    
                                                </tr>
                                            );
                                    }                          
                                )}
                            </tbody>                            
                        </table> 
                    </div>
                    <div className="pagination-display">
                        {this.maxPage!==Number.NaN && this.maxPage>1 &&
                            <Pagination 
                                    changeInput={this.changeInput} 
                                    onChangeNext={this.onChangeNext} 
                                    onChangePrevious={this.onChangePrevious} 
                                    maxPage={this.maxPage} 
                                    pageNumber={this.state.paginationRequest.pageNumber}/>      
                        }
                    </div>  
                </div>  
            </React.Fragment>
        );
    }

    private deleteGroup = () => {
        deleteGroup(String(this.state.selectedRow), this.state.paginationRequest);
        this.setState({
            ...this.state,
            contextMenuVisible: false
        })
    }

    private displayGroupDetails = () => {
        this.setState({
            ...this.state,
            groupDetailPopupVisible : true,
            groupCreate:false,
            contextMenuVisible: false
        })
    }

    private closeGroupDetails = () => {
        this.setState({
            ...this.state,
            groupDetailPopupVisible : false,
            groupCreate:false,
            contextMenuVisible: false

        })
    }

    private createGroup = () => {
        this.setState({
            ...this.state,
            selectedRow:-1,
            groupDetailPopupVisible : true,
            groupCreate:true
        })
    }

    private getCurrentGroup = (groupId: number) : Group => {
        if (this.props.groups) {
            for (var group of this.props.groups) {
                if (group.id===groupId) {
                    return group;
                }
            }
        }        
        return new Group();
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
        })
    }

    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                contextMenuVisible: false,
                paginationRequest:paginationRequest                
            })
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
        }        
    }

    private changeInput = (e: any) => {
        const value = e.target? e.target.value: e.value;
        if (value === '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
          return;
        }
        if (value < 1) {
          return;
        }       
      }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    groups : state.groupStore.listOfGroups,
    totalAmountOfGroups : state.groupStore.amountOfGroups
})

export const Groups = connect(mapStateToProps, null)(GroupsClass);