import { AxiosError } from "axios";
import { useHistory } from "react-router-dom";

export const manageAxiosError = (error: any) => {
    console.log(error.response?.data.status);
    if (error.response?.data?.status==='401') {
        RedirectToLginPage();
    }
  
}

export const RedirectToLginPage = () => {
    window.location.replace("/");  
}