import React from "react";
import { connect } from "react-redux";

import { PaginationRequest } from "../../model/common/PaginationRequest";
import { Statistics } from "../../model/common/Statistics";
import { IRootState } from "../../store";

import Pie, { ProvidedProps, PieArcDatum } from '@visx/shape/lib/shapes/Pie';
import { scaleOrdinal } from '@visx/scale';
import { Group } from '@visx/group';
import { GradientPinkBlue } from '@visx/gradient';
import { getAlertStats } from "../../store/alerts/action";
import { url } from "inspector";
import { AreaClosed } from "@visx/shape";


interface IntProps {
    statisticsAlert?:Statistics;
}

interface IntState {
    
}

class DashboardClass extends React.Component<IntProps, IntState> {

    
    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);       
    }

    public render() {
        const half = 100;

        const colors:string[] = ["#e65054", "#00ba37", "#f2d675", "#614200", "#362400"]

        if (this.props.statisticsAlert && this.props.statisticsAlert.statsValue) {    
            
            let stats : {label:string, value:number, color:string}[]= [];
            let idxcol:number = 0;            
            for (let statValue of this.props.statisticsAlert.statsValue) {
                stats.push({
                    label : statValue.label,
                    value : statValue.value,
                    color : colors[idxcol]
                })
                idxcol++;
            }

            return (
                
                <React.Fragment>
                    <div className="main-grids" >
                        <div className="grids-display">                        
                            <svg width={400} height={400}>
                                <Group top={200} left={200}>
                                <Pie
                                        data={stats}
                                        pieValue={data => data.value}
                                        outerRadius={half}
                                        padAngle={0.01}
                                >
                                    {
                                            pie => pie.arcs.map ( arc => {
                                                const [centroidX, centroidY] = pie.path.centroid(arc);
                                                const hasSpaceForLabel = arc.endAngle - arc.startAngle;
                                                return (
                                                    
                                                    <g key={arc.data.label}>
                                                        <path d={pie.path(arc) || ''}
                                                                fill={arc.data.color}
                                                                display={arc.data.label}>
                                                        </path>
                                                        {hasSpaceForLabel && 
                                                            <text 
                                                                fill="black"
                                                                x={centroidX}
                                                                y={centroidY}
                                                                fontSize={9}
                                                                >
                                                                    {arc.data.label + "(" + arc.data.value + ")"}

                                                            </text>
                                                        }
                                                    </g>
                                                )
                                            })
                                        }
                                    </Pie>
                                   </Group>
                            </svg>
                        </div>
                    </div>
            </React.Fragment>
            );
        } else {
            return (
                <React.Fragment/>
            )
        }
    }
}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    statisticsAlert    : state.alertStore.statistics    
})

export const Dashboard = connect(mapStateToProps, null)(DashboardClass);