import React from "react";
import { connect } from "react-redux";

import '../../common/css/loader.css'
import '../../common/css/radar.css'
import '../../common/css/table.css'
import '../../common/css/button.css'
import { PaginationRequest } from "../../model/common/PaginationRequest";
import { IRootState } from "../../store";
import { RiskSearch } from "./RiskSearch";
import { Pagination } from "../../components/pagination/Pagination";
import { HeaderColumn } from "../../components/grid/HeaderColumn";
import { getAmountOfRiskPerson, getPaginatedRiskPerson } from "../../store/risk/action";
import { Person } from "../../model/persons/Person";
import { RiskContextMenu } from "./RiskContextMenu";
import { PersonFieldsConstant } from "../../common/constant/PersonFieldsConstant";
import { CommonFieldsConstant } from "../../common/constant/CommonFieldsConstant";
import { Name } from "../../model/persons/Name";

interface IntProps {
    isLoading?:Boolean,
    personsAtRisk?:Person[],
    totalAmountOfRiskedPersons?:number,

    addPersonTab?:(customerId:number) => void,
}

interface IntState {
    paginationRequest : PaginationRequest,
    selectedRow:number,
    contextMenuX:number,
    contextMenuY:number,
    contextMenuPersonId:string,
    contextMenuVisible:boolean,

    riskDetailsPopupVisible:boolean,
}

class RiskClass extends React.Component<IntProps, IntState> {

    constructor(props: IntProps | Readonly<IntProps>) {
        super(props);
        
        let paginationRequest:PaginationRequest = new PaginationRequest();
        paginationRequest.pageNumber = 0;
        paginationRequest.maxPerPage = 10;
       
        this.state = {
            paginationRequest : paginationRequest,
            selectedRow: -1,
            contextMenuX: -1,
            contextMenuY: -1,
            contextMenuVisible: false,
            contextMenuPersonId: '',
            riskDetailsPopupVisible: false,
        }       
    }

    private maxPage:number = 0;

    public render() {
       
        return (
            <React.Fragment> 
                <RiskContextMenu showCustomerDetails={this.showCustomerDetails} 
                                    visible={this.state.contextMenuVisible}
                                    x={this.state.contextMenuX}
                                    y={this.state.contextMenuY}
                                />
                <div className="main-search" >
                    <RiskSearch/>
                </div>
                <div className="main-grids" onContextMenu={this.onGridDisplayContextMenu}>  
                    <div className="grids-display">
                    <table className="c-table js-table">
                            <thead className={`c-table__head`}>
                                <tr>
                                    <HeaderColumn sortHeader={this.sortHeader} id="riskId" display="common_id"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="riskName" display="risk_name"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="riskEntityType" display="risk_type"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="riskScore" display="risk_score"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="riskSince" display="risk_since"/>
                                    <HeaderColumn sortHeader={this.sortHeader} id="riskLastUpdateDate" display="risk_investigatedby"/>
                                </tr>
                            </thead>
                            <tbody className="c-table__body">
                                {this.props.personsAtRisk &&  this.props.personsAtRisk.map((rowElem, j) => {                                   
                                        return (
                                                <tr key={'row_' + j} id={String(rowElem.id)} className={`c-table__row js-table-row ${this.state.selectedRow===rowElem.id? "is-selected" : ""}`} onClick={this.selectRow}>                                                   
                                                    <td className="c-table__data c-table__smallfont">{rowElem.id}</td>                                                    
                                                    <td className="c-table__data c-table__smallfont">{rowElem.names?this.getFullNameForDisplay(rowElem.names[0]):''}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.entityType}</td> 
                                                    <td className="c-table__data c-table__smallfont">{rowElem.score}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.riskSince}</td>
                                                    <td className="c-table__data c-table__smallfont">{rowElem.updatedBy}</td>                                                    
                                                </tr>
                                            );
                                    }
                                )}
                            </tbody>                            
                        </table> 
                    </div>
                    <div className="pagination-display">
                        {this.maxPage!==Number.NaN && this.maxPage>0 && 
                            <Pagination 
                                    changeInput={this.changeInput} 
                                    onChangeNext={this.onChangeNext} 
                                    onChangePrevious={this.onChangePrevious} 
                                    maxPage={this.maxPage} 
                                    pageNumber={this.state.paginationRequest.pageNumber}/>
                        }
                    </div>
                </div>
            </React.Fragment>
        );
    }

    private showCustomerDetails = () => {
        if (this.state.contextMenuPersonId!=='' && this.props.addPersonTab) {
            this.props.addPersonTab(Number(this.state.contextMenuPersonId));
        }

        this.setState({
            ...this.state,
            contextMenuVisible: false,
        })
    }

    private onGridDisplayContextMenu = (event: any)  => {
        event.preventDefault();            
        this.setState({
            ...this.state,
            contextMenuVisible: true,
            contextMenuX: event.pageX ,
            contextMenuY: event.pageY ,
            contextMenuPersonId : event.target && event.target.parentElement? event.target.parentElement.id: ''
        })
    }

    // Pagination
    private onChangeNext = () : void => {      
        if (this.state.paginationRequest.pageNumber<this.maxPage) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber+1;
            this.setState({
                ...this.state,              
                contextMenuVisible: false,
                paginationRequest:paginationRequest                
            })
            getPaginatedRiskPerson(paginationRequest);
            getAmountOfRiskPerson(paginationRequest);
        }        
    }

    private onChangePrevious = () : void => {
        if (this.state.paginationRequest.pageNumber>0) {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = paginationRequest.pageNumber-1;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
            getPaginatedRiskPerson(paginationRequest);
            getAmountOfRiskPerson(paginationRequest);
        }        
    }

    private changeInput = (e: any) => {
        const value = e;
        if (value !== '') {
            let paginationRequest:PaginationRequest = this.state.paginationRequest;
            paginationRequest.pageNumber = value;
            this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
            })
            getPaginatedRiskPerson(paginationRequest);
            getAmountOfRiskPerson(paginationRequest);
          return;
        }
        if (value < 1) {
          return;
        }       
      }

    private selectRow = (e: any) => {
        const value = e.target.parentNode? e.target.parentNode.id: e.id;

        this.setState({
            ...this.state,
            contextMenuVisible: false,
            selectedRow:Number(value)
        })
    }

    private sortHeader = (e: any) => {
        const value = e.target? e.target.id: e.id;

        let paginationRequest:PaginationRequest = this.state.paginationRequest;

        let previousFieldOrder:string = paginationRequest.sortField;

        if (value==='riskId') {
            paginationRequest.sortField = PersonFieldsConstant.IDFIELD;
        }

        if (value==='riskName') {
            paginationRequest.sortField = PersonFieldsConstant.NAMEFIELD;
        }

        if (value==='riskEntityType') {
            paginationRequest.sortField = PersonFieldsConstant.ENTITYTYPEFIELD;
        }

        if (value==='riskScore') {
            paginationRequest.sortField = PersonFieldsConstant.RISKSCOREFIELD;
        }

        if (value==='riskSince') {
            paginationRequest.sortField = PersonFieldsConstant.RISKSINCEFIELD;
        }

        if (value==='riskCreationUpdate') {
            paginationRequest.sortField = CommonFieldsConstant.CREATIONDATEFIELD;
        }

        if (value==='riskCreationBy') {
            paginationRequest.sortField = CommonFieldsConstant.CREATIONBYFIELD;
        }

        if (value==='riskLastUpdateDate') {
            paginationRequest.sortField = CommonFieldsConstant.LASTUPDATEFIELD;
        }

        if (value==='riskLastUpdateBy') {
            paginationRequest.sortField = CommonFieldsConstant.LASTUPBYFIELD;
        }
        
        if (paginationRequest.sortField===previousFieldOrder) {
            if (paginationRequest.sortOrder==='DESC') {
                paginationRequest.sortOrder = 'ASC';    
            } else {
                paginationRequest.sortOrder = 'DESC';
            }
        } else {
            paginationRequest.sortOrder = 'DESC';
        }
        
        this.setState({
                ...this.state,
                contextMenuVisible: false,
                paginationRequest:paginationRequest
        })
        getPaginatedRiskPerson(paginationRequest);
        getAmountOfRiskPerson(paginationRequest);
    }

    private getFullNameForDisplay(name: Name): string {
        if (name.fullName && name.fullName.length > 0) {
            return name.fullName;
        }

        let fullNameConcat: string = '';

        if (name.surname) {
            fullNameConcat += name.surname;
            fullNameConcat += ' ';
        }

        if (name.fatherName) {
            fullNameConcat += name.fatherName;
            fullNameConcat += ' ';
        }

        if (name.givenName) {
            fullNameConcat += name.givenName;
        }

        return fullNameConcat.trim();
    }

}

const mapStateToProps = (state: IRootState) => ({
    isLoading : state.isLoadingStore.isLoading,
    risk : state.riskStore.listOfRiskedPerson,
    personsAtRisk : state.riskStore.listOfRiskedPerson,
    totalAmountOfRiskedPersons : state.riskStore.amountOfPersons
})

export const Risk = connect(mapStateToProps, null)(RiskClass);